import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { Modal as BaseModal } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import Confirm from './Confirm';
import styles from './GenerateKeysModal.style';
import Pending from './Pending';
import PendingDetailed from './PendingDetailed';
import Success from './Success';

export interface PassedProps {
  isOpen: boolean;
  onClick?: () => void;
  type: string;
  keysNumber: number;
  currentKey?: number;
  onCancel?: () => void;
  children?: React.ReactNode | undefined;
}

interface GenerateKeysModalProps
  extends PassedProps,
    WithStyles<typeof styles> {}

class GenerateKeysModal extends PureComponent<GenerateKeysModalProps> {
  public render() {
    const { classes, onCancel, type, isOpen } = this.props;

    return (
      <BaseModal
        open={isOpen}
        onBackdropClick={type === 'success' ? onCancel : () => null}
        className="with-default-kiosk-components-theme"
      >
        <div className={classes.wrapper}>{this.renderContent()}</div>
      </BaseModal>
    );
  }
  private renderContent = () => {
    const { type, children, onClick, keysNumber, currentKey, onCancel } =
      this.props;
    switch (type) {
      case 'success':
        return (
          <Success
            keysNumber={keysNumber}
            currentKey={currentKey}
            onContinue={onClick}
            onCancel={onCancel}
          />
        );
      case 'pending':
        return <Pending keysNumber={keysNumber} currentKey={currentKey} />;
      case 'pending-detailed':
        return (
          <PendingDetailed keysNumber={keysNumber} currentKey={currentKey} />
        );
      case 'confirm':
        return (
          <Confirm
            keysNumber={keysNumber}
            currentKey={currentKey}
            onContinue={onClick}
            onCancel={onCancel}
          />
        );
      default:
        return children;
    }
  };
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(GenerateKeysModal) as (props: PassedProps) => JSX.Element;

import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Footer, ReservationHeader, View } from 'components';
import {
  ConsentDetailsOverlay,
  ExternalLinkOverlay,
} from 'components/overlays';
import { ConsentDetails } from 'components/overlays/ConsentDetailsOverlay/ConsentDetailsOverlay';
import { compose } from 'redux';
import { getProfileConsents } from 'store/profile/selectors';
import { updatePurposeOfStay } from 'store/reservation/actions';
import { getPurposeOfStay } from 'store/reservation/selectors';
import { getErrors, isLoading } from 'store/selectors';
import { ProfileConsent } from 'types/Api/Profile';
import { ApiError } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator, Router } from 'utils';

import { Body, ExternalHTMLContent, Section } from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';
import {
  createRequiredValidator,
  Form,
  FormApi,
} from '@ac/react-infrastructure';

import { Header } from '@gss/components/layout';
import { FormValidator } from '@gss/utils/form';
import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';

import PurposeOfStaySubForm from './PurposeOfStaySubForm/PurposeOfStaySubForm';
import { cacheConfirmationFormData } from './store/actions';
import { getCachedConfirmationFormData } from './store/selectors';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import { ConfirmationFormProperties, ConfirmationFormValues } from './types';

import './CheckInConfirmation.scss';

interface PassedProps {}

interface CheckInConfirmationProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation {
  isSubmitting: boolean;
  purposeOfStayId?: string;
  profileConsents: ProfileConsent[];
  errors: ApiError[];
  cachedConfirmationFormData?: ConfirmationFormValues;
  updatePurposeOfStay: typeof updatePurposeOfStay;
  cacheConfirmationFormData: typeof cacheConfirmationFormData;
}

interface CheckInConfirmationState {
  clickedConsentLinkData?: ConsentDetails;
  clickedDisclaimerLink?: string;
}

class CheckInConfirmation extends PureComponent<
  CheckInConfirmationProps,
  CheckInConfirmationState
> {
  public static defaultProps = {
    errors: [],
  };

  public state: CheckInConfirmationState = {
    clickedConsentLinkData: undefined,
    clickedDisclaimerLink: undefined,
  };

  private validator = new FormValidator<ConfirmationFormValues>({
    [ConfirmationFormProperties.purposeOfStay]: this.isPurposeOfStayEnabled
      ? createRequiredValidator(this.props.t('FIELD_IS_MANDATORY'))
      : undefined,
  });

  public componentWillUnmount() {
    const { cacheConfirmationFormData } = this.props;
    cacheConfirmationFormData(this.validator.data);
  }

  public render() {
    const { t, isSubmitting, errors } = this.props;

    const { clickedConsentLinkData, clickedDisclaimerLink } = this.state;

    return (
      <View modal={{ values: errors }} idle={{ type: 'modal' }}>
        <Header title={`${t('CHECK_IN')} - ${t('CONFIRMATION')}`} />
        <ReservationHeader />
        <Form
          initialValues={this.initialFormValues}
          validate={this.validator.validate}
          onSubmit={this.onFormSubmit}
          keepDirtyOnReinitialize
        >
          {(formRenderProps) => {
            return (
              <>
                {!!clickedConsentLinkData && (
                  <ConsentDetailsOverlay
                    consentDetails={clickedConsentLinkData}
                    onCancel={(consentId, isAccepted) => {
                      this.handleConsentOverlayAction(
                        formRenderProps.form,
                        consentId,
                        isAccepted
                      );
                    }}
                    onConfirm={(consentId, isAccepted) => {
                      this.handleConsentOverlayAction(
                        formRenderProps.form,
                        consentId,
                        isAccepted
                      );
                    }}
                  />
                )}

                {!!clickedDisclaimerLink && (
                  <ExternalLinkOverlay
                    href={clickedDisclaimerLink}
                    onClose={this.handleDisclaimerLinkClose}
                  />
                )}

                <Body>
                  <Section className="spacing-top-sm spacing-bottom-sm">
                    <div className="check-in-confirmation-form-wrapper">
                      {this.isTermsAndConditionsEnabled && (
                        <div className="check-in-confirmation-form-subsection">
                          <TermsAndConditions
                            onConsentLinkClick={this.openTermsModal}
                            disabledConsents={this.disabledConsentsIds}
                          />
                        </div>
                      )}

                      {this.isPurposeOfStayEnabled && (
                        <div className="check-in-confirmation-form-subsection">
                          <PurposeOfStaySubForm />
                        </div>
                      )}
                    </div>

                    {this.disclaimerContent && (
                      <ExternalHTMLContent
                        className="spacing-top-xxlg"
                        content={this.disclaimerContent}
                        onLinkClick={this.handleDisclaimerLinkClick}
                      />
                    )}
                  </Section>
                </Body>
                <Footer
                  hasCancelButton
                  hasBackButton
                  hasContinueButton
                  onContinue={formRenderProps.handleSubmit}
                  routeName={t('CHECK_IN')}
                  isContinueDisabled={isSubmitting || formRenderProps.invalid}
                />
              </>
            );
          }}
        </Form>
      </View>
    );
  }

  private get disclaimerContent(): string {
    const { i18n } = this.props;

    return Configurator.getTranslation(
      Configurator.getTranslationCodes()?.SELF_SERVICE_DISCLAIMER,
      i18n.language
    );
  }

  private get isPurposeOfStayEnabled(): boolean {
    const { SHOW_PURPOSE_OF_STAY } = Configurator.switchCodes;

    return Configurator.getSwitch(SHOW_PURPOSE_OF_STAY);
  }

  private get isTermsAndConditionsEnabled(): boolean {
    const { SHOW_TERMS_AND_CONDITIONS } = Configurator.switchCodes;

    return (
      Configurator.getSwitch(SHOW_TERMS_AND_CONDITIONS) &&
      Boolean(Configurator.consents.length)
    );
  }

  private get disabledConsentsIds(): string[] {
    const { profileConsents } = this.props;

    return profileConsents
      .map((consent) => {
        const isCBPConsent =
          consent.type === Configurator.consentTypeCodes.CROSS_BORDER;

        return isCBPConsent && consent.isGranted
          ? consent.consentId
          : undefined;
      })
      .filter(isDefined);
  }

  private get initialFormValues() {
    const { profileConsents, purposeOfStayId, cachedConfirmationFormData } =
      this.props;

    if (cachedConfirmationFormData) {
      return cachedConfirmationFormData;
    }

    return {
      ...(this.isPurposeOfStayEnabled
        ? { [ConfirmationFormProperties.purposeOfStay]: purposeOfStayId }
        : {}),
      [ConfirmationFormProperties.termsAndCondition]:
        Configurator.consents.reduce(
          (initialConsents, consent) => ({
            ...initialConsents,
            [consent.id]: !!profileConsents.find(
              ({ consentId }) => consentId === consent.id
            )?.isGranted,
          }),
          {}
        ),
    } as ConfirmationFormValues;
  }

  private handleDisclaimerLinkClick = (href: string) => {
    this.setState({ clickedDisclaimerLink: href });
  };

  private handleDisclaimerLinkClose = () => {
    this.setState({ clickedDisclaimerLink: undefined });
  };

  private openTermsModal = (consentId: string) => {
    const { profileConsents } = this.props;
    const consentDictionary = Configurator.consents.find(
      (consent) => consent.id === consentId
    );
    const guestAssignedConsent = profileConsents.find(
      (consent) => consent.consentId === consentId
    );
    const isCBPConsent =
      consentDictionary?.consentType?.code ===
      Configurator.consentTypeCodes.CROSS_BORDER;

    if (!consentDictionary) return;

    this.setState({
      clickedConsentLinkData: {
        id: consentId,
        isGranted: this.validator.data.termsAndCondition[consentId],
        isRevokePossible: !isCBPConsent || !guestAssignedConsent?.isGranted,
      },
    });
  };

  private handleConsentOverlayAction = (
    formApi: FormApi<ConfirmationFormValues>,
    consentId: string,
    value: boolean
  ) => {
    const values = formApi.getState().values.termsAndCondition;
    formApi.change(ConfirmationFormProperties.termsAndCondition, {
      ...values,
      [consentId]: value,
    });

    this.setState({ clickedConsentLinkData: undefined });
  };

  private onFormSubmit = async (data: ConfirmationFormValues) => {
    const {
      updatePurposeOfStay,
      history,
      purposeOfStayId: currentPurposeOfStay,
    } = this.props;
    const { purposeOfStay } = data;

    if (purposeOfStay && currentPurposeOfStay !== purposeOfStay) {
      await updatePurposeOfStay(purposeOfStay);
    }

    history.replace(Router.nextStepURL);
  };
}

const mapStateToProps = (state: Store) => ({
  cachedConfirmationFormData: getCachedConfirmationFormData(state),
  purposeOfStayId: getPurposeOfStay(state),
  profileConsents: getProfileConsents(state),
  isSubmitting: isLoading(state),
  errors: getErrors(state),
});

const mapDispatchToProps = {
  updatePurposeOfStay,
  cacheConfirmationFormData,
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckInConfirmation) as (props: PassedProps) => JSX.Element;

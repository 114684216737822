import * as ReactDOM from 'react-dom/client';

import { ConsoleSimulator } from '@ac/kiosk-components';

import {
  APP_ROOT_CONTAINER_ID,
  CONSOLE_SIMULATOR_ROOT_CONTAINER_ID,
} from '@gss/configs/constants';
import * as serviceWorkerRegistration from '@gss/serviceWorker/serviceWorkerRegistration';

import { AppContainer } from './AppContainer';
import { initAppSetup } from './initAppSetup';

initAppSetup();

const consoleSimulatorRoot = document.getElementById(
  CONSOLE_SIMULATOR_ROOT_CONTAINER_ID
);

if (consoleSimulatorRoot) {
  ReactDOM.createRoot(consoleSimulatorRoot).render(<ConsoleSimulator />);
}

const appContainerRoot = document.getElementById(APP_ROOT_CONTAINER_ID);

if (appContainerRoot) {
  ReactDOM.createRoot(appContainerRoot).render(<AppContainer />);
}

serviceWorkerRegistration.register();

import { put, select, take, takeLatest, takeLeading } from 'redux-saga/effects';

import {
  EmptyInterface,
  LibraryApiResponse,
  LibraryApiResponseHeader,
} from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';

import { SelfServiceMakeKeysProcessApi } from '@gss/api/KioskApi';
import { MakeKeysReservationDetailsDto } from '@gss/api/KioskApi/entries';
import { API_HEADERS } from '@gss/configs/constants';
import { getSelfServiceDeviceId } from '@gss/store/configuration/selectors';
import { AuthProcessDataSource } from '@gss/types/authProcess';
import { SagasGenerator } from '@gss/types/shared';
import {
  AuthFailedError,
  AuthTooManyReservationsFoundError,
  isAuthEntityNotFoundError,
  isAuthTooManyReservationsError,
} from '@gss/utils/errors';
import { handleSagaError } from '@gss/utils/sagas/handleSagaError';

import { changeAppLanguage } from '../../configuration/actions';

import * as actions from './actions';
import { MakeKeyAuthenticationPayload } from './interfaces';
import {
  getIsMakeKeysGuestAuthenticated,
  getIsMakeKeysProcessReady,
  getMakeKeysProcessId,
} from './selectors';

export function* authenticateGuestSaga(
  action: Action<MakeKeyAuthenticationPayload>
): SagasGenerator {
  try {
    const deviceId: string = yield select(getSelfServiceDeviceId);

    const response: LibraryApiResponse<EmptyInterface> =
      yield SelfServiceMakeKeysProcessApi.startProcess({
        data: {
          deviceId,
          ...action.payload,
        },
        customConfig: {
          iterations: 0,
        },
      });

    const sessionId = response.headers[
      API_HEADERS.kioskSessionId.toLowerCase() as LibraryApiResponseHeader
    ] as string;

    yield put(actions.authenticateGuest.success(sessionId));
  } catch (error) {
    if (isAuthEntityNotFoundError(error)) {
      yield put(actions.authenticateGuest.failure(new AuthFailedError()));
    } else if (isAuthTooManyReservationsError(error)) {
      yield put(
        actions.authenticateGuest.failure(
          action.payload.dataSource === AuthProcessDataSource.form
            ? new AuthTooManyReservationsFoundError()
            : new AuthFailedError()
        )
      );
    } else {
      yield put(actions.authenticateGuest.failure(handleSagaError(error)));
    }
  }
}

export function* closeMakeKeyProcessSaga(
  action: Action<() => void>
): SagasGenerator {
  try {
    const processId: string | undefined = yield select(getMakeKeysProcessId);

    if (processId) {
      yield SelfServiceMakeKeysProcessApi.finishProcess({
        customConfig: {
          headers: {
            [API_HEADERS.kioskSessionId]: processId,
          },
        },
      });
    }
  } finally {
    yield put(actions.closeMakeKeyProcess.success());
    action.payload?.();
  }
}

export function* fetchMakeKeysReservationDetails(): SagasGenerator {
  try {
    const processId: string = yield select(getMakeKeysProcessId);

    const response: LibraryApiResponse<MakeKeysReservationDetailsDto> =
      yield SelfServiceMakeKeysProcessApi.getReservationDetails({
        customConfig: {
          headers: {
            [API_HEADERS.kioskSessionId]: processId,
          },
        },
      });

    yield put(actions.fetchMakeKeysReservationDetails.success(response.data));
  } catch (error) {
    yield put(
      actions.fetchMakeKeysReservationDetails.failure(handleSagaError(error))
    );
  }
}

export function* prepareMakeKeysProcessSaga(
  action: Action<MakeKeyAuthenticationPayload>
): SagasGenerator {
  yield put(actions.authenticateGuest.trigger(action.payload));
  yield take([
    actions.authenticateGuest.success,
    actions.authenticateGuest.failure,
  ]);

  const isGuestAuthenticated = yield select(getIsMakeKeysGuestAuthenticated);

  if (isGuestAuthenticated) {
    yield put(actions.fetchMakeKeysReservationDetails.trigger());
  }
}

export function* handleChangeAppLanguageSaga(): SagasGenerator {
  const isMakeKeysProcessReady = yield select(getIsMakeKeysProcessReady);
  if (isMakeKeysProcessReady) {
    yield put(actions.fetchMakeKeysReservationDetails.trigger());
  }
}

export function* makeKeysFlowSagas(): SagasGenerator {
  yield takeLatest(changeAppLanguage.success, handleChangeAppLanguageSaga);
  yield takeLatest(
    actions.fetchMakeKeysReservationDetails.trigger,
    fetchMakeKeysReservationDetails
  );
  yield takeLatest(actions.authenticateGuest.trigger, authenticateGuestSaga);
  yield takeLeading(actions.startMakeKeysProcess, prepareMakeKeysProcessSaga);
  yield takeLeading(
    actions.closeMakeKeyProcess.trigger,
    closeMakeKeyProcessSaga
  );
}

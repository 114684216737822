import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Body,
  Footer,
  FormHeader,
  ReservationHeader,
  ResultDrawer,
  View,
} from 'components';
import { ids } from 'config';
import { compose } from 'redux';
import { change, getFormValues, submit } from 'redux-form';
import { fetchPropertyLocalDateTime } from 'store/propertyManagement/actions';
import {
  cutKey,
  duplicateKey,
  getCutKeyOperationStatus,
  getDuplicateKeyStatus,
} from 'store/reservation/actions';
import {
  getAdults,
  getCutKeyOperation,
  getDuplicateKeyOperation,
  getGuestsNumber,
  getKeyEndValidityDate,
  getKeyStartValidityDate,
  getReservation,
  getReservationErrors,
  isFetching,
} from 'store/reservation/selectors';
import { ApiError, Operation } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator, Router } from 'utils';
import { Path } from 'utils/Router';

import { Header } from '@gss/components/layout';
import { getWorkstationId } from '@gss/store/configuration/selectors';
import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './GenerateKeys.style';
import GenerateKeysForm from './GenerateKeysForm';
import GenerateKeysModal from './GenerateKeysModal';

const { CHECK_IN_FAILED_MESSAGE, KEY_MESSAGE } =
  Configurator.getTranslationCodes();
const INITIAL_TIMEOUT = 3000;

interface PassedProps {}

interface GenerateKeysProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  guestNumber: number;
  reservation: {
    id: string;
    eta: string;
    etd: string;
    doorKeyAmount: number;
  };
  keyEndValidityDate: string;
  keyStartValidityDate: string;
  isFetching: boolean;
  cutKey: typeof cutKey;
  duplicateKey: typeof duplicateKey;
  submit: typeof submit;
  getCutKeyOperationStatus: typeof getCutKeyOperationStatus;
  getDuplicateKeyStatus: typeof getDuplicateKeyStatus;
  fetchPropertyLocalDateTime: typeof fetchPropertyLocalDateTime;
  errors: ApiError[];
  cutKeyOperation: Operation;
  duplicateKeyOperation: Operation;
  adultsNumber: number;
  workstationId?: string;
  keyFormValues?: {
    keys: number;
  };
  onChange: (form: string, field: string, value: number) => void;
}

interface GenerateKeysState {
  successModalOpen: boolean;
  isGenerateKeyModalOpen: boolean;
  wasKeyGenerated: boolean;
  isProcessInProgress: boolean;
  keysToGenerate: number;
  currentKey: number;
  isDrawerOpen: boolean;
  isFinish: boolean;
}

class GenerateKeys extends PureComponent<GenerateKeysProps, GenerateKeysState> {
  public state = {
    successModalOpen: false,
    isGenerateKeyModalOpen: false,
    wasKeyGenerated: false,
    isProcessInProgress: false,
    keysToGenerate: 0,
    currentKey: 0,
    isDrawerOpen: false,
    isFinish: false,
  };

  public async componentDidMount() {
    const { fetchPropertyLocalDateTime } = this.props;

    const noBackButton = 'no-back-button';

    // Temporary disabled rule because typescript in tests interfering types incorrectly
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    (window.location as Location).hash = noBackButton;
    window.onhashchange = () => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      (window.location as Location).hash = noBackButton;
    };
    await fetchPropertyLocalDateTime();
  }

  public render() {
    const {
      t,
      guestNumber,
      isFetching,
      errors,
      classes,
      adultsNumber,
      keyFormValues,
    } = this.props;
    const maxKeys = adultsNumber === 1 ? 2 : guestNumber;
    const { isFinish, isGenerateKeyModalOpen, isDrawerOpen, currentKey } =
      this.state;
    const keysToGenerate = keyFormValues ? keyFormValues.keys : 0;

    return (
      <View
        modal={{
          values: errors,
          customErrorCode: CHECK_IN_FAILED_MESSAGE,
          disableLoader: isGenerateKeyModalOpen,
        }}
        hideCounter
        idle={{ type: 'modal' }}
      >
        {isGenerateKeyModalOpen && (
          <GenerateKeysModal
            isOpen
            currentKey={currentKey + 1}
            {...this.getModalProps()}
          />
        )}
        <Header title={`${t('CHECK_IN')} - ${t('GENERATE_KEYS')}`} />
        <ReservationHeader />
        <ResultDrawer
          isOpen={isDrawerOpen}
          title={t('GENERATE_KEYS_COMPLETED')}
        />
        <Body className={classes.body}>
          {isFinish ? (
            <Typography className={classes.title}>
              {t('ALL_KEYS_READY', { pathname: this.getPathname() })}
            </Typography>
          ) : (
            <>
              <ButtonBase
                className={classes.zeroKeysButton}
                onClick={() => this.onKeysReset()}
              />
              <Grid container>
                <Grid item xs={12} md={6}>
                  <FormHeader
                    title={t('HOW_MANY_KEYS')}
                    subtitle={
                      Configurator.getTranslation(KEY_MESSAGE) ||
                      t('FOLLOW_INSTRUCTIONS')
                    }
                  />
                  <GenerateKeysForm
                    onSubmit={this.onSubmit}
                    maxValue={maxKeys}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.keysGenerateImage}
                />
              </Grid>
            </>
          )}
        </Body>
        <Footer
          hasCancelButton={Router.currentPath === Path.generateKeys}
          hasContinueButton
          onContinue={
            isFinish || !keysToGenerate
              ? this.goNext
              : this.toggleGenerateKeyModal
          }
          isContinueDisabled={isFetching || isGenerateKeyModalOpen}
          continueButtonLabel={isFinish ? t('FINISH') : t('CONTINUE')}
        />
      </View>
    );
  }

  private toggleGenerateKeyModal = () => {
    const { isGenerateKeyModalOpen } = this.state;
    this.setState({ isGenerateKeyModalOpen: !isGenerateKeyModalOpen });
  };

  private onCancel = () => {
    this.setState({
      successModalOpen: false,
      isGenerateKeyModalOpen: false,
      wasKeyGenerated: false,
      isProcessInProgress: false,
      keysToGenerate: 0,
      currentKey: 0,
    });
  };

  private onKeysReset = () => {
    const { onChange } = this.props;
    onChange(ids.GENERATE_KEYS_FORM, 'keys', 0);
  };

  private getModalProps = () => {
    const { isFetching, keyFormValues } = this.props;
    const { wasKeyGenerated, isProcessInProgress } = this.state;
    const keysToGenerate = keyFormValues ? keyFormValues.keys : 0;
    if (isFetching) {
      return {
        type: 'pending',
        keysNumber: keysToGenerate,
      };
    }
    if (isProcessInProgress) {
      return {
        type: 'success',
        keysNumber: keysToGenerate,
        onCancel: this.onCancel,
        onClick: this.handleSuccessModalClick,
      };
    }

    return {
      type: 'confirm',
      keysNumber: keysToGenerate,
      onCancel: this.onCancel,
      onClick: wasKeyGenerated ? this.duplicateKeys : this.submit,
    };
  };

  private submit = () => {
    const { submit } = this.props;
    submit(ids.GENERATE_KEYS_FORM);
  };

  private onSubmit = async ({ keys }: { keys: number }) => {
    if (keys < 1) {
      const { history } = this.props;

      return history.replace(Router.nextStepURL);
    }
    this.setState({ keysToGenerate: keys });
    const { keyStartValidityDate, keyEndValidityDate, workstationId } =
      this.props;
    await this.generateKeys({
      encoder: Configurator.cuttingKeysConfig.ENCODER,
      from: keyStartValidityDate,
      to: keyEndValidityDate,
      workstation: workstationId,
      numberOfKeys: keys,
    });
  };

  private generateKeys = async (data: any) => {
    const {
      reservation: { doorKeyAmount },
    } = this.props;
    const { numberOfKeys } = data;
    if (doorKeyAmount) return this.duplicateKeys();
    await this.generateNewKey(data);
    const {
      cutKeyOperation: { status: operationStatus },
    } = this.props;
    const isSuccess =
      operationStatus === Configurator.operationStatuses.SUCCEEDED;
    if (isSuccess) this.toggleProcessState();
    if (numberOfKeys < 2 && isSuccess) this.handleGenerateKeysSuccess();
  };

  private duplicateKeys = async () => {
    const { keyStartValidityDate, keyEndValidityDate, workstationId } =
      this.props;
    const { currentKey, keysToGenerate } = this.state;
    await this.duplicateExistingKey({
      encoder: Configurator.cuttingKeysConfig.ENCODER,
      from: keyStartValidityDate,
      to: keyEndValidityDate,
      workstation: workstationId,
      numberOfKeys: 1,
    });
    const {
      duplicateKeyOperation: { status: operationStatus },
    } = this.props;
    const isSuccess =
      operationStatus === Configurator.operationStatuses.SUCCEEDED;
    if (isSuccess) this.toggleProcessState();

    return (
      isSuccess &&
      currentKey + 1 === keysToGenerate &&
      this.handleGenerateKeysSuccess()
    );
  };

  private generateNewKey = async (data: any) => {
    const {
      reservation: { id },
      cutKey,
    } = this.props;
    await cutKey(id, { ...data, numberOfKeys: 1 });
    const {
      cutKeyOperation: { id: operationId },
      getCutKeyOperationStatus,
    } = this.props;
    await getCutKeyOperationStatus(operationId);

    this.setState({ wasKeyGenerated: true });
  };
  private duplicateExistingKey = async (data: any) => {
    const { currentKey } = this.state;
    const {
      reservation: { id },
      duplicateKey,
    } = this.props;
    await duplicateKey(id, data);
    const {
      duplicateKeyOperation: { id: operationId },
      getDuplicateKeyStatus,
    } = this.props;
    await getDuplicateKeyStatus(operationId);
    if (currentKey > 0) this.setState({ wasKeyGenerated: true });
  };

  private incrementKeyIndex = () => {
    const { currentKey } = this.state;
    this.setState({ currentKey: currentKey + 1 });
  };

  private goNext = () => {
    const { history } = this.props;
    history.replace(Router.nextStepURL);
  };

  private toggleProcessState = () => {
    const { isProcessInProgress } = this.state;
    this.setState({ isProcessInProgress: !isProcessInProgress });
  };

  private expandDrawer = () => this.setState({ isDrawerOpen: true });
  private hideDrawer = () => this.setState({ isDrawerOpen: false });
  private handleGenerateKeysSuccess = () => {
    this.setState({ isFinish: true });
    this.expandDrawer();
    this.toggleGenerateKeyModal();
    setTimeout(() => {
      this.hideDrawer();
    }, INITIAL_TIMEOUT);
  };

  private handleSuccessModalClick = () => {
    this.toggleProcessState();
    this.incrementKeyIndex();
  };
  private getPathname = () => {
    const { t } = this.props;
    const isGenerateKeysPath = Router.currentPath === Path.generateKeys;

    return isGenerateKeysPath ? t('GENERATE_KEYS') : t('CHECK_IN');
  };
}

const mapStateToProps = (state: Store) => ({
  isFetching: isFetching(state),
  workstationId: getWorkstationId(state),
  cutKeyOperation: getCutKeyOperation(state),
  duplicateKeyOperation: getDuplicateKeyOperation(state),
  keyEndValidityDate: getKeyEndValidityDate(state),
  keyStartValidityDate: getKeyStartValidityDate(state),
  guestNumber: getGuestsNumber(state),
  reservation: getReservation(state),
  adultsNumber: getAdults(state),
  keyFormValues: getFormValues(ids.GENERATE_KEYS_FORM)(state),
  errors: getReservationErrors(state),
});

const mapDispatchToProps = {
  getCutKeyOperationStatus,
  getDuplicateKeyStatus,
  duplicateKey,
  submit,
  cutKey,
  onChange: change,
  fetchPropertyLocalDateTime,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(GenerateKeys) as (props: PassedProps) => JSX.Element;

import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Footer, ReservationHeader, View } from 'components';
import {
  ProfileDuplicatesModal,
  ProfileDuplicateVariants,
} from 'components/modals';
import isEqual from 'lodash.isequal';
import { compose } from 'redux';
import { resetAppProgress } from 'store/actions';
import { sendUpdateProfileData } from 'store/checkInProcess/actions';
import { getConfirmedScannedDocument } from 'store/checkInProcess/selectors';
import {
  checkIndividualProfileMatching,
  fetchProfile,
  setIsGuestAcceptedProfileDuplicateRisk,
} from 'store/profile/actions';
import {
  getActiveDocuments,
  getIfGuestAcceptedProfileDuplicateRisk,
  getIsProfileDuplicateDetected,
} from 'store/profile/selectors';
import { getProfileId } from 'store/reservation/selectors';
import { getErrors } from 'store/selectors';
import { Document } from 'types/Api/Profile';
import { ApiError } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator, DateManager, Router } from 'utils';
import { allSteps, Path } from 'utils/Router';

import {
  Body,
  Section,
  Text,
  TextSize,
  TextWeight,
} from '@ac/kiosk-components';

import {
  DocumentScanningResultDetails,
  KioskCheckInUpdateIdentityDocumentDetails,
  KioskCheckInUpdateIdentityDocuments,
  KioskCheckInUpdateProfile,
} from '@gss/api/KioskApi/entries';
import { Header } from '@gss/components/layout';
import { submitFormById } from '@gss/utils/form';
import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';

import { DocumentsFormFieldsVisibilityConfig } from './DocumentsForm/DocumentsForm';
import {
  cacheDocumentFormData,
  setDocumentUpdatePayload,
} from './store/actions';
import {
  getCachedDocumentFormData,
  getIdOfPreviouslyAddedDocument,
} from './store/selectors';
import DocumentScanner from './DocumentScanner';
import { DocumentsForm, DocumentsFormValues } from './DocumentsForm';

const DOCUMENT_FORM_ID = 'document-form-id';

const {
  SHOW_SCAN_DOCUMENT,
  ALLOW_KIOSK_PROFILE_MATCH,
  ALLOW_KIOSK_PROFILE_DUPLICATES,
} = Configurator.switchCodes;

const { CHECK_IN_FAILED_MESSAGE } = Configurator.getTranslationCodes();

interface PassedProps {}

interface CheckInIDProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation {
  checkIndividualProfileMatching: typeof checkIndividualProfileMatching;
  resetAppProgress: typeof resetAppProgress;
  setIsGuestAcceptedProfileDuplicateRisk: typeof setIsGuestAcceptedProfileDuplicateRisk;
  setDocumentUpdatePayload: typeof setDocumentUpdatePayload;
  cacheDocumentFormData: typeof cacheDocumentFormData;
  sendUpdateProfileData: typeof sendUpdateProfileData;
  fetchProfile: typeof fetchProfile;
  isProfileDuplicateDetected: boolean;
  confirmedScannedDocument?: DocumentScanningResultDetails;
  profileId: string;
  errors: Array<Error | ApiError>;
  isGuestAcceptedProfileDuplicateRisk: boolean;
  documents: Document[];
  cachedDocumentFormData?: DocumentsFormValues;
  previouslyAddedDocumentId?: string;
}

interface CheckInIDState {
  isFormValid: boolean;
  isModalOpen: boolean;
  selectedDocumentCode?: string;
  isViewReady: boolean;
  formInitialValues: DocumentsFormValues;
}

class CheckInID extends PureComponent<CheckInIDProps, CheckInIDState> {
  public state = {
    isFormValid: false,
    isModalOpen: false,
    selectedDocumentCode: undefined,
    isViewReady: false,
    formInitialValues: {},
  };

  componentDidMount() {
    this.setState({
      isViewReady: true,
      formInitialValues: this.getFormInitialData(),
    });
  }

  componentDidUpdate(_prevProps: CheckInIDProps, prevState: CheckInIDState) {
    const prevSelectedDocumentCode = prevState.selectedDocumentCode;
    const currentSelectedDocumentCode = this.state.selectedDocumentCode;

    if (
      (prevSelectedDocumentCode || currentSelectedDocumentCode) &&
      prevSelectedDocumentCode !== currentSelectedDocumentCode
    ) {
      this.setState({
        formInitialValues: this.getFormInitialData(),
      });
    }
  }

  public render() {
    const {
      t,
      errors,
      isProfileDuplicateDetected,
      isGuestAcceptedProfileDuplicateRisk,
      confirmedScannedDocument,
    } = this.props;
    const { isModalOpen, isFormValid, isViewReady, formInitialValues } =
      this.state;

    const isDocumentCaptureVisible = Configurator.getSwitch(SHOW_SCAN_DOCUMENT);

    return (
      <View
        modal={{
          values: errors,
          customErrorCode: CHECK_IN_FAILED_MESSAGE,
          isLoading: !isViewReady,
        }}
        idle={{ type: 'modal' }}
      >
        {isProfileDuplicateDetected && !isGuestAcceptedProfileDuplicateRisk && (
          <ProfileDuplicatesModal
            variant={this.profileDuplicatesModalVariant}
            onCancel={this.handleProfileDuplicateModalCancel}
            onSubmit={this.handleProfileDuplicateModalSubmit}
          />
        )}

        <Header title={`${t('CHECK_IN')} - ${t('DOCUMENT_DETAILS')}`} />

        <ReservationHeader />
        <Body>
          <Section className="spacing-top-sm spacing-bottom-sm">
            <Text size={TextSize.xlg} weight={TextWeight.light}>
              {t('COMPLETE_THE_FORM')}
            </Text>
            <Text hint className="spacing-top-sm spacing-bottom-lg">
              {t('YOU_MAY_SKIP_OPTIONAL')}
            </Text>

            <DocumentsForm
              id={DOCUMENT_FORM_ID}
              fieldsVisibility={this.documentFormFieldsConfig}
              isScannedDocument={!!confirmedScannedDocument}
              onDocumentTypeChange={this.onDocumentTypeChange}
              onSubmit={this.onSubmit}
              initialValues={formInitialValues}
              onFormValidChange={this.onFormValidChange}
            />

            {isDocumentCaptureVisible && (
              <DocumentScanner
                isCaptureModalOpen={isModalOpen}
                toggleModal={this.onModalToggle}
                shouldCrop
              />
            )}
          </Section>
        </Body>
        <Footer
          hasCancelButton
          hasBackButton={this.isBackButtonVisible}
          hasContinueButton
          routeName={t('CHECK_IN')}
          onContinue={submitFormById(DOCUMENT_FORM_ID)}
          isContinueDisabled={!isFormValid}
        />
      </View>
    );
  }

  private get profileDuplicatesModalVariant() {
    return Configurator.getSwitch(ALLOW_KIOSK_PROFILE_DUPLICATES)
      ? ProfileDuplicateVariants.duplicatesWarning
      : ProfileDuplicateVariants.duplicatesError;
  }

  private get isBackButtonVisible() {
    const authStepPath = Router.steps[Path.checkIn].AUTH?.url;

    return Router.prevStepURL !== authStepPath;
  }

  private get documentFormFieldsConfig(): DocumentsFormFieldsVisibilityConfig {
    const { confirmedScannedDocument } = this.props;

    if (!confirmedScannedDocument) {
      return {
        documentType: true,
        number: true,
        nationality: true,
        expiryDate: true,
      };
    }

    return {
      documentType: true,
      number: true,
      countryOfBirth: Boolean(confirmedScannedDocument.countryOfBirth?.code),
      countryOfIssue: Boolean(confirmedScannedDocument.countryOfIssue?.code),
      dateOfBirth: Boolean(confirmedScannedDocument.dateOfBirth),
      expiryDate: Boolean(confirmedScannedDocument.expiryDate),
      issueDate: Boolean(confirmedScannedDocument.issueDate),
      nationality: Boolean(confirmedScannedDocument.nationality?.code),
      placeOfIssue: Boolean(confirmedScannedDocument.placeOfIssue),
    };
  }

  private getFormInitialData = (): DocumentsFormValues => {
    const { confirmedScannedDocument, cachedDocumentFormData } = this.props;
    const { selectedDocumentCode } = this.state;
    const cachedDataShouldBeUsed = selectedDocumentCode
      ? cachedDocumentFormData?.documentType === selectedDocumentCode
      : cachedDocumentFormData;

    if (cachedDataShouldBeUsed && cachedDocumentFormData) {
      return cachedDocumentFormData;
    }

    if (confirmedScannedDocument) {
      const parsedDateBirth = confirmedScannedDocument.dateOfBirth && {
        ...confirmedScannedDocument.dateOfBirth,
        month: confirmedScannedDocument.dateOfBirth.month - 1,
      };

      return {
        documentType: confirmedScannedDocument.documentIdType?.code,
        countryOfBirth: confirmedScannedDocument.countryOfBirth?.code,
        countryOfIssue: confirmedScannedDocument.countryOfIssue?.code,
        dateOfBirth:
          parsedDateBirth &&
          DateManager.getServerFormattedDate(parsedDateBirth),
        expiryDate: confirmedScannedDocument.expiryDate,
        issueDate: confirmedScannedDocument.issueDate,
        nationality: confirmedScannedDocument.nationality?.code,
        number: confirmedScannedDocument.documentNumber,
        placeOfIssue: confirmedScannedDocument.placeOfIssue,
      };
    }

    const selectedDocument = this.getSelectedDocument();

    return selectedDocument
      ? {
          documentType: selectedDocument.typeCode,
          number: selectedDocument.number,
          expiryDate: selectedDocument.expiryDate,
          nationality: selectedDocument.nationalityCode,
        }
      : {
          documentType:
            selectedDocumentCode || Configurator.documentTypes?.[0]?.code,
        };
  };

  private getSelectedDocument = (): Document | undefined => {
    const { documents } = this.props;
    const { selectedDocumentCode } = this.state;

    return selectedDocumentCode
      ? documents.find(({ typeCode }) => typeCode === selectedDocumentCode)
      : documents[0];
  };

  private onFormValidChange = (isFormValid: boolean) => {
    const { isViewReady } = this.state;
    if (!isViewReady) return;
    this.setState({ isFormValid });
  };

  private onDocumentTypeChange = (code: string) => {
    this.setState({ selectedDocumentCode: code });
  };

  private onModalToggle = () => {
    const { isModalOpen } = this.state;
    this.setState({ isModalOpen: !isModalOpen });
  };

  private handleProfileDuplicateModalCancel = () => {
    const { history, resetAppProgress } = this.props;
    resetAppProgress(history);
  };

  private handleProfileDuplicateModalSubmit = () => {
    const { setIsGuestAcceptedProfileDuplicateRisk, history } = this.props;
    history.push(Router.nextStepURL);
    setIsGuestAcceptedProfileDuplicateRisk(true);
  };

  private onSubmit = async (values: DocumentsFormValues) => {
    const {
      setDocumentUpdatePayload,
      sendUpdateProfileData,
      cacheDocumentFormData,
      fetchProfile,
      profileId,
      history,
    } = this.props;

    await fetchProfile(profileId);

    const documentPayload = this.getIdentityDocumentsPayload(values);
    setDocumentUpdatePayload({ ...documentPayload });
    cacheDocumentFormData(values);

    if (
      Router.hasAllowedURL(
        Path.checkIn,
        allSteps.CHECK_IN.COMMUNICATION_DETAILS.url
      )
    ) {
      return history.push(Router.nextStepURL);
    }

    await sendUpdateProfileData();

    const { errors } = this.props;
    if (errors?.length) {
      return;
    }

    if (!Configurator.getSwitch(ALLOW_KIOSK_PROFILE_MATCH)) {
      return history.push(Router.nextStepURL);
    }

    const isDuplicateDetected = await this.checkProfileDuplicates();
    if (!isDuplicateDetected) history.push(Router.nextStepURL);
  };

  private getIdentityDocumentsPayload = (
    values: DocumentsFormValues
  ):
    | Pick<KioskCheckInUpdateProfile, 'identityDocuments' | 'personalDetails'>
    | undefined => {
    const { confirmedScannedDocument, previouslyAddedDocumentId } = this.props;
    const { formInitialValues } = this.state;

    const selectedDocument = this.getSelectedDocument();
    const isScannedDocument = Boolean(confirmedScannedDocument);
    const isScannedDocumentSaved =
      selectedDocument && Boolean(previouslyAddedDocumentId);
    const valuesNotChanged = isEqual(values, formInitialValues);
    const documentTypesId = Configurator.documentTypes.find(
      ({ code }) => code === values.documentType
    )?.id;

    if (
      !documentTypesId ||
      ((isScannedDocumentSaved || !isScannedDocument) && valuesNotChanged)
    ) {
      return;
    }

    const personalDetails = values.nationality
      ? { nationalityCode: values.nationality }
      : undefined;

    const identityDocuments: Required<KioskCheckInUpdateIdentityDocuments> = {
      add: [],
      update: [],
    };

    const updatedDocument: KioskCheckInUpdateIdentityDocumentDetails = {
      id: isScannedDocument
        ? previouslyAddedDocumentId
        : selectedDocument?.identificationDocumentId,
      typeId: documentTypesId,
      expiryDate:
        values.expiryDate &&
        DateManager.getServerFormattedDate(values.expiryDate),
      issueDate:
        values.issueDate &&
        DateManager.getServerFormattedDate(values.issueDate),
      countryOfIssue: values.countryOfIssue,
      placeOfIssue: values.placeOfIssue,
      issuingAuthority: values.issuedBy,
      countryOfBirth: values.countryOfBirth,
      dateOfBirth:
        values.dateOfBirth &&
        DateManager.getServerFormattedDate(values.dateOfBirth),
      number: values.number,
      nationality: values.nationality,
    };

    if (updatedDocument.id) {
      identityDocuments.update.push(updatedDocument);
    } else {
      identityDocuments.add.push(updatedDocument);
    }

    return {
      identityDocuments,
      ...(personalDetails ? { personalDetails } : undefined),
    };
  };

  private checkProfileDuplicates = async () => {
    const {
      checkIndividualProfileMatching,
      profileId,
      isProfileDuplicateDetected,
      isGuestAcceptedProfileDuplicateRisk,
    } = this.props;

    if (isProfileDuplicateDetected && isGuestAcceptedProfileDuplicateRisk)
      return false;
    await checkIndividualProfileMatching(profileId);

    const { isProfileDuplicateDetected: updatedIsProfileDuplicateDetected } =
      this.props;

    return (
      updatedIsProfileDuplicateDetected && !isGuestAcceptedProfileDuplicateRisk
    );
  };
}

const mapStateToProps = (state: Store) => ({
  errors: getErrors(state),
  profileId: getProfileId(state),
  isProfileDuplicateDetected: getIsProfileDuplicateDetected(state),
  isGuestAcceptedProfileDuplicateRisk:
    getIfGuestAcceptedProfileDuplicateRisk(state),
  confirmedScannedDocument: getConfirmedScannedDocument(state),
  documents: getActiveDocuments(state),
  cachedDocumentFormData: getCachedDocumentFormData(state),
  previouslyAddedDocumentId: getIdOfPreviouslyAddedDocument(state),
});

const mapDispatchToProps = {
  checkIndividualProfileMatching,
  resetAppProgress,
  setIsGuestAcceptedProfileDuplicateRisk,
  setDocumentUpdatePayload,
  sendUpdateProfileData,
  cacheDocumentFormData,
  fetchProfile,
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckInID) as (props: PassedProps) => JSX.Element;

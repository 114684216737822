import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FormHeader, FormSelect, FormTextField, SelectModal } from 'components';
import { ids } from 'config';
import { compose } from 'redux';
import {
  change,
  Field,
  Form,
  getFormValues,
  initialize,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { fetchProfileVersion } from 'store/profile/actions';
import { getParentVersion, getProfileAddress } from 'store/profile/selectors';
import { getProfileId } from 'store/reservation/selectors';
import { Address } from 'types/Api/Profile';
import FormSelectOption from 'types/FormSelectOption';
import Store from 'types/Store';
import { Configurator } from 'utils';
import { isFormValid, required } from 'utils/Validator';

import {
  PatchOperation,
  PatchProfileAddressDetailsRequest,
  PatchProfileSectionRequest,
} from '@ac/library-api';

import {
  BaseKioskConfigurationEntity,
  KioskStateDetails,
} from '@gss/api/KioskApi/entries';
import {
  fetchDistricts,
  fetchStates,
} from '@gss/store/lazyLoadedDictionary/actions';
import {
  getAllDistrictResults,
  getAllStateResults,
} from '@gss/store/lazyLoadedDictionary/selectors';
import { mapSelectOptions } from '@gss/utils';
import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';
import { Grid } from '@material-ui/core';

const SPACING = 2;

interface FormValues {
  addressLine1: string;
  addressLine2: string;
  postalCode: string;
  city: string;
  countryCode: string;
  stateCode: string;
  districtId?: string;
}

interface PassedProps {
  setSubmitState: (isFormValid: boolean) => void;
  onSubmit: (
    data: PatchProfileSectionRequest<PatchProfileAddressDetailsRequest>
  ) => void;
  isTitleDisabled?: boolean;
  isSubtitleDisabled?: boolean;
  shouldInitialize?: boolean;
  isCompany?: boolean;
  companyAddress?: Address;
}

interface AddressFormProps
  extends PassedProps,
    RouteComponentProps,
    InjectedFormProps<FormValues>,
    WithTranslation {
  onChange: (form: string, field: string, value: any) => void;
  fetchProfileVersion: typeof fetchProfileVersion;
  onInitialize: (form: string, data: FormValues) => void;
  fetchStates: typeof fetchStates.trigger;
  fetchDistricts: typeof fetchDistricts.trigger;
  parentVersion: string;
  profileId: string;
  address: Address | undefined;
  states: Record<string, KioskStateDetails[]>;
  districts: Record<string, BaseKioskConfigurationEntity[]>;
  values: FormValues;
  dirty: boolean;
}

interface AddressFormState {
  isStateModalOpen: boolean;
  isCountryModalOpen: boolean;
  isDistrictModalOpen: boolean;
  countries: FormSelectOption[];
}

class AddressForm extends PureComponent<AddressFormProps, AddressFormState> {
  public static defaultProps = {
    isTitleDisabled: false,
    isSubtitleDisabled: false,
    shouldInitialize: true,
    isCompany: false,
  };

  public state = {
    isCountryModalOpen: false,
    isStateModalOpen: false,
    isDistrictModalOpen: false,
    countries: [
      {
        title: '',
        value: '',
      },
    ],
  };

  public componentDidUpdate() {
    const { setSubmitState } = this.props;
    setSubmitState(isFormValid(this.props));
  }

  public async componentDidMount() {
    const { shouldInitialize } = this.props;
    const countries = this.getCountries();
    const countryCode = this.getDefaultCountryCode();

    this.setState({ countries });
    await this.getAddressDictionaries(countryCode);

    if (shouldInitialize) this.initializeForm();
  }

  public render() {
    const {
      t,
      handleSubmit,
      isTitleDisabled,
      isSubtitleDisabled,
      onSubmit,
      values,
      ...props
    } = this.props;
    const {
      isCountryModalOpen,
      isStateModalOpen,
      isDistrictModalOpen,
      countries,
    } = this.state;
    const address = this.getAddress();
    const formStateCode = values?.stateCode ?? '';
    const stateCode = formStateCode || address.stateCode;
    const districtId = values?.districtId || address?.districtId || '';
    const isDistrictEnabled = Boolean(
      Configurator.getSwitch(Configurator.switchCodes.DISTRICT)
    );

    return (
      <div>
        <FormHeader
          title={isTitleDisabled ? '' : t('PROVIDE_ADDRESS_DETAILS')}
          subtitle={isSubtitleDisabled ? '' : t('YOU_MAY_SKIP_OPTIONAL')}
          formName={t('ADDRESS')}
          isFormValid={isFormValid(props)}
        />
        <SelectModal
          title={t('COUNTRY')}
          placeholder={t('START_SEARCHING')}
          options={countries}
          onSelect={this.onCountrySelect}
          onCancel={this.closeSelectModal}
          open={isCountryModalOpen}
          initialValue={address.countryCode}
        />
        <SelectModal
          title={t('STATE')}
          placeholder={t('START_SEARCHING')}
          options={this.states}
          onSelect={this.onStateSelect}
          onCancel={this.closeSelectModal}
          open={isStateModalOpen}
          initialValue={stateCode}
        />
        <SelectModal
          title={t('SHARED.DISTRICT')}
          placeholder={t('START_SEARCHING')}
          options={this.districts}
          onSelect={this.onDistrictSelect}
          onCancel={this.closeSelectModal}
          open={isDistrictModalOpen}
          initialValue={districtId}
        />
        <Form onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
          <Grid container spacing={SPACING}>
            <Grid item xs={12} sm={6} md={12}>
              <Field
                name="addressLine1"
                component={FormTextField}
                validate={[required]}
                label={t('ADDRESS_LINE_1')}
                autoComplete="none"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <Field
                name="addressLine2"
                type="text"
                component={FormTextField}
                label={t('ADDRESS_LINE_2')}
                autoComplete="none"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={4}>
              <Field
                name="postalCode"
                type="text"
                validate={[required]}
                component={FormTextField}
                label={t('CODE')}
                autoComplete="none"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={8}>
              <Field
                name="city"
                type="text"
                validate={[required]}
                component={FormTextField}
                label={t('CITY')}
                autoComplete="none"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                onInputMouseDown={this.onCountryClick}
                name="countryCode"
                type="text"
                validate={[required]}
                component={FormSelect}
                options={countries}
                label={t('COUNTRY')}
                onChange={(event: any) =>
                  this.getAddressDictionaries(event.target.value)
                }
                empty="true"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                onInputMouseDown={this.onStateClick}
                name="stateCode"
                type="text"
                component={FormSelect}
                options={this.states}
                label={t('STATE')}
                empty="true"
                disabled={!values?.countryCode}
              />
            </Grid>
            {isDistrictEnabled && (
              <Grid item xs={12} sm={6}>
                <Field
                  onInputMouseDown={this.onDistrictClick}
                  name="districtId"
                  type="text"
                  component={FormSelect}
                  options={this.districts}
                  label={t('SHARED.DISTRICT')}
                  empty="true"
                  disabled={!values?.countryCode}
                />
              </Grid>
            )}
          </Grid>
        </Form>
      </div>
    );
  }

  private initializeForm = () => {
    const { onInitialize } = this.props;
    const data = this.getAddress();
    onInitialize(ids.ADDRESS_FORM, data);
  };

  private getAddress = () => {
    const { address: personalAddress, companyAddress, isCompany } = this.props;

    const address = isCompany ? companyAddress : personalAddress;
    const isDistrictEnabled = Boolean(
      Configurator.getSwitch(Configurator.switchCodes.DISTRICT)
    );

    return {
      addressLine1: address?.addressLine1 ?? '',
      addressLine2: address?.addressLine2 ?? '',
      city: address?.city ?? '',
      postalCode: address?.postCode ?? '',
      stateCode: address?.stateCode ?? '',
      ...(isDistrictEnabled && { districtId: address?.districtId ?? '' }),
      countryCode: address?.countryCode ?? '',
    };
  };

  private getDefaultCountryCode = () => {
    const { address: personalAddress, companyAddress, isCompany } = this.props;
    const address = isCompany ? companyAddress : personalAddress;

    return address?.countryCode ?? '';
  };

  private closeSelectModal = () => {
    this.setState({
      isCountryModalOpen: false,
      isStateModalOpen: false,
      isDistrictModalOpen: false,
    });
  };

  private onCountryClick = (event: React.MouseEvent) => {
    event.preventDefault();
    this.setState({
      isCountryModalOpen: true,
    });
  };

  private onStateClick = (event: React.MouseEvent) => {
    event.preventDefault();
    this.setState({
      isStateModalOpen: true,
    });
  };

  private onDistrictClick = (event: React.MouseEvent) => {
    event.preventDefault();
    this.setState({
      isDistrictModalOpen: true,
    });
  };

  private onStateSelect = (value: string | null) => {
    return this.onModalSelect('stateCode')(value);
  };

  private onDistrictSelect = (value: string | null) => {
    return this.onModalSelect('districtId')(value);
  };

  private onCountrySelect = (value: string) => {
    const isDistrictEnabled = Boolean(
      Configurator.getSwitch(Configurator.switchCodes.DISTRICT)
    );

    this.getAddressDictionaries(value);
    this.onStateSelect(null);
    if (isDistrictEnabled) {
      this.onDistrictSelect(null);
    }

    return this.onModalSelect('countryCode')(value);
  };

  private onModalSelect = (field: string) => (value: string | null) => {
    const { onChange } = this.props;
    onChange(ids.ADDRESS_FORM, field, value);
    this.closeSelectModal();
  };

  private getCountries = (): FormSelectOption[] => {
    const { i18n } = this.props;

    return Configurator.getCountries(i18n.language)
      .filter((country) => country.name && country.code)
      .map((country) => ({
        title: country.name,
        value: country.code,
      })) as FormSelectOption[];
  };

  private getAddressDictionaries = (countryCode: string | undefined) => {
    const { fetchStates, fetchDistricts, i18n } = this.props;
    const isDistrictEnabled = Boolean(
      Configurator.getSwitch(Configurator.switchCodes.DISTRICT)
    );

    if (!countryCode) return;
    fetchStates({
      countryCode,
      languageCode: i18n.language,
      skipCache: true,
    });
    if (!isDistrictEnabled) return;
    fetchDistricts({
      countryCode,
      languageCode: i18n.language,
      skipCache: true,
    });
  };

  private findState = (stateCode: string, countryCode: string) => {
    const { states } = this.props;

    return (states[countryCode] || []).find(
      (state) => state.code === stateCode
    );
  };

  private onSubmit = async (values: FormValues) => {
    const { companyAddress, onSubmit } = this.props;

    return onSubmit(this.getFormattedAddress(companyAddress, values));
  };

  private get addressTypeId(): string {
    const { address, companyAddress } = this.props;

    const {
      getActiveEntityCodeOrDefault,
      addressTypes,
      defaultGuestAddressType,
      defaultCompanyAddressType,
    } = Configurator;

    const validCompanyAddressCode = getActiveEntityCodeOrDefault(
      addressTypes,
      companyAddress?.typeCode,
      defaultCompanyAddressType?.code
    );

    const validGuestAddressCode = getActiveEntityCodeOrDefault(
      addressTypes,
      address?.typeCode,
      defaultGuestAddressType?.code
    );

    const addressTypeCode = companyAddress
      ? validCompanyAddressCode
      : validGuestAddressCode;

    return addressTypes.find(({ code }) => code === addressTypeCode)
      ?.id as string;
  }

  private get states() {
    const { states, values } = this.props;
    const countryCode = values?.countryCode || this.getDefaultCountryCode();

    if (!states || !countryCode) return [];
    const selectedCountryStates = states[values?.countryCode];

    return mapSelectOptions(selectedCountryStates);
  }

  private get districts() {
    const { districts, values } = this.props;
    const countryCode = values?.countryCode || this.getDefaultCountryCode();

    if (!districts || !countryCode) return [];
    const selectedDictionaries = districts[values?.countryCode];

    return mapSelectOptions(selectedDictionaries, 'description', 'id');
  }

  private getFormattedAddress = (
    address: Address | undefined,
    { stateCode, countryCode, ...rest }: FormValues
  ): PatchProfileSectionRequest<PatchProfileAddressDetailsRequest> => {
    const selectedState = this.findState(stateCode, countryCode);
    const { i18n } = this.props;

    return {
      operation: address ? PatchOperation.Update : PatchOperation.Add,
      id: address?.id,
      value: {
        ...rest,
        countryCode,
        isPrimary: address?.primary ?? true,
        isArPrimary: address?.arPrimary ?? true,
        languageCode: address?.languageCode ?? i18n.language.toUpperCase(),
        ...(selectedState?.code && { stateCode: selectedState?.code }),
        typeId: this.addressTypeId,
      },
    };
  };
}

const mapStateToProps = (state: Store, props: AddressFormProps) => ({
  address: getProfileAddress(state),
  parentVersion: getParentVersion(state),
  profileId: getProfileId(state),
  states: getAllStateResults(state),
  districts: getAllDistrictResults(state),
  values: getFormValues(ids.ADDRESS_FORM)(state),
});

const mapDispatchToProps = {
  fetchStates: fetchStates.trigger,
  fetchDistricts: fetchDistricts.trigger,
  fetchProfileVersion,
  onInitialize: initialize,
  onChange: change,
};

export default compose(
  withRouter,
  withTranslation(),
  reduxForm({ form: ids.ADDRESS_FORM, destroyOnUnmount: false }),
  connect(mapStateToProps, mapDispatchToProps)
)(AddressForm) as (props: PassedProps) => JSX.Element;

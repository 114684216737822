import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { RouteName, routes, RoutesViews } from '@gss/router';
import { requireDeviceConfiguration } from '@gss/utils/hoc';
import { legacyRoutes } from '@LEGACY/views';

import { checkInStepsBinds } from './checkIn/checkInStepsBinds';
import { checkOutStepsBinds } from './checkOut/checkOutStepsBinds';
import { initialStepsBinds } from './initialSteps/initialStepsBinds';
import { makeKeysStepsBinds } from './makeKeys/makeKeysStepsBinds';
import { Welcome } from './Welcome';

export const stepsBinds: RoutesViews<typeof routes> = {
  ...initialStepsBinds,
  ...checkInStepsBinds,
  ...checkOutStepsBinds,
  ...makeKeysStepsBinds,
  WELCOME: requireDeviceConfiguration(Welcome),
};

const stepsBindsEntries = Object.entries(stepsBinds) as Array<
  [RouteName, React.ComponentType]
>;

export const AppRoutes = (): ReturnType<typeof useRoutes> => {
  return useRoutes([
    ...stepsBindsEntries.map(([routeName, component]): RouteObject => {
      return {
        path: routes[routeName].path,
        element: React.createElement(component),
      };
    }),
    ...legacyRoutes,
    {
      path: '*',
      element: React.createElement(Navigate, {
        to: routes.LOGIN.path,
        replace: true,
      }),
    },
  ]);
};

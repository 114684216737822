import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FormTextField } from 'components';
import { ids } from 'config';
import { compose } from 'redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import {
  getDetailedFolios,
  getFoliosWithCommunicationChannels,
  isEmailPerFolio,
} from 'store/cashiering/selectors';
import {
  fetchProfile,
  fetchProfileFromFolio,
  updateCompanyCommunicationChannels,
  updateGuestCommunicationChannels,
  updateIndividualCommunicationChannels,
  updateTravelAgentCommunicationChannels,
} from 'store/profile/actions';
import {
  getAllProfiles,
  getCompanyCommunicationChannels,
  getEmail,
  getIndividualCommunicationChannels,
  getParentVersion,
  getProfileFromFolio,
  getTravelAgentCommunicationChannels,
  getTravelAgents,
} from 'store/profile/selectors';
import { getProfileId } from 'store/reservation/selectors';
import { FolioWithCommunicationChannels } from 'types/Api/Cashiering';
import { CommunicationChannel, Profile, TravelAgent } from 'types/Api/Profile';
import Store from 'types/Store';
import { Configurator, Router } from 'utils';
import { email as emailValidator, required } from 'utils/Validator';

import { Button, ButtonPattern } from '@ac/kiosk-components';
import { CommunicationMode, PatchOperation } from '@ac/library-api';

import { fetchFolioProfile } from '@LEGACY/store/cashiering/actions';
import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';
import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './MissingEmail.style';

interface PassedProps {
  onSubmit?: (values: any) => void;
  currentFolioNumber?: number;
}

interface MissingEmailProps
  extends PassedProps,
    WithTranslation,
    InjectedFormProps,
    RouteComponentProps,
    WithStyles<typeof styles> {
  updateIndividualCommunicationChannels: typeof updateIndividualCommunicationChannels;
  updateCompanyCommunicationChannels: typeof updateCompanyCommunicationChannels;
  updateTravelAgentCommunicationChannels: typeof updateTravelAgentCommunicationChannels;
  updateGuestCommunicationChannels: typeof updateGuestCommunicationChannels;
  fetchProfile: (profileId: string) => void;
  profile: Profile;
  profileId: string;
  parentVersion: string;
  email: {
    details: string;
    [index: string]: any;
  };
  currentFolioNumber: number;
  folios: FolioWithCommunicationChannels[];
  profiles: Profile[];
  travelAgents: TravelAgent[];
  guestCommunicationChannels: CommunicationChannel[];
  companyCommunicationChannels: CommunicationChannel[];
  travelAgentCommunicationChannels: CommunicationChannel[];
  individualCommunicationChannels: CommunicationChannel[];
  fetchProfileFromFolio: typeof fetchProfileFromFolio;
  profileFromFolio: Profile[];
  foliosWithCommunicationChannels: FolioWithCommunicationChannels[];
  isEmailPerFolio: (folio: FolioWithCommunicationChannels) => boolean;
  fetchFolioProfile: typeof fetchFolioProfile;
}

class MissingEmail extends PureComponent<MissingEmailProps> {
  public static defaultProps = {
    currentFolioNumber: 0,
  };

  public render() {
    const { t, classes, invalid, handleSubmit } = this.props;

    return (
      <Grid className={classes.content}>
        <Form
          className={classes.formWrapper}
          onSubmit={handleSubmit(this.onSubmit)}
        >
          <Typography className={classes.title}>
            {this.missingEmailMessage}
          </Typography>
          <Field
            name="email"
            type="email"
            validate={[required, emailValidator]}
            component={FormTextField}
            className={classes.emailInput}
          />
          <Grid className={classes.bottomWrapper}>
            <Button onClick={this.onCancel} pattern={ButtonPattern.secondary}>
              {t('CANCEL')}
            </Button>
            <Button type="submit" disabled={invalid}>
              {t('CONFIRM')}
            </Button>
          </Grid>
        </Form>
      </Grid>
    );
  }

  private get missingEmailMessage() {
    const {
      folioTypeCodes: { INDIVIDUAL, COMPANY, TRAVEL_AGENT },
    } = Configurator;
    const {
      foliosWithCommunicationChannels,
      currentFolioNumber,
      email: guestEmail,
      t,
    } = this.props;

    const currentFolio = foliosWithCommunicationChannels[currentFolioNumber];
    const folioCode = currentFolio?.folioTypeCode?.code;

    if (!guestEmail) {
      return t('PROVIDE_MISSING_INDIVIDUAL_EMAIL_ADDRESS');
    }

    switch (folioCode) {
      case INDIVIDUAL: {
        return t('PROVIDE_MISSING_INDIVIDUAL_EMAIL_ADDRESS');
      }
      case COMPANY: {
        return t('PROVIDE_MISSING_COMPANY_EMAIL_ADDRESS');
      }
      case TRAVEL_AGENT: {
        return t('PROVIDE_MISSING_TRAVEL_AGENT_EMAIL_ADDRESS');
      }
      default: {
        return t('PROVIDE_MISSING_EMAIL_ADDRESS');
      }
    }
  }

  private getVersion = (
    type: string,
    isGuestFolio: boolean
  ): string | undefined => {
    const {
      parentVersion,
      foliosWithCommunicationChannels,
      currentFolioNumber,
      profileFromFolio,
    } = this.props;
    const {
      folioTypeCodes: { INDIVIDUAL, COMPANY, TRAVEL_AGENT },
    } = Configurator;

    const currentFolio = foliosWithCommunicationChannels[currentFolioNumber];

    if (!currentFolio) {
      return undefined;
    }
    const {
      profile: { version: currentVersionNumber },
    } = currentFolio;

    let version;

    switch (type) {
      case INDIVIDUAL: {
        if (isGuestFolio) return parentVersion;
        version = profileFromFolio.length && profileFromFolio[0].version;
        break;
      }
      case COMPANY:
      case TRAVEL_AGENT: {
        version = currentVersionNumber;
        break;
      }
      default: {
        version = parentVersion;
        break;
      }
    }

    return String(version);
  };

  private onSubmit = async (values: any) => {
    const {
      updateGuestCommunicationChannels,
      updateIndividualCommunicationChannels,
      updateCompanyCommunicationChannels,
      updateTravelAgentCommunicationChannels,
      foliosWithCommunicationChannels,
      currentFolioNumber,
      onSubmit,
      profileId: guestProfileId,
      parentVersion,
      fetchProfile,
      fetchProfileFromFolio,
      fetchFolioProfile,
    } = this.props;
    const {
      folioTypeCodes: { INDIVIDUAL, COMPANY, TRAVEL_AGENT },
      defaultCommunicationEmailType,
    } = Configurator;

    await fetchProfile(guestProfileId);
    const { email: guestEmail } = this.props;

    const email = {
      operation: PatchOperation.Add,
      value: {
        typeId: defaultCommunicationEmailType?.id,
        mode: CommunicationMode.Email,
        details: values.email,
        primary: true,
      },
    };

    if (!guestEmail) {
      await updateGuestCommunicationChannels(guestProfileId, parentVersion, [
        email,
      ]);
    }

    const currentFolio = foliosWithCommunicationChannels[currentFolioNumber];

    if (currentFolio) {
      const {
        id: currentFolioId,
        profileId: currentFolioProfileId,
        folioTypeCode: { code },
      } = currentFolio;
      if (code === INDIVIDUAL) {
        await fetchProfileFromFolio(currentFolioProfileId);
      }
      await fetchFolioProfile(currentFolioProfileId, currentFolioId);

      const isGuestFolio = currentFolioProfileId === guestProfileId;
      const version = this.getVersion(code, isGuestFolio);
      const hasFolioEmail = isEmailPerFolio(currentFolio);

      if (!!guestEmail && !hasFolioEmail && version) {
        switch (code) {
          case INDIVIDUAL:
            await updateIndividualCommunicationChannels(
              currentFolioProfileId,
              version,
              [email]
            );
            break;
          case COMPANY:
            await updateCompanyCommunicationChannels(
              currentFolioProfileId,
              version,
              [email]
            );
            break;
          case TRAVEL_AGENT:
            await updateTravelAgentCommunicationChannels(
              currentFolioProfileId,
              version,
              [email]
            );
            break;
          default:
            return;
        }
      }
    }
    if (onSubmit) return onSubmit(values);
  };

  private onCancel = () => {
    const { history } = this.props;
    history.push(Router.paths.WELCOME);
  };
}

const mapStateToProps = (state: Store) => ({
  parentVersion: getParentVersion(state),
  folios: getDetailedFolios(state),
  profileId: getProfileId(state),
  email: getEmail(state),
  profiles: getAllProfiles(state),
  travelAgents: getTravelAgents(state),
  companyCommunicationChannels: getCompanyCommunicationChannels(state),
  travelAgentCommunicationChannels: getTravelAgentCommunicationChannels(state),
  individualCommunicationChannels: getIndividualCommunicationChannels(state),
  profileFromFolio: getProfileFromFolio(state),
  foliosWithCommunicationChannels: getFoliosWithCommunicationChannels(state),
});

const mapDispatchToProps = {
  updateIndividualCommunicationChannels,
  updateCompanyCommunicationChannels,
  updateTravelAgentCommunicationChannels,
  updateGuestCommunicationChannels,
  fetchProfileFromFolio,
  fetchProfile,
  fetchFolioProfile,
};

export default compose(
  withTranslation(),
  withRouter,
  reduxForm({ form: ids.MISSING_EMAIL_FORM }),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(MissingEmail) as (props: PassedProps) => JSX.Element;

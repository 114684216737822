import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import ReactImageCrop from 'react-image-crop';
import { connect } from 'react-redux';
import Webcam from 'react-webcam';
import classNames from 'classnames';
import { compose } from 'redux';
import { isIPad } from 'store/ui/selectors';
import { TypographyVariant } from 'types/MaterialUI';
import Store from 'types/Store';

import { Button, ButtonPattern, Loader } from '@ac/kiosk-components';

import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';
import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import { saveIdPhoto } from '../../store/actions';
import { getIdPhotosNumber } from '../../store/selectors';
import EditorMenu from '../EditorMenu';

import styles, {
  getBackground,
  WEBCAM_HEIGHT,
  WEBCAM_WIDTH,
} from './WebcamPhotoCapture.style';

interface PassedProps {
  shouldCrop?: boolean;
  toggleModal: () => void;
  kioskProPhoto?: string;
  onCaptureReset: () => void;
  onCaptureRetry: () => void;
}

interface WebcamPhotoCaptureState {
  isLoading: boolean;
  currentPhoto: string;
  croppedPhoto: string;
  isEditing: boolean;
  flipVertical: boolean;
  flipHorizontal: boolean;
  rotate: number;
  stepRotation: number;
  crop: {
    unit: 'px' | '%';
    width: number;
    height: number;
    x: number;
    y: number;
  };
}

interface WebcamPhotoCaptureProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  saveIdPhoto: typeof saveIdPhoto;
  photosNumber: number;
  isIPad?: boolean;
}

class WebcamPhotoCapture extends PureComponent<
  WebcamPhotoCaptureProps,
  WebcamPhotoCaptureState
> {
  public static defaultProps = {
    shouldCrop: false,
    isIPad: false,
  };

  public state: WebcamPhotoCaptureState = {
    isLoading: true,
    isEditing: false,
    flipVertical: false,
    flipHorizontal: false,
    rotate: 0,
    stepRotation: 0,
    currentPhoto: '',
    croppedPhoto: '',
    crop: {
      unit: 'px',
      width: WEBCAM_WIDTH * 0.9,
      height: WEBCAM_HEIGHT * 0.9,
      x: 0,
      y: 0,
    },
  };

  public webcam: Webcam | any;

  private imageRef: any;

  public componentDidMount() {
    const { kioskProPhoto } = this.props;
    if (kioskProPhoto) {
      this.setState({ currentPhoto: kioskProPhoto });
    }
  }

  public render() {
    const { classes, t, kioskProPhoto } = this.props;
    const { isLoading, currentPhoto, rotate, isEditing, stepRotation } =
      this.state;

    return (
      <Grid className={classes.wrapper}>
        <Grid className={classes.titleWrapper}>
          <Typography className={classes.title}>
            {this.getTitleText()}
          </Typography>
        </Grid>
        <div className={classes.cameraWrapper}>
          {!kioskProPhoto && isLoading && (
            <Loader title={t('LOADING_CAMERA')} />
          )}
          {this.renderWebcamContent()}
        </div>
        {isEditing && (
          <EditorMenu
            onChange={this.onRotate}
            onFlipVertical={this.flipVertical}
            onFlipHorizontal={this.flipHorizontal}
            onRotateLeft={this.removeRotation}
            onRotateRight={this.addRotation}
            value={rotate + stepRotation}
            onReset={this.resetRotation}
          />
        )}
        <Grid className={classNames(classes.buttonWrapper, 'spacing-top-lg')}>
          <Button onClick={this.onCancel} pattern={ButtonPattern.secondary}>
            {t('CANCEL')}
          </Button>
          <Grid className={classes.buttonWrapper}>
            {currentPhoto && !isEditing && (
              <Button
                pattern={ButtonPattern.secondary}
                onClick={this.onRetry}
                className={classes.retryButton}
              >
                {t('TRY_AGAIN')}
              </Button>
            )}
            {!isEditing && currentPhoto && (
              <Button
                pattern={ButtonPattern.secondary}
                onClick={this.toggleEdit}
                className={classes.editButton}
              >
                {t('EDIT')}
              </Button>
            )}
            <Button onClick={this.onCaptureClick}>
              {this.getAcceptButtonText()}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  private getTitleText = () => {
    const { t } = this.props;
    const { isEditing, currentPhoto } = this.state;
    if (!currentPhoto) return t('TAKE_PICTURE');

    return isEditing ? t('USE_BUTTONS_TO_APPLY') : t('CONFIRM_PICTURE_OR_EDIT');
  };

  private changeStepRotation = async (value: number) => {
    await this.setState({ stepRotation: value });
    this.refreshImage();
  };

  private addRotation = () => {
    const { stepRotation } = this.state;
    if (stepRotation === 315) return this.changeStepRotation(0);
    this.changeStepRotation(stepRotation + 45);
  };
  private removeRotation = () => {
    const { stepRotation } = this.state;
    if (stepRotation === -315) return this.changeStepRotation(0);
    this.changeStepRotation(stepRotation - 45);
  };

  private getAcceptButtonText = () => {
    const { croppedPhoto, currentPhoto, isEditing } = this.state;
    const { shouldCrop, t, kioskProPhoto } = this.props;
    const photo = kioskProPhoto || croppedPhoto || currentPhoto;
    if (isEditing) return t('SAVE_CHANGES');
    const isPhotoCaptured = photo || (currentPhoto && !shouldCrop);
    const buttonText = t(isPhotoCaptured ? 'CONFIRM' : 'CAPTURE');

    return buttonText;
  };

  private onRotate = async (value: number) => {
    await this.setState({ rotate: value });
    this.refreshImage();
  };

  private renderWebcamContent = () => {
    const { currentPhoto, crop, isEditing } = this.state;
    const { shouldCrop, classes, kioskProPhoto } = this.props;
    const photo = kioskProPhoto || currentPhoto;
    if (photo && shouldCrop && isEditing) {
      return (
        <ReactImageCrop
          src={photo}
          crop={crop}
          onChange={this.onCropChange}
          onImageLoaded={this.onImageLoaded}
          onComplete={this.onCropComplete}
          imageStyle={this.getImageStyle()}
          style={{ backgroundColor: '#fff' }}
          className={classes.crop}
        />
      );
    }
    if (photo) {
      return (
        <Grid className={classes.webcamCapture} style={getBackground(photo)} />
      );
    }

    return (
      <Webcam
        ref={this.setRef}
        audio={false}
        screenshotFormat="image/jpeg"
        className={classes.webcam}
        onUserMedia={this.loadCamera}
      />
    );
  };

  private onImageLoaded = (image: any) => {
    this.imageRef = image;
  };

  private makeClientCrop = async (crop: any) => {
    if (this.imageRef && crop.width && crop.height) {
      const base64object: any = await this.getCroppedImg();
      this.setState({ croppedPhoto: base64object.result });
    }
  };

  private onCropComplete = (crop: any) => {
    this.makeClientCrop(crop);
  };

  private getCroppedImg = async () => {
    const image = await this.getRotatedImg();
    const { crop } = this.state;
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d')!;
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          // eslint-disable-next-line no-console
          console.error('Canvas is empty');

          return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          resolve({ result: reader.result });
        };
      }, 'image/jpeg');
    });
  };

  private onCropChange = (crop: any) => {
    this.setState({ crop });
  };

  private flipVertical = async () => {
    const { flipVertical } = this.state;
    await this.setState({ flipVertical: !flipVertical });
    this.refreshImage();
  };

  private flipHorizontal = async () => {
    const { flipHorizontal } = this.state;
    await this.setState({ flipHorizontal: !flipHorizontal });
    this.refreshImage();
  };

  private getRotatedImg = () => {
    const { stepRotation, rotate, flipVertical, flipHorizontal } = this.state;
    const image = this.imageRef;
    const canvas = document.createElement('canvas');
    const width = image.width;
    const height = image.height;
    canvas.width = width;
    canvas.height = height;
    const middleX = width / 2;
    const middleY = height / 2;
    const ctx = canvas.getContext('2d')!;
    ctx.fillStyle = '#fff';
    ctx.fillRect(-width, -height, width * 3, height * 3);
    ctx.translate(middleX, middleY);
    ctx.rotate(((rotate + stepRotation) * Math.PI) / 180);
    ctx.translate(-middleX, -middleY);
    if (flipHorizontal) {
      ctx.translate(0, height);
      ctx.scale(1, -1);
    }
    if (flipVertical) {
      ctx.translate(width, 0);
      ctx.scale(-1, 1);
    }
    ctx.drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL();
    const img = document.createElement('img');
    img.src = dataUrl;
    canvas.remove();

    return img;
  };

  private getImageStyle = () => {
    const { rotate, stepRotation, flipHorizontal, flipVertical } = this.state;
    const scaleX = flipVertical ? ' scaleX(-1)' : '';
    const scaleY = flipHorizontal ? ' scaleY(-1)' : '';
    const styles = {
      transform: `rotate(${rotate + stepRotation}deg)${scaleX}${scaleY}`,
    };

    return styles;
  };

  private loadCamera = () => this.setState({ isLoading: false });

  private onCaptureClick = async () => {
    const { toggleModal, onCaptureReset, isIPad } = this.props;
    const { currentPhoto } = this.state;
    if (!currentPhoto) {
      const currentPhoto = this.webcam.getScreenshot();

      return this.setState({ currentPhoto });
    }
    await this.savePhoto();
    if (isIPad) onCaptureReset();
    this.clearState();
    const { photosNumber } = this.props;
    if (photosNumber > 1 && !isIPad) return toggleModal();
  };

  private toggleEdit = () => {
    const { isEditing } = this.state;
    this.setState({ isEditing: !isEditing });
  };

  private savePhoto = () => {
    const { croppedPhoto, currentPhoto } = this.state;
    const { saveIdPhoto, shouldCrop } = this.props;
    if (croppedPhoto && shouldCrop) return saveIdPhoto(croppedPhoto);

    return currentPhoto && saveIdPhoto(currentPhoto);
  };

  private onCancel = () => {
    const { toggleModal, isIPad, onCaptureReset } = this.props;
    this.clearState();
    if (isIPad) onCaptureReset();
    toggleModal();
  };

  private setRef = (webcam: any) => {
    this.webcam = webcam;
  };

  private refreshImage = () => {
    const { crop } = this.state;
    this.makeClientCrop(crop);
  };
  private onRetry = () => {
    const { onCaptureRetry, isIPad } = this.props;
    this.clearState();
    if (isIPad) onCaptureRetry();
  };
  private clearState = () => {
    this.setState({
      croppedPhoto: '',
      currentPhoto: '',
      isLoading: true,
      isEditing: false,
      flipVertical: false,
      flipHorizontal: false,
      rotate: 0,
      stepRotation: 0,
      crop: {
        unit: '%',
        width: 90,
        height: 90,
        x: 0,
        y: 0,
      },
    });
  };

  private resetRotation = () => {
    this.setState({ rotate: 0, stepRotation: 0 });
  };
}

const mapStateToProps = (state: Store) => ({
  photosNumber: getIdPhotosNumber(state),
  isIPad: isIPad(state),
});

const mapDispatchToProps = {
  saveIdPhoto,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(WebcamPhotoCapture) as (props: PassedProps) => JSX.Element;

import { PrivateRoute } from 'components';
import { allSteps } from 'utils/Router';

import CheckInAddons from './CheckInAddons';
import CheckInAuth from './CheckInAuth';
import CheckInCommunicationDetails from './CheckInCommunicationDetails';
import CheckInComplete from './CheckInComplete';
import CheckInConfirmation from './CheckInConfirmation';
import CheckInID from './CheckInID';
import CheckInIDScanning from './CheckInIDScanning';
import CheckInMultiRoom from './CheckInMultiRoom';
import CheckInNameDetails from './CheckInNameDetails';
import CheckInPayment from './CheckInPayment';
import CheckInPreAuthorization from './CheckInPreAuthorization';
import CheckInPrepayment from './CheckInPrepayment';
import CheckInRoom from './CheckInRoom';
import CheckInSummary from './CheckInSummary';
import CheckOutAuth from './CheckOutAuth';
import CheckOutCharges from './CheckOutCharges';
import CheckOutComplete from './CheckOutComplete';
import CheckOutPayment from './CheckOutPayment';
import CheckOutReservations from './CheckOutReservations';
import GenerateKeys from './GenerateKeys';

export const legacyRoutes = [
  /* Check-in steps */
  {
    path: allSteps.CHECK_IN.AUTH.url,
    element: <PrivateRoute component={CheckInAuth} />,
  },
  {
    path: allSteps.CHECK_IN.MULTIROOM.url,
    element: <PrivateRoute component={CheckInMultiRoom} />,
  },
  {
    path: allSteps.CHECK_IN.NAME_DETAILS.url,
    element: <PrivateRoute component={CheckInNameDetails} />,
  },
  {
    path: allSteps.CHECK_IN.ADDONS.url,
    element: <PrivateRoute component={CheckInAddons} />,
  },
  {
    path: allSteps.CHECK_IN.COMMUNICATION_DETAILS.url,
    element: <PrivateRoute component={CheckInCommunicationDetails} />,
  },
  {
    path: allSteps.CHECK_IN.ID_SCANNING.url,
    element: <PrivateRoute component={CheckInIDScanning} />,
  },
  {
    path: allSteps.CHECK_IN.ID.url,
    element: <PrivateRoute component={CheckInID} />,
  },
  {
    path: allSteps.CHECK_IN.ROOM.url,
    element: <PrivateRoute component={CheckInRoom} />,
  },
  {
    path: allSteps.CHECK_IN.PAYMENT.url,
    element: <PrivateRoute component={CheckInPayment} />,
  },
  {
    path: allSteps.CHECK_IN.PREPAYMENT.url,
    element: <PrivateRoute component={CheckInPrepayment} />,
  },
  {
    path: allSteps.CHECK_IN.PRE_AUTHORIZATION.url,
    element: <PrivateRoute component={CheckInPreAuthorization} />,
  },
  {
    path: allSteps.CHECK_IN.NUMBER_OF_KEYS.url,
    element: <PrivateRoute component={GenerateKeys} />,
  },
  {
    path: allSteps.CHECK_IN.CONFIRMATION.url,
    element: <PrivateRoute component={CheckInConfirmation} />,
  },
  {
    path: allSteps.CHECK_IN.SUMMARY.url,
    element: <PrivateRoute component={CheckInSummary} />,
  },
  {
    path: allSteps.CHECK_IN.COMPLETE.url,
    element: <PrivateRoute component={CheckInComplete} />,
  },
  /* Check-out steps */
  {
    path: allSteps.CHECK_OUT.AUTH.url,
    element: <PrivateRoute component={CheckOutAuth} />,
  },
  {
    path: allSteps.CHECK_OUT.RESERVATIONS.url,
    element: <PrivateRoute component={CheckOutReservations} />,
  },
  {
    path: allSteps.CHECK_OUT.PAYMENT.url,
    element: <PrivateRoute component={CheckOutPayment} />,
  },
  {
    path: allSteps.CHECK_OUT.CHARGES.url,
    element: <PrivateRoute component={CheckOutCharges} />,
  },
  {
    path: allSteps.CHECK_OUT.COMPLETE.url,
    element: <PrivateRoute component={CheckOutComplete} />,
  },
];

import { useMemo } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

import { initialStepsRoutes } from '../routesConfigurations/initialStepsRoutes';
import { FlowType } from '../types';

const CHECK_IN_PATH = '/check-in';
const CHECK_OUT_PATH = '/check-out';
const MAKE_KEYS_PATH = '/make-keys';

export const useCurrentFlowType = (): FlowType | undefined => {
  const location = useLocation();
  const isInitialFlow = matchRoutes(
    Object.values(initialStepsRoutes),
    location
  );
  const isCheckInFlow = matchRoutes(
    [
      {
        path: `${CHECK_IN_PATH}/*`,
        exact: false,
      },
    ],
    location
  );
  const isCheckOutFlow = matchRoutes(
    [
      {
        path: `${CHECK_OUT_PATH}/*`,
        exact: false,
      },
    ],
    location
  );
  const isMakeKeysFlow = matchRoutes(
    [
      {
        path: `${MAKE_KEYS_PATH}/*`,
        exact: false,
      },
    ],
    location
  );

  return useMemo(() => {
    if (isInitialFlow) {
      return FlowType.initial;
    } else if (isCheckInFlow) {
      return FlowType.checkIn;
    } else if (isCheckOutFlow) {
      return FlowType.checkOut;
    } else if (isMakeKeysFlow) {
      return FlowType.makeKeys;
    }
  }, [isInitialFlow, isCheckInFlow, isCheckOutFlow, isMakeKeysFlow]);
};

import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FormErrorMessage } from 'components';
import { compose } from 'redux';

import { Icon, IconTypes } from '@ac/kiosk-components';

import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './FormTextField.style';

interface FormTextFieldProps
  extends WithTranslation,
    WithStyles<typeof styles> {
  input?: object;
  className?: string;
  label?: string;
  placeholder?: string;
  isError?: boolean;
  meta: {
    touched: boolean;
    error: string;
    active: boolean;
  };
  icon: IconTypes;
  onClick?: () => void;
  onIconClick?: () => void;
  classNames: {
    icon?: string;
  };
  readOnly?: boolean;
  autoFocus?: boolean;
  onInputMouseDown?: (event: React.MouseEvent) => void;
  inputRef?: any;
  disableError?: boolean;
}

class FormTextField extends PureComponent<FormTextFieldProps> {
  public static defaultProps = {
    label: '',
    placeholder: '',
    icon: '',
    meta: { touched: false, error: false, active: false },
    classNames: {},
    disableError: false,
  };

  public render() {
    const {
      input,
      label,
      classes,
      placeholder,
      t,
      tReady,
      isError,
      meta: { touched, error, active },
      className,
      icon,
      onClick,
      onIconClick,
      classNames: passedClassNames,
      readOnly,
      autoFocus,
      onInputMouseDown,
      inputRef,
      disableError,
      ...props
    } = this.props;
    const isInputError = touched && !!error && !active;
    const inputClasses = {
      root: classes.inputPropsRoot,
      input: classes.inputPropsInput,
      focused: classes.inputPropsFocused,
      error: classes.inputPropsError,
    };
    const inputLabelClasses = {
      root: classes.inputLabelRoot,
      error: classes.inputLabelError,
      focused: classes.inputLabelFocused,
      shrink: classes.inputLabelShrink,
    };
    active
      ? (document.body.style.height = '101%')
      : (document.body.style.height = '100%');

    return (
      <div className={classNames(className, classes.fieldWrapper)}>
        <TextField
          label={label}
          className={classes.textField}
          error={!disableError && (isError || isInputError)}
          autoFocus={!!autoFocus}
          InputProps={{
            readOnly,
            classes: inputClasses,
            disableUnderline: true,
            endAdornment: !!icon && (
              <InputAdornment position="start" className={classes.adornment}>
                <IconButton
                  onClick={onIconClick}
                  className={classes.iconButton}
                >
                  <Icon
                    type={icon}
                    className={classNames(classes.icon, passedClassNames?.icon)}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
            classes: inputLabelClasses,
          }}
          {...input}
          {...props}
          inputProps={{ onMouseDown: onInputMouseDown }}
          onClick={this.onInputClick}
          inputRef={inputRef}
          placeholder={placeholder || label}
        />
        {!disableError && isInputError && <FormErrorMessage error={error} />}
      </div>
    );
  }

  private onInputClick = () => {
    const { onClick } = this.props;

    return onClick && onClick();
  };
}

const ComposedFormTextField = compose(
  withStyles(styles),
  withTranslation()
)(FormTextField);

export default ComposedFormTextField as any;

import { useEffect } from 'react';
import { Router } from 'utils';
import { Path } from 'utils/Router';

import { LegacyHistory } from '@LEGACY/utils/withRouter';

export function useCorectStep(
  history: LegacyHistory,
  isLoading: boolean,
  hasError: boolean,
  configureSteps: () => void,
  isStepAvailable: () => boolean
) {
  useEffect(() => {
    if (isLoading || hasError) {
      return;
    }

    let nextURL = Router.nextStepURL;
    configureSteps();
    if (!Router.hasAllowedURL(Path.checkIn, nextURL)) {
      nextURL = Router.nextStepURL;
    }

    if (!isStepAvailable()) {
      history.replace(nextURL);
    }
  });
}

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LoadingView } from '@ac/kiosk-components';

import { Authorizer } from '@gss/services';
import {
  prepareApplication,
  setIsKioskProEnv,
} from '@gss/store/configuration/actions';
import { getIsAppInitialized } from '@gss/store/configuration/selectors';
import { handleKioskProDevice } from '@gss/utils';

import { AppRoutes } from './stepsBinds';

export const App = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isAppInitialized = useSelector(getIsAppInitialized);

  const [isAppAuthorized, setIsAppAuthorized] = useState(false);

  useEffect(() => {
    Authorizer.init(() => {
      if (!isAppAuthorized) {
        navigate(window.location.pathname, { replace: true });
        dispatch(prepareApplication.trigger());
        setIsAppAuthorized(true);
        handleKioskProDevice(() => {
          dispatch(setIsKioskProEnv(true));
        });
      }
    });

    Authorizer.authorize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isAppAuthorized || !isAppInitialized ? (
    <LoadingView description={t('SHARED.AUTHORIZING')} />
  ) : (
    <AppRoutes />
  );
};

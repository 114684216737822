import {
  createAction,
  createAsyncAction,
} from '@ac/library-utils/dist/redux-utils';

import { SagaError } from '@gss/types/shared';

import { PrepareSettingsPayload } from './interfaces/prepareSetupPayload';
import { Settings } from './interfaces/settings';

const ACTION_PREFIX = '@settings/';

export const prepareSettings = createAsyncAction<
  PrepareSettingsPayload,
  void,
  SagaError
>(`${ACTION_PREFIX}prepareSettings`);

export const applySettings = createAction<Settings>(
  `${ACTION_PREFIX}applySettings`
);

import {
  createAction,
  createAsyncAction,
} from '@ac/library-utils/dist/redux-utils';

import { MakeKeysReservationDetailsDto } from '@gss/api/KioskApi/entries';
import { SagaError } from '@gss/types/shared';

import { MakeKeyAuthenticationPayload } from './interfaces';

const ACTION_PREFIX = '@flow/makeKeys/';

export const authenticateGuest = createAsyncAction<
  MakeKeyAuthenticationPayload,
  string,
  SagaError
>(`${ACTION_PREFIX}authenticateGuest`);

export const fetchMakeKeysReservationDetails = createAsyncAction<
  void,
  MakeKeysReservationDetailsDto,
  SagaError
>(`${ACTION_PREFIX}fetchMakeKeysReservationDetails`);

export const startMakeKeysProcess = createAction<MakeKeyAuthenticationPayload>(
  `${ACTION_PREFIX}startMakeKeysProcess`
);

export const closeMakeKeyProcess = createAsyncAction<
  () => void,
  void,
  SagaError
>(`${ACTION_PREFIX}closeMakeKeyProcess`);

export const resetAuthProcess = createAction(
  `${ACTION_PREFIX}resetAuthProcess`
);

import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Big from 'big.js';
import { compose } from 'redux';
import {
  getAllFolioCharges,
  getCurrentMinibarCharges,
  getMinibarPendingCharges,
} from 'store/cashiering/selectors';
import {
  getAddonsPriceSum,
  getCurrency,
  getRoomPrice,
  isRatePlanConfidential,
} from 'store/reservation/selectors';
import { getReservationDetails } from 'store/selectors';
import Store from 'types/Store';
import { Configurator, Router } from 'utils';
import { Path } from 'utils/Router';

import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';
import { Grid } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import PriceSection from '../PriceSection';

import styles from './TotalPrice.style';

interface PassedProps {
  isExpandVisible: boolean;
}

interface TotalPriceProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  roomPrice: number;
  addonsPrice: number;
  minibarPrice: number;
  currency: string;
  grossBalanceFromAllFolios: number;
  isRatePlanConfidential: boolean;
  minibarPendingCharges: number;
  reservationDetails: {
    totalPrice: number;
  };
}

class TotalPrice extends PureComponent<TotalPriceProps> {
  public static defaultProps = {
    reservationDetails: {
      totalPrice: 0,
    },
  };

  render() {
    const {
      t,
      classes,
      isExpandVisible,
      roomPrice,
      addonsPrice,
      minibarPrice,
      currency,
      location: { pathname },
      isRatePlanConfidential,
    } = this.props;
    const isChargesStep = pathname.includes('charges');
    const isCheckOut = Router.currentPath === Path.checkOut;

    return (
      <>
        <PriceSection
          text={this.totalPriceTitle}
          price={this.totalPrice}
          isExpandable={isExpandVisible}
          classNames={{
            text: classes.totalPriceText,
            price: classes.totalPriceText,
          }}
        />
        {!isCheckOut && !!addonsPrice && this.areAddonsVisible && (
          <Grid
            className={
              isExpandVisible
                ? classes.priceContainerExpandable
                : classes.priceContainer
            }
          >
            <PriceSection
              text={`${t('ACCOMODATION')}: `}
              price={
                isRatePlanConfidential
                  ? Configurator.pricePlaceholder
                  : `${roomPrice.toFixed(2)} ${currency}`
              }
              isExpandable={isExpandVisible}
            />
            <PriceSection
              text={`${t('STAY_ENHANCEMENTS')}: `}
              price={`${addonsPrice.toFixed(2)} ${currency}`}
              isExpandable={isExpandVisible}
              classNames={{
                wrapper: !isExpandVisible ? classes.priceWrapper : '',
              }}
            />
          </Grid>
        )}
        {isCheckOut && isChargesStep && !!minibarPrice && (
          <PriceSection
            text={`${t('MINIBAR')}:`}
            price={`${minibarPrice.toFixed(2)} ${currency}`}
          />
        )}
      </>
    );
  }

  private get totalPrice() {
    const {
      minibarPrice,
      currency,
      grossBalanceFromAllFolios,
      minibarPendingCharges,
      isRatePlanConfidential,
      reservationDetails: { totalPrice },
    } = this.props;
    const isCheckOut = Router.currentPath === Path.checkOut;
    const totalPriceAmount = isCheckOut
      ? `${new Big(grossBalanceFromAllFolios)
          .plus(minibarPrice)
          .minus(minibarPendingCharges)
          .toFixed(2)} ${currency}`
      : `${totalPrice.toFixed(2)} ${currency}`;

    return isRatePlanConfidential
      ? Configurator.pricePlaceholder
      : totalPriceAmount;
  }

  private get totalPriceTitle() {
    const { t, addonsPrice, isExpandVisible } = this.props;
    const isCheckOut = Router.currentPath === Path.checkOut;
    const totalPriceText = isExpandVisible
      ? t('TOTAL_WITH_TAX')
      : !addonsPrice
      ? t('TOTAL_WITH_TAX')
      : '';
    const title = isCheckOut ? t('BALANCE') : totalPriceText;

    return title ? `${title}:` : '';
  }

  private get areAddonsVisible() {
    return Configurator.getSwitch(Configurator.switchCodes.SHOW_ADDONS, false);
  }
}

const mapStateToProps = (state: Store) => ({
  currency: getCurrency(state),
  roomPrice: getRoomPrice(state),
  addonsPrice: getAddonsPriceSum(state),
  minibarPrice: getCurrentMinibarCharges(state),
  grossBalanceFromAllFolios: getAllFolioCharges(state),
  isRatePlanConfidential: isRatePlanConfidential(state),
  minibarPendingCharges: getMinibarPendingCharges(state),
  reservationDetails: getReservationDetails(state),
});

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps),
  withRouter
)(TotalPrice) as (props: PassedProps) => JSX.Element;

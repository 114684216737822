import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  ConfirmationFormProperties,
  ConfirmationFormValues,
} from 'views/CheckInConfirmation/types';

import { Box, Checkbox, Link, Text } from '@ac/kiosk-components';
import { formNestedSingleCheckboxFieldFactory } from '@ac/react-infrastructure';

import { mapCheckboxFieldRenderProps } from '@gss/utils/form';

import './ConsentCheckbox.scss';

const CheckboxField =
  formNestedSingleCheckboxFieldFactory<
    Pick<ConfirmationFormValues, ConfirmationFormProperties.termsAndCondition>
  >();

export interface ConsentData {
  id: string;
  description: string;
  disabled: boolean;
  isConsentCBP: boolean;
}

interface ConsentCheckboxProps {
  dataTestSelector?: string;
  consent: ConsentData;
  className?: string;
  onConsentLinkClick?: (consentId: string) => void;
}

const ConsentCheckbox = ({
  dataTestSelector,
  consent,
  className,
  onConsentLinkClick,
}: ConsentCheckboxProps) => {
  const { t } = useTranslation();
  const handleConsentLinkClick = useCallback(() => {
    if (!consent.id) return;

    onConsentLinkClick?.(consent.id);
  }, [onConsentLinkClick, consent.id]);

  const consentLink = useMemo(() => {
    const isLinkStandalone = consent.isConsentCBP && consent.disabled;

    return (
      <Box className="consent-checkbox-label-wrapper">
        {!isLinkStandalone && (
          <Text className="display-inline">{t('I_ACCEPT')}</Text>
        )}

        <Link
          className={classNames(
            'consent-checkbox-label-link',
            'display-inline',
            {
              'spacing-left-s spacing-right-s': !isLinkStandalone,
            }
          )}
          onClick={handleConsentLinkClick}
        >
          {consent.description}
        </Link>

        {!isLinkStandalone && (
          <Text className="display-inline">{`(${t('OPTIONAL')})`}</Text>
        )}
      </Box>
    );
  }, [consent, handleConsentLinkClick]);

  return consent.id ? (
    <div className={className}>
      <CheckboxField
        valuePath={[ConfirmationFormProperties.termsAndCondition, consent.id]}
      >
        {(checkboxFieldRenderProps) => (
          <Checkbox
            {...mapCheckboxFieldRenderProps(checkboxFieldRenderProps)}
            data-test-selector={dataTestSelector}
            disabled={consent.disabled}
            label={consentLink}
          />
        )}
      </CheckboxField>
      {consent.isConsentCBP && consent.disabled && (
        <Text hint className="consent-disabled-cbp-consent">
          {t('CROSS_BORDER_DESCRIPTION')}
        </Text>
      )}
    </div>
  ) : null;
};

export default ConsentCheckbox;

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route } from 'react-router-dom';
import Store from 'types/Store';
import { Configurator } from 'utils';
import Router, { paths } from 'utils/Router';

import {
  getIsAppInitialized,
  getPaymentDevice,
  getScanningDevice,
  getUsedPropertyId,
  getWorkstationId,
} from '@gss/store/configuration/selectors';
import { getAreSettingsInitialized } from '@gss/store/settings/selectors';

class PrivateRoute extends PureComponent<any> {
  public render() {
    const { component: Component, ...props } = this.props;

    if (this.shouldRedirectToLogin)
      return <Navigate to={paths.LOGIN} replace />;

    return !props.isAppInitialized && Router.isCurrentStepPrivate ? (
      <Navigate to={paths.WELCOME} replace />
    ) : (
      <Component {...props} />
    );
  }

  private get shouldRedirectToLogin() {
    const {
      propertyId,
      isSetupInitialized,
      workstationId,
      paymentDevice,
      scanningDevice,
    } = this.props;

    const { KIOSKS_PASSPORT_SCANNING } = Configurator.switchCodes;
    const isScanningDeviceEnabled = Configurator.getSwitch(
      KIOSKS_PASSPORT_SCANNING
    );

    return (
      !propertyId ||
      !isSetupInitialized ||
      !workstationId ||
      !paymentDevice ||
      (!scanningDevice && isScanningDeviceEnabled)
    );
  }
}

const mapStateToProps = (state: Store) => ({
  isAppInitialized: getIsAppInitialized(state),
  isSetupInitialized: getAreSettingsInitialized(state),
  propertyId: getUsedPropertyId(state),
  workstationId: getWorkstationId(state),
  paymentDevice: getPaymentDevice(state),
  scanningDevice: getScanningDevice(state),
});

export default connect(mapStateToProps)(PrivateRoute) as (
  props: any
) => JSX.Element;

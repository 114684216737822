import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Body, Footer, ImageHeader, View } from 'components';
import { compose } from 'redux';
import { getEmailsSent } from 'store/cashiering/selectors';
import Store from 'types/Store';
import { Configurator } from 'utils';
import { paths } from 'utils/Router';

import { Button, ButtonPattern } from '@ac/kiosk-components';

import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';
import { Box, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './CheckOutComplete.style';

const INITIAL_TIMEOUT = 20;
const { GOODBYE_MESSAGE } = Configurator.getTranslationCodes();

interface CheckOutCompleteState {
  secondsRemaining: number;
}

interface PassedProps {}

interface CheckOutCompleteProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  emails: string[];
}

class CheckOutComplete extends PureComponent<
  CheckOutCompleteProps,
  CheckOutCompleteState
> {
  public state = {
    secondsRemaining: INITIAL_TIMEOUT,
  };

  private timer: number | undefined;

  public componentDidMount() {
    this.startTimer();
  }

  public componentWillUnmount() {
    clearInterval(this.timer);
  }

  public render() {
    const { classes, t, emails } = this.props;
    const { secondsRemaining } = this.state;
    const timeRemaining = t('SECONDS', { count: secondsRemaining });

    return (
      <View hideCounter>
        <ImageHeader
          logo={Configurator.logo}
          title={Configurator.getTranslation(GOODBYE_MESSAGE)}
        />
        <Body className={classes.body}>
          <div>
            <Typography className={classes.primaryText}>
              {t('DOCUMENT_SENT_TO')}
            </Typography>
            <Typography className={classes.secondaryText}>
              {emails.length
                ? emails.map((email, index) => <Box key={index}>{email}</Box>)
                : t('NOT_PROVIDED')}
            </Typography>
          </div>
        </Body>
        <Footer className={classes.footer}>
          <div className={classes.footerContent}>
            <Typography className={classes.footerText}>
              {`${t('SCREEN_WILL_CLOSE')} ${timeRemaining}`}
            </Typography>
            <Button pattern={ButtonPattern.secondary} onClick={this.onClose}>
              {t('CLOSE')}
            </Button>
          </div>
        </Footer>
      </View>
    );
  }

  private startTimer = () => {
    this.timer = window.setInterval(this.decrementTime, 1000);
  };

  private decrementTime = () => {
    const { secondsRemaining } = this.state;
    if (secondsRemaining > 0) {
      this.setState({
        secondsRemaining: secondsRemaining - 1,
      });
    } else {
      this.handleTimerEnd();
    }
  };

  private handleTimerEnd = () => {
    clearInterval(this.timer);
    this.changeRoute(paths.WELCOME);
  };

  private changeRoute = (route: string) => {
    const { history } = this.props;
    history.replace(route);
  };

  private onClose = () => {
    this.changeRoute(paths.WELCOME);
  };
}

const mapStateToProps = (state: Store) => ({
  emails: getEmailsSent(state),
});

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps)
)(CheckOutComplete) as (props: PassedProps) => JSX.Element;

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { getAreSettingsInitialized } from '@gss/store/settings/selectors';
import { paths } from '@LEGACY/utils/Router';

export const requireSettingsInitialized = <
  Props extends Record<string, unknown>
>(
  WrappedComponent: React.ComponentType<Props>
): typeof WrappedComponent => {
  const SettingsInitializationValidator = (props: Props): JSX.Element => {
    const areSettingsInitialized = useSelector(getAreSettingsInitialized);

    if (!areSettingsInitialized) {
      return <Navigate to={paths.SETUP} replace />;
    }

    return <WrappedComponent {...props} />;
  };

  return SettingsInitializationValidator;
};

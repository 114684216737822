import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { compose } from 'redux';
import { resetAppProgress } from 'store/actions';
import { checkOut, fetchFolios, fullCheckOut } from 'store/cashiering/actions';
import {
  getBalancedFoliosToSettle,
  getFolios,
  getNotBalancedFolios,
} from 'store/cashiering/selectors';
import { checkIn, fullCheckIn } from 'store/reservation/actions';
import { isCheckedIn } from 'store/reservation/selectors';
import { getErrors, isLoading } from 'store/selectors';
import { ChargesFolio, Folio } from 'types/Api/Cashiering';
import { ApiError } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator } from 'utils';
import Router, { oneWaySteps, Path } from 'utils/Router';

import {
  Button,
  ButtonPattern,
  ConfirmModal,
  Footer as BasicFooter,
} from '@ac/kiosk-components';

import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './Footer.style';

interface PassedProps {
  className?: string;
  hasCancelButton?: boolean;
  hasContinueButton?: boolean;
  hasBackButton?: boolean;
  onContinue?: () => any;
  onGoBack?: () => any;
  onCancel?: () => void;
  isOnContinuePrior?: boolean;
  isOnContinueDisabled?: boolean;
  routeName?: string;
  isContinueDisabled?: boolean;
  continueButtonLabel?: string;
  backButtonLabel?: string;
  children?: JSX.Element | JSX.Element[];
  isBackDisabled?: boolean;
  isCancelDisabled?: boolean;
  isOnContinueFirst?: boolean;
  isContinueLoading?: boolean;
  isScrollEnabled?: boolean;
  goNext?: boolean;
}

interface FooterProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  balancedFoliosToSettle: ChargesFolio[];
  isLoading: boolean;
  checkIn: typeof checkIn;
  errors: ApiError[];
  folios: Folio[];
  notBalancedFolios: Folio[];
  checkOut: typeof checkOut;
  fetchFolios: typeof fetchFolios;
  fullCheckOut: typeof fullCheckOut;
  resetAppProgress: typeof resetAppProgress;
  isCheckedIn: boolean;
  fullCheckIn: typeof fullCheckIn;
}

interface FooterState {
  isCancelModalOpen: boolean;
}

class Footer extends PureComponent<FooterProps, FooterState> {
  public static defaultProps = {
    isCancelDisabled: false,
    isBackDisabled: false,
    hasCancelButton: false,
    hasContinueButton: false,
    isLoading: false,
    hasBackButton: false,
    routeName: '',
    continueButtonLabel: '',
    backButtonLabel: '',
    isOnContinuePrior: false,
    isOnContinueDisabled: false,
    isOnContinueFirst: false,
    goNext: false,
  };

  public state = {
    isCancelModalOpen: false,
  };

  public render() {
    const {
      t,
      classes,
      children,
      className,
      hasCancelButton,
      hasContinueButton,
      hasBackButton,
      continueButtonLabel,
      backButtonLabel,
      isContinueDisabled,
      isContinueLoading,
      routeName,
      isLoading,
      isBackDisabled,
      isCancelDisabled,
    } = this.props;

    const { isCancelModalOpen } = this.state;
    const SEPARATE_FOOTER_BG = Configurator.switchCodes.SEPARATE_FOOTER_BG;

    return (
      <>
        {isCancelModalOpen && (
          <ConfirmModal
            dataTestSelector="footer-cancel-process-confirm-modal"
            header={t('CANCEL_TITLE')}
            className="with-default-kiosk-components-theme"
            description={t('CANCEL_DESCRIPTION', { routeName })}
            primaryButtonLabel={t('CANCEL_BUTTON_CONTINUE', { routeName })}
            secondaryButtonLabel={t('CANCEL_BUTTON_CANCEL', { routeName })}
            onPrimaryClick={this.onToggleCancelButton}
            onSecondaryClick={this.handleCancelProcess}
          />
        )}
        <BasicFooter
          onPrimaryClick={this.onContinue}
          onSecondaryClick={this.onGoBack}
          hasPrimaryButton={hasContinueButton}
          hasSecondaryButton={hasBackButton}
          isPrimaryDisabled={
            isContinueLoading || isLoading || isContinueDisabled
          }
          isSecondaryDisabled={isBackDisabled || isLoading || isContinueLoading}
          primaryLabel={continueButtonLabel}
          secondaryLabel={backButtonLabel}
          dataTestSelector="footer-component"
          className={classNames(
            {
              [classes.transparentFooter]:
                !Configurator.getSwitch(SEPARATE_FOOTER_BG),
            },
            className
          )}
        >
          <div className={classes.footerChildrenWrapper}>
            {hasCancelButton && (
              <Button
                pattern={ButtonPattern.secondary}
                onClick={this.onToggleCancelButton}
                disabled={isCancelDisabled || isLoading || isContinueLoading}
                dataTestSelector="footer-cancel-button"
                className="spacing-right-sm"
              >
                {t('CANCEL')}
              </Button>
            )}
            {children}
          </div>
        </BasicFooter>
      </>
    );
  }

  private onToggleCancelButton = () => {
    const { isCancelModalOpen } = this.state;
    this.setState({ isCancelModalOpen: !isCancelModalOpen });
  };

  private handleCancelProcess = () => {
    const { history, resetAppProgress } = this.props;
    const { onCancel } = this.props;
    onCancel ? onCancel() : resetAppProgress(history);
  };

  private changeRoute = (route: string) => {
    const { history } = this.props;

    return oneWaySteps.includes(route)
      ? history.replace(route)
      : history.push(route);
  };

  private goNext = () => this.changeRoute(Router.nextStepURL);

  private goBack = () => this.changeRoute(Router.prevStepURL);

  private onGoBack = () => {
    const { onGoBack } = this.props;

    return onGoBack ? onGoBack() : this.goBack();
  };

  private onContinue = async () => {
    const {
      onContinue,
      fetchFolios,
      isScrollEnabled,
      isOnContinuePrior,
      isOnContinueDisabled,
      isOnContinueFirst,
      fullCheckOut,
      goNext,
      fullCheckIn,
      isCheckedIn,
    } = this.props;
    if (isOnContinueDisabled) return;
    if (onContinue && isOnContinueFirst) await onContinue();
    if (onContinue && isOnContinuePrior) return onContinue();
    const isCheckInPath = Router.currentPath === Path.checkIn;
    const isCheckOutPath = Router.currentPath === Path.checkOut;
    const isCheckInFinish = isCheckInPath && Router.isCurrentStepLast;
    const isCheckOutFinish = isCheckOutPath && this.shouldFinishPath();
    if (isCheckInFinish && !isCheckedIn && !isScrollEnabled) {
      await fullCheckIn(Configurator.postCheckInLetter);
    }
    if (isCheckOutFinish && !isScrollEnabled) {
      await fetchFolios();
      await fullCheckOut();
    }
    const { errors } = this.props;
    if (errors.length > 0) return;
    if (onContinue && isOnContinueFirst && goNext) return this.goNext();

    return onContinue ? onContinue() : this.goNext();
  };

  private shouldFinishPath = () => {
    const { notBalancedFolios: folios, balancedFoliosToSettle } = this.props;
    const isChargesNext = Router.nextStepURL.includes('charges');
    const isLast = Router.currentStepIndex === Router.allowedSteps.length - 3;
    if (balancedFoliosToSettle.length) return false;

    return (
      (!folios.length && isChargesNext && isLast) || Router.isCurrentStepLast
    );
  };
}

const mapStateToProps = (state: Store) => ({
  folios: getFolios(state),
  notBalancedFolios: getNotBalancedFolios(state),
  errors: getErrors(state),
  isLoading: isLoading(state),
  balancedFoliosToSettle: getBalancedFoliosToSettle(state),
  isCheckedIn: isCheckedIn(state),
});

const mapDispatchToProps = {
  checkIn,
  fullCheckIn,
  fetchFolios,
  fullCheckOut,
  checkOut,
  resetAppProgress,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Footer) as (props: PassedProps) => JSX.Element;

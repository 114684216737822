import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Store from 'types/Store';

import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';
import { Grid } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import CaptureButton from '../CaptureButton';
import DocumentScan from '../DocumentScan';
import IDCapture from '../IDCapture';
import { getIdPhotos } from '../store/selectors';

import styles from './DocumentScanner.style';

export interface PassedProps {
  toggleModal: () => void;
  isCaptureModalOpen: boolean;
  shouldCrop: boolean;
}

interface DocumentScannerProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  photos: string[];
}

class DocumentScanner extends PureComponent<DocumentScannerProps> {
  public static defaultProps = {
    isCaptureModalOpen: false,
    shouldCrop: false,
  };

  public render() {
    const { photos, toggleModal, isCaptureModalOpen, shouldCrop } = this.props;

    return (
      <Grid container>
        <IDCapture
          shouldCrop={shouldCrop}
          isOpen={isCaptureModalOpen}
          toggleModal={toggleModal}
        />
        {photos.map((photo, index) => (
          <DocumentScan key={`documentGalleryItem-${index}`} image={photo} />
        ))}
        {photos.length < 2 && <CaptureButton onClick={toggleModal} />}
      </Grid>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  photos: getIdPhotos(state),
});

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps)
)(DocumentScanner) as (props: PassedProps) => JSX.Element;

import {
  createAction,
  createAsyncAction,
} from '@ac/library-utils/dist/redux-utils';

import { SagaError } from '@gss/types/shared';

const ACTION_PREFIX = '@mainProcess/';

export const setIsOfflineStatus = createAction<boolean>(
  `${ACTION_PREFIX}setIsOfflineStatus`
);

export const setIsAppInactive = createAction<boolean>(
  `${ACTION_PREFIX}setIsAppInactive`
);

export const initializeMainProcess = createAsyncAction<void, void, SagaError>(
  `${ACTION_PREFIX}initializeMainProcess`
);

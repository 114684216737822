import Configurator from './Configurator';

const DEFAULT_IS_ALLOWED = true;
const {
  SHOW_PREPAYMENT,
  SHOW_PRE_AUTHORIZATION,
  SHOW_ADDONS,
  SHOW_CONTACT,
  SHOW_DOCUMENT,
  SHOW_ADDRESS,
  SHOW_GENERATE_KEY,
  SHOW_NUMBER_OF_KEYS,
  SHOW_COMPANY_INVOICE,
  SHOW_CHECK_IN_SUMMARY,
  SHOW_ALLOCATION_STEP,
  COMPANY_INVOICE_ON_CHECK_OUT,
  SHOW_PURPOSE_OF_STAY,
  SHOW_TERMS_AND_CONDITIONS,
  KIOSKS_PASSPORT_SCANNING,
  SHOW_TAX_ID,
} = Configurator.switchCodes;
export enum Path {
  welcome = 'WELCOME',
  login = 'LOGIN',
  logout = 'LOGOUT',
  setup = 'SETUP',
  checkIn = 'CHECK_IN',
  checkOut = 'CHECK_OUT',
  generateKeys = 'GENERATE_KEYS',
  deviceSetup = 'DEVICE_SETUP',
  missingDevice = 'MISSING_DEVICE',
}

class Step {
  public url: string;
  public isPrivate: boolean;
  public showInCounter: boolean;
  public hidden: boolean;
  private switchCode?: string | string[];

  constructor({
    path,
    switchCode,
    isPrivate = false,
    hidden = false,
    showInCounter = true,
  }: {
    path: string;
    switchCode?: string | string[];
    isPrivate?: boolean;
    hidden?: boolean;
    showInCounter?: boolean;
  }) {
    this.url = path;
    this.switchCode = switchCode;
    this.isPrivate = isPrivate;
    this.hidden = hidden;
    this.showInCounter = showInCounter;
  }

  public get isAllowed() {
    if (this.hidden) return !this.hidden;
    if (!this.switchCode) return DEFAULT_IS_ALLOWED;
    if (typeof this.switchCode === 'string') {
      return Configurator.getSwitch(this.switchCode, DEFAULT_IS_ALLOWED);
    }

    return this.switchCode.find((code) =>
      Configurator.getSwitch(code, DEFAULT_IS_ALLOWED)
    );
  }
}

const createPrivateStep = ({
  path,
  switchCode,
  hidden = false,
  showInCounter = true,
}: {
  path: string;
  hidden?: boolean;
  switchCode?: string | string[];
  showInCounter?: boolean;
}) => new Step({ path, switchCode, isPrivate: true, showInCounter, hidden });

export const LOGIN_REDIRECT_URL = `${window.location.origin}/login`;

export const paths = {
  [Path.checkIn]: '/check-in-legacy',
  [Path.checkOut]: '/check-out',
  [Path.generateKeys]: '/generate-keys',
  [Path.login]: '/login',
  [Path.logout]: '/logout',
  [Path.setup]: '/setup',
  [Path.deviceSetup]: '/device-setup',
  [Path.missingDevice]: '/missing-devices',
  [Path.welcome]: '/',
};

export const allSteps = {
  [Path.checkIn]: {
    AUTH: createPrivateStep({ path: `${paths.CHECK_IN}/auth` }),
    MULTIROOM: createPrivateStep({
      path: `${paths.CHECK_IN}/multi-room`,
      showInCounter: false,
    }),
    NAME_DETAILS: createPrivateStep({ path: `${paths.CHECK_IN}/name-details` }),
    ID_SCANNING: createPrivateStep({
      path: `${paths.CHECK_IN}/id-scanning`,
      switchCode: [KIOSKS_PASSPORT_SCANNING],
    }),
    ID: createPrivateStep({
      path: `${paths.CHECK_IN}/id`,
      switchCode: SHOW_DOCUMENT,
    }),
    COMMUNICATION_DETAILS: createPrivateStep({
      path: `${paths.CHECK_IN}/contact`,
      switchCode: [SHOW_CONTACT, SHOW_ADDRESS, SHOW_TAX_ID],
    }),
    ADDONS: createPrivateStep({
      path: `${paths.CHECK_IN}/addons`,
      switchCode: SHOW_ADDONS,
    }),
    ROOM: createPrivateStep({
      path: `${paths.CHECK_IN}/room`,
      switchCode: SHOW_ALLOCATION_STEP,
    }),
    CONFIRMATION: createPrivateStep({
      path: `${paths.CHECK_IN}/confirmation`,
      switchCode: [SHOW_PURPOSE_OF_STAY, SHOW_TERMS_AND_CONDITIONS],
    }),
    SUMMARY: createPrivateStep({
      path: `${paths.CHECK_IN}/summary`,
      switchCode: SHOW_CHECK_IN_SUMMARY,
    }),
    PAYMENT: createPrivateStep({
      path: `${paths.CHECK_IN}/payment`,
      switchCode: SHOW_COMPANY_INVOICE,
    }),
    PREPAYMENT: createPrivateStep({
      path: `${paths.CHECK_IN}/prepayment`,
      switchCode: SHOW_PREPAYMENT,
    }),
    PRE_AUTHORIZATION: createPrivateStep({
      path: `${paths.CHECK_IN}/pre-authorization`,
      switchCode: SHOW_PRE_AUTHORIZATION,
    }),
    NUMBER_OF_KEYS: createPrivateStep({
      path: `${paths.CHECK_IN}/number-of-keys`,
      switchCode: SHOW_NUMBER_OF_KEYS,
    }),
    COMPLETE: createPrivateStep({ path: `${paths.CHECK_IN}/complete` }),
  },
  [Path.checkOut]: {
    AUTH: createPrivateStep({ path: `${paths.CHECK_OUT}/auth` }),
    RESERVATIONS: createPrivateStep({
      path: `${paths.CHECK_OUT}/reservations`,
    }),
    PAYMENT: createPrivateStep({
      path: `${paths.CHECK_OUT}/payment`,
      switchCode: COMPANY_INVOICE_ON_CHECK_OUT,
    }),
    CHARGES: createPrivateStep({ path: `${paths.CHECK_OUT}/charges` }),
    COMPLETE: createPrivateStep({ path: `${paths.CHECK_OUT}/complete` }),
  },
  [Path.generateKeys]: {
    AUTH: createPrivateStep({ path: `${paths.GENERATE_KEYS}/auth` }),
    CUT: createPrivateStep({
      path: `${paths.GENERATE_KEYS}/cut`,
      switchCode: SHOW_GENERATE_KEY,
    }),
  },
  [Path.welcome]: {},
  [Path.login]: {},
  [Path.logout]: {},
  [Path.setup]: {},
  [Path.deviceSetup]: {},
  [Path.missingDevice]: {},
};

export const oneWaySteps = [allSteps.CHECK_OUT.RESERVATIONS.url];

class Router {
  public static paths = paths;
  public static steps = allSteps;

  public static get nextStepURL(): string {
    const currentPath = this.getCurrentPath();
    const allowedSteps = this.getAllowedSteps(currentPath);
    const currentStepIndex = this.getCurrentStepIndex(allowedSteps);
    const nextStep = allowedSteps[currentStepIndex + 1];

    return nextStep ? nextStep.url : paths.WELCOME;
  }

  public static get prevStepURL(): string {
    const currentPath = this.getCurrentPath();
    const allowedSteps = this.getAllowedSteps(currentPath);
    const currentStepIndex = this.getCurrentStepIndex(allowedSteps);
    const prevStep = allowedSteps[currentStepIndex - 1];

    return prevStep ? prevStep.url : paths.WELCOME;
  }

  public static removeStep<
    PathKey extends Path,
    StepKey extends keyof (typeof allSteps)[PathKey]
  >(path: PathKey, step: StepKey) {
    const steps = Router.steps[path];
    const newSteps = { ...steps };
    delete newSteps[step];

    Router.steps = {
      ...Router.steps,
      [path]: newSteps,
    };
  }

  public static changeStepVisibility<
    PathKey extends Path,
    StepKey extends keyof (typeof allSteps)[PathKey]
  >(path: PathKey, step: StepKey, hidden: boolean) {
    const steps = Router.steps[path];
    const parsedStepEntries = Object.entries(steps).map((item) => {
      if (item[0] === step) item[1].hidden = hidden;

      return item;
    });

    Router.steps = {
      ...Router.steps,
      [path]: Object.fromEntries(parsedStepEntries),
    };
  }

  public static hasAllowedURL<PathKey extends Path>(
    path: PathKey,
    url: string
  ): boolean {
    const allowedSteps = this.getAllowedSteps(path);

    return allowedSteps.some((step) => step.url === url);
  }

  public static initSteps() {
    Router.steps = allSteps;
  }

  public static get isCurrentStepPrivate() {
    const currentPath = this.getCurrentPath();
    const allowedSteps = this.getAllowedSteps(currentPath);
    const currentStepIndex = this.getCurrentStepIndex(allowedSteps);

    return allowedSteps[currentStepIndex]
      ? allowedSteps[currentStepIndex].isPrivate
      : false;
  }

  public static get allowedSteps() {
    const currentPath = this.getCurrentPath();

    return this.getAllowedSteps(currentPath);
  }

  public static get isCurrentStepLast() {
    const currentPath = this.getCurrentPath();
    const allowedSteps = this.getAllowedSteps(currentPath);
    const currentStepIndex = this.getCurrentStepIndex(allowedSteps);
    const lastStepIndex = allowedSteps.length - 2;

    return currentStepIndex === lastStepIndex;
  }

  public static get currentPath() {
    return this.getCurrentPath();
  }

  public static get currentStepIndex() {
    const currentPath = this.getCurrentPath();
    const allowedSteps = this.getAllowedSteps(currentPath);

    return this.getCurrentStepIndex(allowedSteps);
  }

  private static getAllowedSteps = (path: Path): Step[] =>
    Object.values(Router.steps[path])
      .map((route) => route.isAllowed && route)
      .filter((route) => Boolean(route));

  private static getCurrentPath = () =>
    (Object.keys(paths).find((key) =>
      window.location.pathname.startsWith(paths[key as Path])
    ) || 'BASE') as Path;

  private static getCurrentStepIndex = (availableSteps: Step[]) =>
    availableSteps.findIndex((route) =>
      window.location.pathname.startsWith(route.url)
    );

  public static get counterSteps() {
    const currentPath = this.getCurrentPath();
    const steps = this.getAllowedSteps(currentPath);

    return steps.filter((item) => item.showInCounter);
  }

  public static get currentCounterStepIndex() {
    const steps = this.counterSteps;

    return this.getCurrentStepIndex(steps);
  }
}

export default Router;

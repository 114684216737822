import {
  createAction,
  createAsyncAction,
} from '@ac/library-utils/dist/redux-utils';

import { SagaError } from '@gss/types/shared';

const ACTION_PREFIX = '@local/keyGeneratorStep/';

export const generateKey = createAsyncAction<void, void, SagaError>(
  `${ACTION_PREFIX}generateKey`
);

export const resetGenerateKeyStatus = createAction(
  `${ACTION_PREFIX}resetGenerateKeyStatus`
);

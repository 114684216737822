import i18n from 'i18next';
import {
  fetchAvailablePurchaseItems,
  fetchInventoryItemsAvailability,
} from 'store/availability/actions';
import {
  fetchBillingInstructions,
  fetchCashieringAccount,
  fetchFolios,
  fetchKioskAuthorizationAmount,
} from 'store/cashiering/actions';
import { fetchActiveAuthorizations } from 'store/cashiering/authorization/actions';
import { fetchReservationOutstandingDeposit } from 'store/cashiering/reservationOutstandingDeposit/actions';
import {
  resetCheckInState,
  startCheckInProcess,
} from 'store/checkInProcess/actions';
import { getCheckInProcessSessionId } from 'store/checkInProcess/selectors';
import {
  fetchCompany,
  fetchProfileInternalMemberships,
} from 'store/profile/actions';
import { getAddresses } from 'store/profile/selectors';
import { fetchRatePlans } from 'store/rateManager/actions';
import {
  fetchBreakdown,
  fetchReservationDetails,
  fetchReservationExtended,
  fetchReservationPurchases,
} from 'store/reservation/actions';
import {
  getProfileId,
  getReservation,
  isReservationDayUse,
} from 'store/reservation/selectors';
import { fetchRoomType } from 'store/room/actions';
import { getCompanyProfileIds } from 'store/selectors';
import { refreshUi } from 'store/ui/actions';
import { Dispatch, GetState } from 'store/utils/actions';
import { Configurator, DateManager } from 'utils';
import { paths } from 'utils/Router';
import { revokeImageUrls } from 'views/CheckInRoom/store/actions';

import { isDefined } from '@ac/library-utils/dist/utils';

import {
  fetchDistricts,
  fetchStates,
  resetLazyLoadedDictionaryStore,
} from '@gss/store/lazyLoadedDictionary/actions';
import { LegacyHistory } from '@LEGACY/utils/withRouter';

import { resetFilesState } from './files/actions';
import { CLEAR_STATE } from './types';

export const fetchCheckInData =
  () => async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const reservation = getReservation(state);
    const addresses = getAddresses(state);
    const profileId = getProfileId(state);
    const checkInProcessSessionId = getCheckInProcessSessionId(state);
    const isDistrictEnabled = Boolean(
      Configurator.getSwitch(Configurator.switchCodes.DISTRICT)
    );
    const {
      id,
      accountId,
      roomTypeId,
      departureDate,
      arrivalDate: arrival,
    } = reservation;

    if (!checkInProcessSessionId) {
      await dispatch(startCheckInProcess(profileId));
    }

    const departure = isReservationDayUse(state)
      ? DateManager.addDays(departureDate, 1)
      : departureDate;

    await dispatch(fetchCashieringAccount(accountId));
    const linkedCompanyIds = getCompanyProfileIds(getState());

    const uniqueCountryCodes = [
      ...new Set(addresses.map(({ countryCode }) => countryCode)),
    ].filter(isDefined);

    for (const countryCode of uniqueCountryCodes) {
      if (!countryCode) continue;

      dispatch(
        fetchStates.trigger({ countryCode, languageCode: i18n.language })
      );

      if (isDistrictEnabled) {
        dispatch(
          fetchDistricts.trigger({
            countryCode,
            languageCode: i18n.language,
          })
        );
      }
    }

    await Promise.all(
      [
        ...linkedCompanyIds.map((id: string) => fetchCompany(id)),
        fetchFolios(),
        fetchProfileInternalMemberships(),
        fetchBreakdown(id),
        fetchBillingInstructions(accountId),
        fetchRoomType(roomTypeId),
        fetchReservationPurchases(id),
        fetchReservationDetails(id),
        fetchKioskAuthorizationAmount(),
        fetchReservationOutstandingDeposit(),
        fetchRatePlans(),
        fetchReservationExtended(id),
        fetchActiveAuthorizations(),
        refreshUi(),
      ].map((item) => dispatch(item))
    );

    await dispatch(fetchAvailablePurchaseItems(arrival, departure));
    await dispatch(fetchInventoryItemsAvailability());
  };

export const clearState = () => (dispatch: Dispatch) => {
  dispatch(resetLazyLoadedDictionaryStore());
  dispatch({
    type: CLEAR_STATE,
  });
};

export const resetState = () => (dispatch: Dispatch) => {
  dispatch(resetFilesState());
  dispatch(resetCheckInState());
};

export const resetAppProgress =
  (history: LegacyHistory) => (dispatch: Dispatch) => {
    dispatch(resetState());
    dispatch(clearState());
    dispatch(revokeImageUrls());
    history.push(paths.WELCOME);
  };

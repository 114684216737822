import { PureComponent, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IdleTimerProvider } from 'react-idle-timer';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Counter } from 'components';
import Modal, { ModalType } from 'components/Modal/Modal';
import { compose } from 'redux';
import { clearCashieringErrors } from 'store/cashiering/actions';
import { clearHousekeepingErrors } from 'store/housekeeping/actions';
import { clearProfileErrors } from 'store/profile/actions';
import { clearReservationErrors } from 'store/reservation/actions';
import { clearRoomErrors } from 'store/room/actions';
import { isLoading } from 'store/selectors';
import { toggleDayUseModal } from 'store/ui/actions';
import { isDayUseModalOpen } from 'store/ui/selectors';
import { ApiError } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator } from 'utils';
import { paths } from 'utils/Router';

import {
  InactivityModal,
  TestEnvironmentBar,
  View as BaseView,
} from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';

import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './View.style';

type IdleType = 'modal' | 'none';

interface ModalValue {
  code: string;
  message: string;
}

interface PassedProps {
  hideCounter?: boolean;
  tabIndex?: number;
  idle?: {
    type: IdleType;
    timeout?: number;
    onIdle?: () => void;
    onActive?: () => void;
  };
  modal?: {
    values?: Array<Error | ApiError | ModalValue>;
    type?: ModalType;
    showDetails?: boolean;
    onClick?: () => void;
    isOpen?: boolean;
    customErrorCode?: string;
    shouldClearErrors?: boolean;
    shouldRedirect?: boolean;
    defaultError?: string;
    isLoading?: boolean;
    disableLoader?: boolean;
  };
  customDescription?:
    | Array<ApiError | JSX.Element>
    | string
    | HTMLParagraphElement;
  className?: string;
  modalClassName?: string;
  shouldClearErrors?: boolean;
  values?: any[];
  children?: ReactNode;
}

interface ViewProps
  extends PassedProps,
    WithTranslation,
    RouteComponentProps,
    WithStyles<typeof styles> {
  clearProfileErrors: typeof clearProfileErrors;
  clearReservationErrors: typeof clearReservationErrors;
  clearRoomErrors: typeof clearRoomErrors;
  clearHousekeepingErrors: typeof clearHousekeepingErrors;
  clearCashieringErrors: typeof clearCashieringErrors;
  isFetching: boolean;
  isDayUseModalOpen: boolean;
  toggleDayUseModal: typeof toggleDayUseModal;
}

interface ViewState {
  isIdle: boolean;
}

class View extends PureComponent<ViewProps, ViewState> {
  public static defaultProps = {
    className: '',
    children: null,
  };

  public state = { isIdle: false };
  public render() {
    const { classes, children, className, hideCounter, tabIndex } = this.props;

    return (
      <BaseView
        className={classNames(classes.view, className)}
        tabIndex={tabIndex}
      >
        {Configurator.propertySettings?.nonProduction && <TestEnvironmentBar />}
        {this.renderIdleTimer()}
        {this.renderModal()}
        {children}
        {!hideCounter && <Counter />}
      </BaseView>
    );
  }

  private renderIdleTimer = () => {
    const { idle, classes } = this.props;
    if (!idle) return null;

    const { isIdle } = this.state;
    const { type, timeout } = idle;
    const showModal = isIdle && type === 'modal';

    return (
      <IdleTimerProvider
        onIdle={this.onIdle}
        timeout={timeout || Configurator.idleTimeout}
      >
        {showModal && (
          <InactivityModal
            className={`with-default-kiosk-components-theme ${classes.inactivityModal}`}
            countdownFrom={Configurator.inavtivityModalCountdown / 1000}
            onConfirmButtonClick={this.onActive}
            onCountdownEnd={this.onInactivityCountdownEnd}
          />
        )}
      </IdleTimerProvider>
    );
  };

  private onActive = () => {
    this.setState({ isIdle: false });

    const { idle } = this.props;
    if (idle?.onActive) return idle.onActive();
  };

  private onInactivityCountdownEnd = () => {
    const { history } = this.props;

    return history.replace(paths.WELCOME);
  };

  private onIdle = () => {
    this.setState({ isIdle: true });

    const { idle } = this.props;
    if (idle?.onIdle) return idle.onIdle();
  };

  private renderModal = () => {
    const {
      modal,
      modalClassName,
      customDescription,
      isFetching,
      isDayUseModalOpen,
    } = this.props;
    if (!modal) return null;

    const { values, isOpen, customErrorCode, isLoading, disableLoader } = modal;

    const isModalOpen =
      (!disableLoader &&
        (isFetching || isLoading) &&
        !(values && values.length > 0)) ||
      isOpen ||
      (values && values.length > 0) ||
      isDayUseModalOpen;

    return (
      <Modal
        isOpen={isModalOpen}
        type={this.modalType}
        defaultError={this.getModalDefaultError()}
        onClick={this.handleModalClick}
        customErrorCode={customErrorCode}
        error={this.getModalCustomError()}
        className={modalClassName}
        customDescription={customDescription}
      />
    );
  };

  private get modalType() {
    const { modal, isFetching, values, isDayUseModalOpen } = this.props;
    const defaultType = 'error';
    if (!modal) return defaultType;
    const { type, isLoading, disableLoader } = modal;
    if (
      !disableLoader &&
      (isFetching || isLoading) &&
      !(values && values.length > 0)
    )
      return 'fetching';
    if (isDayUseModalOpen) return 'dayUse';

    return type || defaultType;
  }

  private handleModalClick = (): (() => void) | { type: string } | void => {
    const { modal, history, isDayUseModalOpen, toggleDayUseModal } = this.props;
    if (!modal) return this.clearErrors;
    if (isDayUseModalOpen) return toggleDayUseModal();
    const { shouldClearErrors = true, shouldRedirect = true, onClick } = modal;
    if (onClick) onClick();
    if (shouldClearErrors) this.clearErrors();
    if (shouldRedirect) history.replace(paths.WELCOME);
  };

  private getMessage = (values?: ModalValue[]) =>
    values ? values.map((v) => v.message).join('\n') : '';

  private getModalDefaultError = () => {
    const { modal } = this.props;
    if (!modal) return;
    const { showDetails, values, defaultError } = modal;
    if (defaultError) return defaultError;

    return showDetails ? this.getMessage(values as ModalValue[]) : undefined;
  };

  private getModalCustomError = () => {
    const { modal, t } = this.props;
    if (!modal) return undefined;
    const { values } = modal;
    if (!values) return undefined;
    const translationCodes = Configurator.getTranslationCodes();
    const roomNotReadyError = Configurator.getTranslation(
      translationCodes?.ROOM_NOT_READY_MESSAGE
    );

    return values
      .filter((value) => value)
      .map(
        (item) =>
          typeof item === 'object' && item && 'code' in item && item.code
      )
      .filter(isDefined)
      .includes(Configurator.roomErrorCodes.DIRTY_ROOM_EXISTS)
      ? roomNotReadyError
      : undefined;
  };

  private clearErrors = () => {
    const {
      clearProfileErrors,
      clearReservationErrors,
      clearRoomErrors,
      clearHousekeepingErrors,
      clearCashieringErrors,
    } = this.props;
    clearProfileErrors();
    clearReservationErrors();
    clearRoomErrors();
    clearHousekeepingErrors();
    clearCashieringErrors();
  };
}

const mapDispatchToProps = {
  clearProfileErrors,
  clearReservationErrors,
  clearRoomErrors,
  clearHousekeepingErrors,
  clearCashieringErrors,
  toggleDayUseModal,
};

const mapStateToProps = (state: Store) => ({
  isFetching: isLoading(state),
  isDayUseModalOpen: isDayUseModalOpen(state),
});

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(View) as (props: PassedProps) => JSX.Element;

import React, { useCallback, useRef } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  addTransaction,
  getAddTransactionStatus,
} from 'store/cashiering/actions';
import { fetchReservationOutstandingDeposit } from 'store/cashiering/reservationOutstandingDeposit/actions';
import { getReservationOutstandingDeposit } from 'store/cashiering/reservationOutstandingDeposit/selectors';
import {
  getCashieringErrors,
  getFolios,
  getPaymentMethods,
  getTransactionOperationStatus,
} from 'store/cashiering/selectors';
import {
  AsyncApiCallThroughActionCreator,
  PropsWithDispatch,
} from 'store/utils/actions';
import { Folio, PaymentMethod } from 'types/Api/Cashiering';
import { ApiError, Money } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator } from 'utils';
import CheckInCardPayment from 'views/CheckInCardPayment';
import { useDepositBalance } from 'views/CheckInPrepayment/depositBalance';
import {
  isPrepaymentAvailable,
  useCorectStepInPrepayment as useCorectStep,
} from 'views/CheckInPrepayment/routing';
import { useAddingPaymentHandler } from 'views/CheckInPrepayment/transaction';

import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';

const { PREPAYMENT_MESSAGE } = Configurator.getTranslationCodes();

interface CheckInPrepaymentProps extends WithTranslation, RouteComponentProps {
  fetchOutstandingDeposit: AsyncApiCallThroughActionCreator<
    typeof fetchReservationOutstandingDeposit
  >;
  addTransaction: AsyncApiCallThroughActionCreator<typeof addTransaction>;
  getAddTransactionStatus: AsyncApiCallThroughActionCreator<
    typeof getAddTransactionStatus
  >;
  outstandingDeposit: Money;
  errors: ApiError[];
  transactionCode?: string;
  paymentAddedSuccessfully: boolean;
  folios: Folio[];
  paymentMethods: PaymentMethod[];
}

const CheckInPrepayment = ({
  t,
  fetchOutstandingDeposit,
  outstandingDeposit,
  history,
  errors,
  transactionCode,
  addTransaction,
  paymentAddedSuccessfully,
  getAddTransactionStatus,
  folios,
  paymentMethods,
}: CheckInPrepaymentProps) => {
  const isLoading = useDepositBalance(fetchOutstandingDeposit);
  const isStepAvailable = isPrepaymentAvailable(outstandingDeposit.amount);
  useCorectStep(history, isLoading, errors.length > 0, isStepAvailable);

  const addPayment = useAddingPaymentHandler(addTransaction, transactionCode);
  const checkOperationStatus = useCallback(getAddTransactionStatus, [
    getAddTransactionStatus,
  ]);

  return (
    <CheckInCardPayment
      headerTitle={t('PREPAYMENT')}
      formMainText={Configurator.getTranslation(PREPAYMENT_MESSAGE)}
      paymentValue={outstandingDeposit}
      addPayment={addPayment}
      checkOperationStatus={checkOperationStatus}
      isPaymentAdded={paymentAddedSuccessfully}
      paymentDescription={t('PREPAYMENT_AMOUNT_DESCRIPTION')}
      isInitializing={isLoading || !isStepAvailable}
      successMessage={t('PAYMENT_SUCCESSFUL')}
    />
  );
};

type PropsToMapToDispatch = Pick<
  CheckInPrepaymentProps,
  'fetchOutstandingDeposit' | 'addTransaction' | 'getAddTransactionStatus'
>;

const mapDispatchToProps: PropsWithDispatch<PropsToMapToDispatch> = {
  addTransaction,
  getAddTransactionStatus,
  fetchOutstandingDeposit: fetchReservationOutstandingDeposit,
};

type PropsFromState = Pick<
  CheckInPrepaymentProps,
  | 'outstandingDeposit'
  | 'errors'
  | 'transactionCode'
  | 'paymentAddedSuccessfully'
  | 'folios'
  | 'paymentMethods'
>;

const mapStateToProps = (state: Store): PropsFromState => ({
  outstandingDeposit: getReservationOutstandingDeposit(state),
  errors: getCashieringErrors(state),
  transactionCode: Configurator.paymentInterface.transactionCode,
  paymentAddedSuccessfully:
    getTransactionOperationStatus(state) ===
    Configurator.operationStatuses.PAYMENT_SUCCESS,
  folios: getFolios(state),
  paymentMethods: getPaymentMethods(state),
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckInPrepayment) as () => JSX.Element;

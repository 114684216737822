import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { routes } from '@gss/router';
import { getIsMainProcessInitialized } from '@gss/store/mainProcess/selectors';

export const requireMainProcessInitialized = <
  Props extends Record<string, unknown>
>(
  WrappedComponent: React.ComponentType<Props>
): typeof WrappedComponent => {
  const MainProcessInitializedValidator = (props: Props): JSX.Element => {
    const isMainProcessInitialized = useSelector(getIsMainProcessInitialized);

    if (!isMainProcessInitialized) {
      return <Navigate to={routes.WELCOME.path} replace />;
    }

    return <WrappedComponent {...props} />;
  };

  return MainProcessInitializedValidator;
};

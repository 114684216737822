import { CreditCardAuthorizationDetails } from 'types/Api/Cashiering';
import { DateManager, Router } from 'utils';
import { Path } from 'utils/Router';
import { useCorectStep } from 'views/CheckInCardPayment/routing';

import { LegacyHistory } from '@LEGACY/utils/withRouter';

export function useCorectStepInPreAuthorization(
  isPreAuthorizationAvailable: boolean,
  history: LegacyHistory,
  isLoading: boolean,
  hasError: boolean
) {
  useCorectStep(
    history,
    isLoading,
    hasError,
    () => configurePreAuthSteps(isPreAuthorizationAvailable),
    () => isPreAuthorizationAvailable
  );
}

export function configurePreAuthSteps(isPreAuthorizationAvailable: boolean) {
  if (!isPreAuthorizationAvailable) {
    // eslint-disable-next-line no-console
    console.warn('Pre-authorization step is going to be skipped');
    Router.removeStep(Path.checkIn, 'PRE_AUTHORIZATION');
  }
}

const daysNumberOfValidTransaction = 0;

export function isPreAuthorizationAvailable(
  currentDateTime: string,
  activeAuthorizations: CreditCardAuthorizationDetails[]
) {
  const validAuthorizationExists = activeAuthorizations.some(
    (authorization) =>
      !!authorization.completedAt &&
      DateManager.getDiffBetween(authorization.completedAt, currentDateTime) ===
        daysNumberOfValidTransaction
  );

  return !validAuthorizationExists;
}

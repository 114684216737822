import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  EmptyInterface,
  extractIdFromLocationHeader,
  LibraryApiResponse,
} from '@ac/library-api';
import { isDefined, repeatableCall } from '@ac/library-utils/dist/utils';

import { SelfServiceMakeKeysProcessApi } from '@gss/api/KioskApi';
import {
  MakeKeysOperationProgressDto,
  MakeKeysReservationDetailsDto,
} from '@gss/api/KioskApi/entries';
import { API_HEADERS } from '@gss/configs/constants';
import { ONE_SECOND } from '@gss/configs/timers';
import { getMakeKeysProcessId } from '@gss/store/flows/makeKeysFlow/selectors';
import { SagasGenerator } from '@gss/types/shared';
import { handleSagaError } from '@gss/utils/sagas/handleSagaError';

import * as actions from './actions';
import { getGenerateKeysAmount } from './selectors';
import { GenerateKeyError } from './utils';

const GENERATE_KEY_STATUS_VERIFICATION_COUNTER = 30;

export function* newKeySaga(): SagasGenerator<string> {
  const processId: string = yield select(getMakeKeysProcessId);

  const response: LibraryApiResponse<EmptyInterface> =
    yield SelfServiceMakeKeysProcessApi.createKey({
      customConfig: {
        headers: {
          [API_HEADERS.kioskSessionId]: processId,
        },
      },
    });

  const operationId = extractIdFromLocationHeader(response.headers);

  if (!operationId) {
    throw new Error('Missing operation id in location header');
  }

  return operationId;
}

export function* duplicateKeySaga(): SagasGenerator<string> {
  const processId: string = yield select(getMakeKeysProcessId);

  const response: LibraryApiResponse<EmptyInterface> =
    yield SelfServiceMakeKeysProcessApi.duplicateKey({
      customConfig: {
        headers: {
          [API_HEADERS.kioskSessionId]: processId,
        },
      },
    });

  const operationId = extractIdFromLocationHeader(response.headers);

  if (!operationId) {
    throw new Error('Missing operation id in location header');
  }

  return operationId;
}

export function* keyGenerationStatusSaga(operationId: string): SagasGenerator {
  const processId: string = yield select(getMakeKeysProcessId);

  const statusData: MakeKeysOperationProgressDto = yield repeatableCall(
    async (): Promise<MakeKeysOperationProgressDto> => {
      const operationResponse =
        await SelfServiceMakeKeysProcessApi.operationStatus({
          pathParams: {
            operationId,
          },
          customConfig: {
            headers: {
              [API_HEADERS.kioskSessionId]: processId,
            },
          },
        });

      return operationResponse.data;
    },
    (operationStatus: MakeKeysOperationProgressDto) =>
      isDefined(operationStatus.finishedAt) || !!operationStatus.errors?.length,
    {
      repeatCount: GENERATE_KEY_STATUS_VERIFICATION_COUNTER,
      intervalTime: ONE_SECOND,
    }
  );

  if (!statusData.finishedAt || statusData.errors?.length) {
    throw new GenerateKeyError();
  }
}

export function* generateKeySaga(): SagasGenerator {
  try {
    const amountOfCreatedKeys: MakeKeysReservationDetailsDto = yield select(
      getGenerateKeysAmount
    );
    const isOriginalKeyGenerated = Boolean(amountOfCreatedKeys);

    const generationSagaType = isOriginalKeyGenerated
      ? duplicateKeySaga
      : newKeySaga;

    const generationProcessId: string = yield call(generationSagaType);
    yield call(keyGenerationStatusSaga, generationProcessId);

    yield put(actions.generateKey.success());
  } catch (error) {
    yield put(actions.generateKey.failure(handleSagaError(error)));
  }
}

export function* keyGeneratorStepSagas(): SagasGenerator {
  yield takeLatest(actions.generateKey.trigger, generateKeySaga);
}

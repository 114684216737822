import { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { IconTypes, LargeButton } from '@ac/kiosk-components';

import { QRScannerModal, ScannerData } from '@gss/components/modals';

interface PassedProps {
  onSubmit: (values: ScannerData) => void;
}

interface QrScannerButtonProps extends PassedProps, WithTranslation {}

interface QrScannerButtonState {
  isScannerModalOpen: boolean;
}

class QrScannerButton extends PureComponent<
  QrScannerButtonProps,
  QrScannerButtonState
> {
  public state = {
    isScannerModalOpen: false,
  };

  public render() {
    const { t } = this.props;
    const { isScannerModalOpen } = this.state;

    return (
      <>
        {isScannerModalOpen && (
          <QRScannerModal
            onClose={this.onModalToggle}
            onScanComplete={this.onScan}
          />
        )}
        <LargeButton
          className="spacing-bottom-xxlg"
          onClick={this.onModalToggle}
          dataTestSelector="check-in-authorizaton-qr-scanner-button"
          icon={IconTypes.qrCode}
        >
          {t('SCAN_QR_CODE')}
        </LargeButton>
      </>
    );
  }

  private onScan = (values: ScannerData) => {
    this.props.onSubmit(values);
    this.onModalToggle();
  };

  private onModalToggle = () => {
    const { isScannerModalOpen } = this.state;
    this.setState({ isScannerModalOpen: !isScannerModalOpen });
  };
}

export default compose(withTranslation())(QrScannerButton) as (
  props: PassedProps
) => JSX.Element;

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { routes } from '@gss/router';
import { getIsMakeKeysProcessReady } from '@gss/store/flows/makeKeysFlow/selectors';

export const requireMakeKeysProcessReady = <
  Props extends Record<string, unknown>
>(
  WrappedComponent: React.ComponentType<Props>
): typeof WrappedComponent => {
  const MakeKeysProcessReadyValidator = (props: Props): JSX.Element => {
    const isMakeKeysProcessReady = useSelector(getIsMakeKeysProcessReady);

    if (!isMakeKeysProcessReady) {
      return <Navigate to={routes.KEY_AUTH.path} replace />;
    }

    return <WrappedComponent {...props} />;
  };

  return MakeKeysProcessReadyValidator;
};

import { colors } from 'config';
import { Colors, Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const backgroundSettings: { [key: string]: any } = {
  fill: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
  fit: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  stretch: {
    backgroundSize: '100% 100%',
  },
  tile: {
    backgroundRepeat: 'repeat',
  },
  center: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
};

const getBackground = () => {
  const backgroundUrl = Configurator.viewBackgroundImage || '';
  const backgroundPosition = Configurator.viewBackgroundPosition
    ? Configurator.viewBackgroundPosition.toLowerCase()
    : '';
  const backgroundColor = Configurator.getColor(
    Configurator.colorCodes.BODY_BACKGROUND
  );

  return {
    ...(backgroundColor
      ? { backgroundColor }
      : { backgroundColor: colors.grey[75] }),
    ...(backgroundUrl
      ? {
          backgroundImage: `url(${backgroundUrl})`,
          ...backgroundSettings[backgroundPosition],
        }
      : {}),
  };
};

export default ({ palette }: Theme) =>
  createStyles({
    view: {
      paddingTop: Configurator.propertySettings?.nonProduction
        ? 'var(--test-environment-bar-height)'
        : 0,
      ...getBackground(),
      ...Colors.getFontColor(
        palette,
        Configurator.colorCodes.BODY_FONT_COLOR,
        palette?.common?.black
      ),
    },

    inactivityModal: {
      zIndex: 99999,
    },
  });

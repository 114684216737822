import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux-saga';

import {
  Flex,
  Grid,
  MagicButton,
  MagicButtonHorizontalPosition,
  MagicButtonVerticalPosition,
  Section,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { Body, View } from '@gss/components/layout';
import {
  SupportedLanguagesSelector,
  SupportedLanguagesSelectorType,
} from '@gss/components/utility';
import { resetFlowsData } from '@gss/store/globalActions';
import { initializeMainProcess } from '@gss/store/mainProcess/actions';
import { getIsMainProcessInitialized } from '@gss/store/mainProcess/selectors';
import { prepareSettings } from '@gss/store/settings/actions';
import { getPropertyConfiguration } from '@gss/store/settings/selectors';
import { clearState } from '@LEGACY/store/actions';
import { Router } from '@LEGACY/utils';

import { RouteButtons, WelcomeHeader } from './components';

import './Welcome.scss';

export const Welcome = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const isMainProcessInitialized = useSelector(getIsMainProcessInitialized);

  useEffect(() => {
    if (!isMainProcessInitialized) {
      dispatch(initializeMainProcess.trigger());
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch(clearState() as unknown as AnyAction);
    dispatch(resetFlowsData());
    Router.initSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshSettings = useCallback(() => {
    dispatch(
      prepareSettings.trigger({
        withDefaultLanguage: true,
      })
    );
  }, [dispatch]);

  return (
    <View className="welcome-view-wrapper">
      <Body>
        <Grid
          className="fill-container"
          gridTemplateRowsSm="minmax(330px, 38dvh) 1fr auto"
        >
          <Grid.Item>
            <MagicButton
              dataTestSelector="refresh-settings-secret-button"
              className="welcome-view-secret-button"
              positionHorizontal={MagicButtonHorizontalPosition.right}
              positionVertical={MagicButtonVerticalPosition.top}
              width={90}
              height={90}
              onLongPress={refreshSettings}
              onDoubleClick={refreshSettings}
            />
            <WelcomeHeader />
          </Grid.Item>
          <Grid.Item>
            <Section>
              <RouteButtons />
            </Section>
          </Grid.Item>
          <Grid.Item>
            <Section>
              <SupportedLanguagesSelector
                type={SupportedLanguagesSelectorType.buttonGroup}
              />
              <Flex className="spacing-top-sm gap-xs property-name">
                <Text size={TextSize.xs}>{t('SHARED.PROPERTY')}:</Text>
                <Text size={TextSize.xs}>{propertyConfiguration?.name}</Text>
              </Flex>
            </Section>
          </Grid.Item>
        </Grid>
      </Body>
    </View>
  );
};

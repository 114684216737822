import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Button, IconTypes } from '@ac/kiosk-components';

import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';
import { Grid, Modal } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import { removeIdPhoto } from '../store/actions';

import styles, { getBackground } from './DocumentScan.style';

export interface PassedProps {
  image: string;
}

interface DocumentScanProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  removeIdPhoto: typeof removeIdPhoto;
}

interface DocumentScanState {
  isPhotoModalOpen: boolean;
}

class DocumentScan extends PureComponent<DocumentScanProps, DocumentScanState> {
  public state = {
    isPhotoModalOpen: false,
  };

  public render() {
    const { classes, image } = this.props;
    const { isPhotoModalOpen } = this.state;

    return (
      <Grid
        component="a"
        className={classes.wrapper}
        style={{ ...getBackground(image) }}
        onClick={this.togglePhotoModal}
      >
        <Modal
          open={isPhotoModalOpen}
          onBackdropClick={this.togglePhotoModal}
          classes={{ root: classes.modalRoot }}
          className="with-default-kiosk-components-theme"
          BackdropProps={{ classes: { root: classes.backdropRoot } }}
        >
          <img
            alt={`image-${image}`}
            src={image}
            className={classes.fullSizeImage}
          />
        </Modal>
        <Grid className={classes.iconButton}>
          <Button
            onClick={this.handleRemovePhoto}
            icon={IconTypes.trash}
            className={classes.icon}
          />
        </Grid>
      </Grid>
    );
  }

  private handleRemovePhoto = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { removeIdPhoto, image } = this.props;
    removeIdPhoto(image);
  };

  private togglePhotoModal = (e: React.MouseEvent) => {
    e.preventDefault();
    const { isPhotoModalOpen } = this.state;
    this.setState({ isPhotoModalOpen: !isPhotoModalOpen });
  };
}

const mapDispatchToProps = {
  removeIdPhoto,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(DocumentScan) as (props: PassedProps) => JSX.Element;

import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Flex,
  FlexDirection,
  Grid,
  JustifyContent,
  LargeButton,
  LargeButtonIconPosition,
  Text,
} from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';

import { useLargeScreenMatchMedia } from '@gss/utils/hooks';

import { useRouteButtonsConfiguration } from './hooks';

import './RouteButtons.scss';

export const RouteButtons = (): JSX.Element => {
  const isLargeScreen = useLargeScreenMatchMedia();
  const navigate = useNavigate();
  const routeButtonsConfiguration = useRouteButtonsConfiguration();

  const isAnyDescription = routeButtonsConfiguration.some((route) =>
    isDefined(route.description)
  );

  const visibleRoutesAmount = routeButtonsConfiguration.filter(
    (route) => route.isVisible
  ).length;

  return (
    <Flex
      grow
      justifyContent={JustifyContent.spaceBetween}
      direction={isLargeScreen ? FlexDirection.row : FlexDirection.column}
      className="gap-xxlg spacing-horizontal-auto spacing-vertical-xxlg route-buttons-wrapper"
    >
      <Grid
        className="gap-xlg route-buttons-grid"
        gridTemplateColumnsSm={isAnyDescription ? 'auto 1fr' : '1fr'}
        gridTemplateColumnsLg={Array(visibleRoutesAmount)
          .fill('228px')
          .join(' ')}
      >
        {routeButtonsConfiguration.map((routeButton, index) =>
          !routeButton.isVisible ? null : (
            <Fragment key={index}>
              <Grid.Item>
                <LargeButton
                  icon={routeButton.icon}
                  dataTestSelector={routeButton.dataTestSelector}
                  onClick={(): void => navigate(routeButton.path)}
                  iconPosition={
                    isLargeScreen
                      ? LargeButtonIconPosition.top
                      : LargeButtonIconPosition.left
                  }
                >
                  {routeButton.label}
                </LargeButton>
              </Grid.Item>
              {isAnyDescription && (
                <Grid.Item className="route-buttons-description-item">
                  <Text>{routeButton.description}</Text>
                </Grid.Item>
              )}
            </Fragment>
          )
        )}
      </Grid>
    </Flex>
  );
};

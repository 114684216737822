import { createSelector } from 'reselect';
import { getAllProfiles } from 'store/profile/selectors';
import { getAllReservations } from 'store/reservation/selectors';
import { getRoomDetails } from 'store/room/selectors';

import { DateTimeManager } from '@gss/services/DateTimeManager';

export const getReservationListData = createSelector(
  getAllReservations,
  getAllProfiles,
  getRoomDetails,
  (reservations, profiles, room) =>
    reservations
      .filter((reservation) => {
        const profileIds = profiles.reduce(
          (acc: any, item) => [...acc, item.id],
          []
        );
        if (profileIds.includes(reservation.profileId)) return true;

        return false;
      })
      .map((reservation) => {
        const profile = profiles.find(
          (profile: any) => profile.id === reservation.profileId
        )!.details;

        return {
          firstName: profile.firstName,
          lastName: profile.lastName,
          confirmationNumber: reservation.confirmationNumber,
          arrivalDate: DateTimeManager.getFormattedShortDate(
            reservation.arrivalDate
          ),
          departureDate: DateTimeManager.getFormattedShortDate(
            reservation.departureDate
          ),
          roomNumber: room ? room.code : 'unassigned',
          id: reservation.id,
        };
      })
);

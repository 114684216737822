import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormErrorMessage, FormTextField, Modal } from 'components';
import { compose } from 'redux';

import { Button, ButtonPattern, IconTypes } from '@ac/kiosk-components';

import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './SelectModal.style';

type Option = {
  title: string;
  value: string;
};

interface SelectModalProps extends WithTranslation, WithStyles<typeof styles> {
  placeholder?: string;
  title?: string;
  name: string;
  open?: boolean;
  options: Option[];
  onSelect: (value: string) => void;
  onSearch?: (value: string) => void;
  onCancel: () => void;
  onModalClose?: () => void;
  initialValue?: string;
  isSearchVisible?: boolean;
}

interface SelectModalState {
  searchValue: string;
  selectedValue: string;
  filterValue: string;
}

class SelectModal extends PureComponent<SelectModalProps, SelectModalState> {
  public static defaultProps = {
    placeholder: '',
    title: '',
    initialValue: '',
    open: false,
    isSearchVisible: true,
    showIcon: false,
    options: [
      {
        title: '',
        value: '',
      },
    ],
  };

  public state = {
    searchValue: '',
    selectedValue: '',
    filterValue: '',
  };

  public searchTimeout: ReturnType<typeof setTimeout> | null = null;

  public componentDidUpdate(prevProps: SelectModalProps) {
    const { open } = prevProps;
    const { open: willOpen, initialValue, options } = this.props;

    if (!open && willOpen) {
      this.onModalOpen(initialValue!, options);
    }
  }

  public render() {
    const {
      classes,
      placeholder,
      t,
      title,
      open,
      options: selectOptions,
      name,
      onCancel,
      isSearchVisible,
      initialValue,
    } = this.props;

    const { selectedValue, searchValue, filterValue } = this.state;

    const options =
      selectOptions.length &&
      selectOptions.filter((item) =>
        item?.title?.toLowerCase().includes(filterValue.toLowerCase())
      );
    const isError = !(options && options.length);

    return (
      <Modal isOpen={!!open} className={classes.modal}>
        <Grid className={classes.wrapper}>
          <Grid className={classes.header}>
            {title && (
              <Typography className={classes.title}>{title}</Typography>
            )}
            {isSearchVisible && (
              <FormTextField
                className={isError ? classes.searchBoxError : classes.searchBox}
                onChange={this.onSearch}
                icon={IconTypes.search}
                placeholder={placeholder}
                isError={isError}
                value={searchValue}
                autoFocus
              />
            )}
            {isError && <FormErrorMessage error={t('NO_RESULTS')} />}
          </Grid>
          <Grid className={classes.body}>
            <RadioGroup
              aria-label={name}
              name={name}
              value={selectedValue}
              onChange={this.onSelect}
              classes={{ root: classes.radioRoot }}
            >
              {options
                ? options.map((item) => (
                    <FormControlLabel
                      value={item.value}
                      control={<Radio color="primary" />}
                      label={<Typography>{item.title}</Typography>}
                      key={`item-select-${item.value}`}
                      classes={{
                        root: classes.formControl,
                        label: classes.label,
                      }}
                    />
                  ))
                : null}
            </RadioGroup>
          </Grid>
          <Grid className={classes.footer}>
            <Button pattern={ButtonPattern.secondary} onClick={onCancel}>
              {t('CANCEL')}
            </Button>
            <Button
              disabled={
                isError || !selectedValue || selectedValue === initialValue
              }
              onClick={this.handleSubmit}
            >
              {t('SELECT')}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    );
  }

  private onSearch = (e: any) => {
    const { onSearch } = this.props;
    const text = e.target.value;
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    if (onSearch) onSearch(text);
    this.setState({ searchValue: text });

    this.searchTimeout = setTimeout(() => {
      this.setState({ filterValue: text });
    }, 500);
  };

  private onSelect = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    this.setState({ selectedValue: target.value });
  };

  private onModalOpen = (value: string, options: Option[]) => {
    const { selectedValue } = this.state;
    const values = options.map((item) => item.value);
    if (values.length && !values.includes(value)) {
      return this.setState({ selectedValue: options[0].value });
    }
    if (!selectedValue) {
      this.setState({ selectedValue: value });
    }
  };

  private handleSubmit = (event: any) => {
    const { onSelect } = this.props;
    const { selectedValue } = this.state;
    onSelect(selectedValue);
  };
}

const ComposedSelectModal = compose(
  withStyles(styles),
  withTranslation()
)(SelectModal);

export default ComposedSelectModal as any;

import { useZxing } from 'react-zxing';

import { QRCodeInvalidError, UnknownError } from '../errors';

const EXPECTED_EXCEPTIONS = [
  'NotFoundException',
  'ChecksumException',
  'FormatException',
];

const QR_FORMAT_CODE = 11;

interface Props {
  onLoad?: () => void;
  onError?: (scanError: unknown) => void;
  onScan: (data: string | null) => void;
}

export const DefaultQRScanner = ({
  onLoad,
  onError,
  onScan,
}: Props): JSX.Element => {
  const { ref } = useZxing({
    onDecodeResult: (result) => {
      const isQRCode = result.getBarcodeFormat() === QR_FORMAT_CODE;

      if (!isQRCode) {
        onError?.(new QRCodeInvalidError());

        return;
      }

      onScan(result.getText());
    },
    onError,
    onDecodeError: (error) => {
      /** @link {https://github.com/zxing-js/library/blob/master/docs/examples/qr-camera/index.html#L90} */
      if (EXPECTED_EXCEPTIONS.includes(error.getKind())) {
        return;
      }

      onError?.(new UnknownError());
    },
    constraints: {
      video: { facingMode: 'user' },
      audio: false,
    },
  });

  return <video ref={ref} className="fill-container" onLoadedData={onLoad} />;
};

import i18next from 'i18next';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { LogReporter } from '@ac/kiosk-components';
import {
  BaseApi,
  buildFIQLFilter,
  Config,
  EffectiveUserPermissionIdData,
  FIQLOperators,
  getDtoForAllPages,
  LibraryApiResponse,
  Logger,
  PageQueryParams,
  PageResponseDto,
  PermissionDetails,
} from '@ac/library-api';
import { PermissionsApi } from '@ac/library-api/dist/api/v0/permissionManagement';
import { EffectiveUserPermissionApi } from '@ac/library-api/dist/api/v0/permissionManagement/users';
import { acConfig, Action } from '@ac/library-utils/dist/declarations';
import { SessionDataHost } from '@ac/library-utils/dist/services';

import {
  SelfServiceInitializationApi,
  SelfServiceUserApi,
} from '@gss/api/KioskApi';
import {
  KioskLoggedUserDto,
  SelfServiceDeviceDto,
  SelfServicePropertyDto,
} from '@gss/api/KioskApi/entries';
import { APP_NAME, DEFAULT_APP_LANGUAGE } from '@gss/configs/constants';
import { changeUiLanguage } from '@gss/i18n';
import { Authorizer, Storage } from '@gss/services';
import {
  keepDeviceLiveTask,
  refreshConfigurationTask,
} from '@gss/store/backgroundTasks/actions';
import { Awaited, SagasGenerator } from '@gss/types/shared';
import { getLocalizedContent } from '@gss/utils';
import { handleSagaError } from '@gss/utils/sagas/handleSagaError';
import { resetLegacyAppSetup } from '@LEGACY/store/setup/actions';
import {
  clearCachedLoginErrorLogs,
  getCachedLoginErrorLogs,
} from '@LEGACY/utils/loginErrorLogsCache';

import { clearAllGlobalErrors, resetGlobalSetupData } from '../globalActions';

import { validateConfiguration } from './utils/validateConfiguration';
import * as actions from './actions';
import {
  ChangeLanguagePayload,
  PermissionData,
  propertyPermissionsMap,
  PropertyPermissionsState,
} from './interfaces';
import { getUsedPropertyId } from './selectors';

export function* fetchPropertyPermissions(
  unitId: string
): SagasGenerator<PropertyPermissionsState> {
  const permissionIds = Object.values(propertyPermissionsMap).map(
    ({ id }) => id
  );

  const permissionsDictionary: PageResponseDto<PermissionDetails> =
    yield PermissionsApi.getList({
      queryParams: {
        pageSize: permissionIds.length,
        filter: buildFIQLFilter('id', FIQLOperators.equal, permissionIds),
      },
      customConfig: {
        skipCache: false,
      },
    });

  const grantedPermissions: PageResponseDto<EffectiveUserPermissionIdData> =
    yield EffectiveUserPermissionApi.getMyInUnit({
      pathParams: { unitId },
      queryParams: {
        pageSize: permissionIds.length,
        filter: buildFIQLFilter(
          'permissionId',
          FIQLOperators.equal,
          permissionIds
        ),
      },
    });

  return Object.entries(propertyPermissionsMap).reduce(
    (acc, [key, permission]) => {
      const permissionName = permissionsDictionary.results.find(
        ({ id }) => id === permission.id
      );
      const isGranted = grantedPermissions.results.some(
        ({ permissionId }) => permissionId === permission.id
      );

      const permissionData: PermissionData = {
        id: permission.id,
        isRequired: !permission.isOptional,
        isGranted,
        name: permissionName?.name && getLocalizedContent(permissionName?.name),
      };

      return {
        ...acc,
        [key]: permissionData,
      };
    },
    {}
  );
}

export function* handlePrepareApplication(): SagasGenerator {
  try {
    if (!BaseApi.defaultConfig) {
      BaseApi.host = acConfig.apiUrl;
      BaseApi.defaultConfig = (): Promise<Config> =>
        Promise.resolve(Authorizer.getStaticConfiguration());
    }

    try {
      const remoteLoggerInstance: Awaited<ReturnType<typeof Logger.init>> =
        yield Logger.init(APP_NAME);

      LogReporter.assignRemoteLogger(remoteLoggerInstance);

      const loginErrors = getCachedLoginErrorLogs();
      loginErrors.forEach((message) => remoteLoggerInstance.error(message));
    } catch (error) {
      /**
       * Logger is optional for application.
       * It throws error for local environment because of no version.json file
       */
    } finally {
      clearCachedLoginErrorLogs();
    }

    const user: LibraryApiResponse<KioskLoggedUserDto> =
      yield SelfServiceUserApi.getLoggedUser();
    yield put(actions.setCurrentUser(user.data));

    yield put(actions.prepareApplication.success());
  } catch (error) {
    yield put(actions.prepareApplication.failure(handleSagaError(error)));
  }
}

export function* handlePreparePropertyConfiguration(
  action: Action<string>
): SagasGenerator {
  try {
    const propertyId = action.payload;
    const propertyPermissions: PropertyPermissionsState = yield call(
      fetchPropertyPermissions,
      propertyId
    );

    validateConfiguration(propertyPermissions);

    yield put(actions.setCurrentProperty(propertyId));
    yield put(
      actions.preparePropertyConfiguration.success(propertyPermissions)
    );
  } catch (error) {
    yield put(
      actions.preparePropertyConfiguration.failure(handleSagaError(error))
    );
  }
}

export function* handleRebootApplication(): SagasGenerator {
  try {
    yield Logger.forceSend();
    localStorage.clear();
    yield put(actions.setCurrentProperty(null));
    yield put(actions.setUsedDeviceId(null));
  } finally {
    window.location.reload();
  }
}

export function* handleResetApplication(): SagasGenerator {
  yield put(clearAllGlobalErrors());
  yield put(keepDeviceLiveTask.stop());
  yield put(refreshConfigurationTask.stop());
  yield changeUiLanguage(DEFAULT_APP_LANGUAGE);
  yield put(actions.setCurrentProperty(null));
  yield put(actions.setUsedDeviceId(null));
  yield put(resetLegacyAppSetup() as unknown as Action); // TODO: Remove after refactor
  yield put(resetGlobalSetupData());
}

export function* handleLogoutApplication(): SagasGenerator {
  try {
    yield Logger.forceSend();
    SessionDataHost.clearSessionData();
    localStorage.clear();
    yield put(actions.setCurrentProperty(null));
    yield put(actions.setUsedDeviceId(null));
  } finally {
    Authorizer.logout();
  }
}

function* handleFetchPropertiesList(): SagasGenerator {
  try {
    const allPropertiesPromise = ({
      pageNumber,
      pageSize,
    }: PageQueryParams): Promise<
      LibraryApiResponse<PageResponseDto<SelfServicePropertyDto>>
    > => {
      return SelfServiceInitializationApi.getProperties({
        queryParams: { pageNumber, pageSize },
      });
    };

    const properties: PageResponseDto<SelfServicePropertyDto> =
      yield getDtoForAllPages(allPropertiesPromise);

    yield put(actions.fetchPropertyList.success(properties.results));
  } catch (error) {
    yield put(actions.fetchPropertyList.failure(handleSagaError(error)));
  }
}

function* handleFetchDevicesList(): SagasGenerator {
  try {
    const allDevicesPromise = ({
      pageNumber,
      pageSize,
    }: PageQueryParams): Promise<
      LibraryApiResponse<PageResponseDto<SelfServiceDeviceDto>>
    > => {
      return SelfServiceInitializationApi.getDevices({
        queryParams: { pageNumber, pageSize },
      });
    };

    const devices: PageResponseDto<SelfServiceDeviceDto> =
      yield getDtoForAllPages(allDevicesPromise);

    yield put(actions.fetchDeviceList.success(devices.results));
  } catch (error) {
    yield put(actions.fetchDeviceList.failure(handleSagaError(error)));
  }
}

export function* updatePropertyConfiguration(
  action: Action<string>
): SagasGenerator {
  const propertyId = action.payload;

  BaseApi.defaultConfig = (): Promise<Config<undefined>> =>
    Promise.resolve(
      Authorizer.getStaticConfiguration({
        propertyId,
        language: i18next.language,
      })
    );

  if (propertyId) {
    yield Storage.savePropertyId(action.payload);
  } else {
    yield Storage.deletePropertyId();
  }
}

export function* updateDeviceConfiguration(
  action: Action<string | null>
): SagasGenerator {
  if (action.payload) {
    yield put(keepDeviceLiveTask.start());
    yield Storage.saveDeviceCookie(action.payload);
  } else {
    yield put(keepDeviceLiveTask.stop());
    yield Storage.deleteDeviceCookie();
  }
}

export function* handleChangeAppLanguage(
  action: Action<ChangeLanguagePayload>
): SagasGenerator {
  try {
    const newLanguage = action.payload.language;
    const propertyId: string = yield select(getUsedPropertyId);

    yield changeUiLanguage(newLanguage);

    BaseApi.defaultConfig = (): Promise<Config<undefined>> =>
      Promise.resolve(
        Authorizer.getStaticConfiguration({
          propertyId,
          language: i18next.language,
        })
      );
    yield put(actions.changeAppLanguage.success(action.payload));
  } catch (error) {
    yield put(actions.changeAppLanguage.failure(handleSagaError(error)));
  }
}

export function* configurationSagas(): SagasGenerator {
  yield takeLatest(
    actions.prepareApplication.trigger,
    handlePrepareApplication
  );
  yield takeLatest(
    actions.preparePropertyConfiguration.trigger,
    handlePreparePropertyConfiguration
  );
  yield takeLatest(actions.setCurrentProperty, updatePropertyConfiguration);
  yield takeLatest(actions.setUsedDeviceId, updateDeviceConfiguration);
  yield takeLatest(
    actions.fetchPropertyList.trigger,
    handleFetchPropertiesList
  );
  yield takeLatest(actions.fetchDeviceList.trigger, handleFetchDevicesList);
  yield takeLatest(actions.changeAppLanguage.trigger, handleChangeAppLanguage);
  yield takeLatest(actions.rebootApplication, handleRebootApplication);
  yield takeLatest(actions.resetApplication, handleResetApplication);
  yield takeLatest(actions.logoutApplication, handleLogoutApplication);
}

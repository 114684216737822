import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Body, Footer, FormHeader, Modal, View } from 'components';
import { QuickCheckOutModal } from 'components/modals';
import { compose } from 'redux';
import { clearState } from 'store/actions';
import { fetchAllTransactions } from 'store/aggregator/actions';
import { fetchAvailablePurchaseItems } from 'store/availability/actions';
import {
  fetchAdditionalFolioInfo,
  fetchBillingInstructions,
  fetchCashieringAccount,
  fetchFolios,
  fullCheckOut,
} from 'store/cashiering/actions';
import {
  getFolios,
  getIsEveryFolioHasAvailableFolioStyle,
} from 'store/cashiering/selectors';
import { continueCheckOutAsFastTrackProcess } from 'store/checkOutProcess/actions';
import {
  getIsFastTrackCheckOutPossible,
  getIsReviewChargesPossible,
} from 'store/checkOutProcess/selectors';
import {
  chooseProfile,
  fetchCompany,
  fetchProfileInternalMemberships,
} from 'store/profile/actions';
import { getAddresses, getAllProfiles } from 'store/profile/selectors';
import {
  fetchBreakdown,
  fetchReservation,
  fetchReservationPurchases,
} from 'store/reservation/actions';
import { getAllReservations } from 'store/reservation/selectors';
import { getCompanyProfileIds } from 'store/selectors';
import { Folio } from 'types/Api/Cashiering';
import { Address, Profile } from 'types/Api/Profile';
import {
  ReservationListDataModel,
  ReservationView,
} from 'types/Api/Reservation';
import Store from 'types/Store';
import Router, { Path, paths } from 'utils/Router';

import { Configurator } from '@gss/_LEGACY_/utils';
import { Header } from '@gss/components/layout';
import {
  fetchDistricts,
  fetchStates,
} from '@gss/store/lazyLoadedDictionary/actions';
import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';

import { redirect } from '../CheckOutAuth/CheckOutAuth';

import { getReservationListData } from './store/selectors';
import ReservationList from './ReservationList';

interface PassedProps {}

interface CheckOutReservationState {
  isLoading: boolean;
  isCheckoutNotPossible: boolean;
  isQuickCheckOutModalOpen: boolean;
}

interface CheckOutReservationProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation {
  reservationListData: ReservationListDataModel[];
  reservations: ReservationView[];
  fetchAdditionalFolioInfo: typeof fetchAdditionalFolioInfo;
  fetchReservationPurchases: typeof fetchReservationPurchases;
  fetchAvailablePurchaseItems: typeof fetchAvailablePurchaseItems;
  chooseProfile: typeof chooseProfile;
  fetchBillingInstructions: typeof fetchBillingInstructions;
  fetchFolios: typeof fetchFolios;
  fetchBreakdown: typeof fetchBreakdown;
  fetchAllTransactions: typeof fetchAllTransactions;
  fetchCashieringAccount: typeof fetchCashieringAccount;
  fetchReservation: typeof fetchReservation;
  fetchStates: typeof fetchStates.trigger;
  fetchDistricts: typeof fetchDistricts.trigger;
  fullCheckOut: typeof fullCheckOut;
  clearState: typeof clearState;
  continueCheckOutAsFastTrackProcess: typeof continueCheckOutAsFastTrackProcess;
  fetchProfileInternalMemberships: typeof fetchProfileInternalMemberships;
  isEveryFolioHasAvailableFolioStyle: ReturnType<
    typeof getIsEveryFolioHasAvailableFolioStyle
  >;
  addresses: Address[];
  linkedCompanyIds: string[];
  profiles: Profile[];
  folios: Folio[];
  isReviewChargesPossible: boolean;
  isFastTrackCheckOutPossible: boolean;
}

// eslint-disable-next-line max-len
class CheckOutReservation extends PureComponent<
  CheckOutReservationProps,
  CheckOutReservationState
> {
  public state = {
    isLoading: false,
    isCheckoutNotPossible: false,
    isQuickCheckOutModalOpen: false,
  };

  public static defaultProps = {
    reservations: [],
    profiles: [],
  };

  public render() {
    const { t, reservationListData } = this.props;
    const { isLoading, isQuickCheckOutModalOpen, isCheckoutNotPossible } =
      this.state;

    const { CHECK_OUT_NOT_POSSIBLE } = Configurator.getTranslationCodes();
    const defaultError = Configurator.getTranslation(CHECK_OUT_NOT_POSSIBLE);

    return (
      <View idle={{ type: 'modal' }} modal={{ isLoading }}>
        {isQuickCheckOutModalOpen && (
          <QuickCheckOutModal
            onCancel={this.goToNextStep}
            onConfirm={this.onQuickCheckoutConfirm}
          />
        )}

        <Modal
          isOpen={isCheckoutNotPossible}
          type="error"
          defaultError={defaultError}
          onClick={this.onErrorModalClick}
        />

        <Header title={`${t('CHECK_OUT')} - ${t('CHOOSE_RESERVATION')}`} />
        <Body>
          <FormHeader title={t('CHOOSE_RESERVATION_TO_CHECK_OUT')} />
          <ReservationList
            data={reservationListData}
            onItemClick={this.onReservationChoose}
            selectionDisabled={isLoading}
          />
        </Body>
        <Footer hasCancelButton routeName={t('CHECK_OUT')} />
      </View>
    );
  }

  private openQuickCheckOutModal = () =>
    this.setState({ isQuickCheckOutModalOpen: true });

  private closeQuickCheckOutModal = () =>
    this.setState({ isQuickCheckOutModalOpen: false });

  private onQuickCheckoutConfirm = async () => {
    const { continueCheckOutAsFastTrackProcess, history } = this.props;
    this.closeQuickCheckOutModal();
    this.enableLoading();
    await continueCheckOutAsFastTrackProcess();
    history.push(Router.nextStepURL);
  };

  private onReservationChoose = async (reservationId: string) => {
    const {
      chooseProfile,
      fetchFolios,
      fetchBreakdown,
      fetchAllTransactions,
      fetchCashieringAccount,
      fetchBillingInstructions,
      fetchReservation,
      fetchAvailablePurchaseItems,
      fetchReservationPurchases,
      fetchStates,
      fetchDistricts,
      fullCheckOut,
      fetchAdditionalFolioInfo,
      fetchProfileInternalMemberships,
      i18n,
    } = this.props;

    this.enableLoading();
    await fetchReservation(reservationId);
    const {
      reservations: [reservation],
    } = this.props;
    const { arrivalDate, departureDate, id, profileId, accountId } =
      reservation;
    await chooseProfile(profileId);
    await Promise.all([
      fetchFolios(),
      fetchBillingInstructions(accountId),
      fetchCashieringAccount(accountId),
      fetchProfileInternalMemberships(),
    ]);
    await Promise.all([
      fetchBreakdown(reservationId),
      fetchAllTransactions(reservationId),
    ]);
    await Promise.all([
      fetchReservationPurchases(id),
      fetchAvailablePurchaseItems(arrivalDate, departureDate),
    ]);
    const { linkedCompanyIds, addresses } = this.props;
    const isDistrictEnabled = Boolean(
      Configurator.getSwitch(Configurator.switchCodes.DISTRICT)
    );

    for (const address of addresses) {
      if (!address.countryCode) return;
      fetchStates({
        countryCode: address.countryCode,
        languageCode: i18n.language,
      });

      if (isDistrictEnabled) {
        fetchDistricts({
          countryCode: address.countryCode,
          languageCode: i18n.language,
        });
      }
    }

    await Promise.all([...linkedCompanyIds.map((id) => fetchCompany(id))]);
    const { folios } = this.props;
    await Promise.all(folios.map(({ id }) => fetchAdditionalFolioInfo(id)));

    const isDocumentSelectionEnabled = Configurator.getSwitch(
      Configurator.switchCodes.SHOW_FISCAL_DOCUMENT_SELECTION
    );

    if (
      isDocumentSelectionEnabled &&
      !this.props.isEveryFolioHasAvailableFolioStyle
    ) {
      this.setState({ isCheckoutNotPossible: true });

      return;
    }

    const { isReviewChargesPossible, isFastTrackCheckOutPossible } = this.props;

    if (!isReviewChargesPossible) {
      Router.removeStep(Path.checkOut, 'PAYMENT');
      await fullCheckOut();
    } else if (isFastTrackCheckOutPossible) {
      this.disableLoading();

      return this.openQuickCheckOutModal();
    }

    this.goToNextStep();
  };

  private disableLoading = () => {
    this.setState({ isLoading: false });
  };

  private enableLoading = () => {
    this.setState({ isLoading: true });
  };

  public onErrorModalClick = () => {
    this.props.clearState();
    this.setState({ isCheckoutNotPossible: false });
    this.props.history.replace(paths.WELCOME);
  };

  private goToNextStep = () => {
    this.disableLoading();
    redirect(this.props.history);
  };
}

const mapStateToProps = (state: Store) => ({
  isEveryFolioHasAvailableFolioStyle:
    getIsEveryFolioHasAvailableFolioStyle(state),
  reservationListData: getReservationListData(state),
  folios: getFolios(state),
  linkedCompanyIds: getCompanyProfileIds(state),
  reservations: getAllReservations(state),
  profiles: getAllProfiles(state),
  addresses: getAddresses(state),
  isReviewChargesPossible: getIsReviewChargesPossible(state),
  isFastTrackCheckOutPossible: getIsFastTrackCheckOutPossible(state),
});

const mapDispatchToProps = {
  fetchFolios,
  fetchBreakdown,
  fetchBillingInstructions,
  fetchAdditionalFolioInfo,
  fetchCashieringAccount,
  chooseProfile,
  fetchAllTransactions,
  fetchReservationPurchases,
  fetchAvailablePurchaseItems,
  fetchReservation,
  fetchStates: fetchStates.trigger,
  fetchDistricts: fetchStates.trigger,
  fullCheckOut,
  clearState,
  continueCheckOutAsFastTrackProcess,
  fetchProfileInternalMemberships,
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckOutReservation) as (props: PassedProps) => JSX.Element;

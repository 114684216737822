import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { Button, Icon, IconTypes } from '@ac/kiosk-components';

import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';
import { Grid } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './CaptureButton.style';

export interface PassedProps {
  onClick: () => void;
  disabled?: boolean;
}

interface CaptureButtonProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class CaptureButton extends PureComponent<CaptureButtonProps> {
  public static defaultProps = {
    disabled: false,
  };

  public render() {
    const { classes, t, disabled } = this.props;

    return (
      <Button
        disabled={disabled}
        onClick={this.handleClick}
        className={classes.button}
        dataTestSelector="check-in-id-take-photo-button"
      >
        <Grid className={classes.buttonWrapper}>
          <Icon type={IconTypes.camera} className={classes.icon} />
          {t('TAKE_PICTURE')}
        </Grid>
      </Button>
    );
  }

  private handleClick = () => {
    const { onClick } = this.props;
    onClick();
  };
}

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles)
)(CaptureButton) as (props: PassedProps) => JSX.Element;

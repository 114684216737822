import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  BasicModal,
  BasicModalSize,
  Box,
  Button,
  ErrorModal,
  Flex,
  FlexDirection,
  Loader,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { getIsKioskProEnv } from '@gss/store/configuration/selectors';
import { BaseObject } from '@gss/types/shared';

import { DefaultQRScanner } from './scanners/DefaultQRScanner';
import { KioskProQRScanner } from './scanners/KioskProQRScanner';
import { QRCodeInvalidError } from './errors';
import { ScannerData } from './types';

import './QRScannerModal.scss';

interface QRScannerModalProps {
  onScanComplete?: (data: ScannerData) => void;
  onClose?: () => void;
}

export const QRScannerModal = ({
  onScanComplete,
  onClose,
}: QRScannerModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorData, setDataError] = useState<string>();
  const isKioskPro = useSelector(getIsKioskProEnv);

  const errorTypes = useMemo<BaseObject<string>>(
    () => ({
      NotFoundError: t('COMPONENTS.MODALS.QRSCANNER.ERRORS.NO_VIDEO_DEVICES'),
      NoVideoInputDevicesError: t(
        'COMPONENTS.MODALS.QRSCANNER.ERRORS.NO_VIDEO_DEVICES'
      ),
      NotAllowedError: t(
        'COMPONENTS.MODALS.QRSCANNER.ERRORS.PERMISSION_DENIED'
      ),
      QRCodeInvalidError: t(
        'COMPONENTS.MODALS.QRSCANNER.ERRORS.QR_CODE_IS_INVALID'
      ),
      UnknownError: t('COMPONENTS.MODALS.QRSCANNER.ERRORS.UNKNOWN_ERROR'),
    }),
    [t]
  );

  const disableLoader = (): void => {
    setIsLoading(false);
  };

  const clearErrors = (): void => {
    setDataError(undefined);
  };

  const getValidatedScannedQRCodeData = (data: string): ScannerData => {
    const parsedData: unknown = JSON.parse(data);

    const isValidQRCodeData = Boolean(
      parsedData &&
        typeof parsedData === 'object' &&
        'lastName' in parsedData &&
        'identificationNumber' in parsedData &&
        (parsedData as ScannerData).lastName &&
        (parsedData as ScannerData).identificationNumber
    );

    if (isValidQRCodeData) {
      return parsedData as ScannerData;
    }

    throw new QRCodeInvalidError();
  };

  const handleError = (error: unknown): void => {
    const errorOutput: string | undefined = error
      ? errorTypes[(error as Error).name]
      : errorTypes.UnknownError;
    setDataError(errorOutput || errorTypes.UnknownError);
  };

  const handleScan = (data: string | null): void => {
    try {
      if (!data || errorData) return;
      const values = getValidatedScannedQRCodeData(data);

      onScanComplete?.(values);
    } catch (error) {
      handleError(error as Error);
    }
  };

  return (
    <>
      <BasicModal
        size={BasicModalSize.md}
        className="with-default-kiosk-components-theme qrscanner-modal-container"
      >
        <Flex
          direction={FlexDirection.column}
          className="gap-lg spacing-top-sm"
        >
          <Text className="text-center" size={TextSize.xlg}>
            {t('COMPONENTS.MODALS.QRSCANNER.TITLE')}
          </Text>
          <Text className="text-center">
            {t('COMPONENTS.MODALS.QRSCANNER.DESCRIPTION')}
          </Text>
          <Box className="qrscanner-scanner-preview-container">
            {isLoading && (
              <Loader
                className="qrscanner-scanner-loader"
                description={t('COMPONENTS.MODALS.QRSCANNER.LOADING_SCANNER')}
              />
            )}

            <Box className={isLoading ? 'hidden' : undefined}>
              {isKioskPro ? (
                <KioskProQRScanner
                  onLoad={disableLoader}
                  onScan={handleScan}
                  onError={handleError}
                />
              ) : (
                <DefaultQRScanner
                  onLoad={disableLoader}
                  onScan={handleScan}
                  onError={handleError}
                />
              )}
            </Box>
          </Box>
          <Button onClick={onClose} className="spacing-auto">
            {t('SHARED.CLOSE')}
          </Button>
        </Flex>
      </BasicModal>

      {errorData && (
        <ErrorModal
          className="with-default-kiosk-components-theme"
          description={errorData}
          onConfirm={clearErrors}
        />
      )}
    </>
  );
};

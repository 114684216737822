import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Footer, ReservationHeader, View } from 'components';
import { compose } from 'redux';
import { resetAppProgress } from 'store/actions';
import {
  resetProfileUpdateProgress,
  saveScannedDocument,
} from 'store/checkInProcess/actions';
import { getConfirmedScannedDocument } from 'store/checkInProcess/selectors';
import { getActiveDocuments } from 'store/profile/selectors';
import { getErrors } from 'store/selectors';
import { Document } from 'types/Api/Profile';
import Store from 'types/Store';
import { Configurator, Router } from 'utils';
import { Path } from 'utils/Router';

import {
  Body,
  IconTypes,
  LargeButton,
  LargeButtonIconPosition,
  LoadingModal,
  Section,
  Text,
  TextSize,
  TextWeight,
} from '@ac/kiosk-components';
import { ApiError } from '@ac/library-api';

import { DocumentScanningResultDetails } from '@gss/api/KioskApi/entries';
import { Header } from '@gss/components/layout';
import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';

import {
  clearCheckInIDScanningDocument,
  clearCheckInIDScanningErrors,
  scanDocument,
} from './store/actions';
import {
  getCheckInIDScanningErrors,
  getIsCheckInIDScanningLoading,
  getScannedDocumentDetails,
} from './store/selectors';
import { ScannedDataVerificationModal } from './ScannedDataVerificationModal';
import { ScanningErrorModal } from './ScanningErrorModal';

import './CheckInIDScanning.scss';

const { CHECK_IN_FAILED_MESSAGE } = Configurator.getTranslationCodes();

interface CheckInIDScanningProps extends WithTranslation, RouteComponentProps {
  isScanningLoading: boolean;
  scannedDocument?: DocumentScanningResultDetails;
  confirmedScannedDocument?: DocumentScanningResultDetails;
  documents: Document[];
  checkInIDScanningErrors: Array<Error | ApiError>;
  errors: Array<Error | ApiError>;
  scanDocument: typeof scanDocument;
  clearCheckInIDScanningErrors: typeof clearCheckInIDScanningErrors;
  clearCheckInIDScanningDocument: typeof clearCheckInIDScanningDocument;
  resetAppProgress: typeof resetAppProgress;
  saveScannedDocument: typeof saveScannedDocument;
  resetProfileUpdateProgress: typeof resetProfileUpdateProgress;
}

class CheckInIDScanning extends PureComponent<CheckInIDScanningProps> {
  private onBackToScan = () => {
    const { clearCheckInIDScanningErrors, clearCheckInIDScanningDocument } =
      this.props;
    clearCheckInIDScanningErrors();
    clearCheckInIDScanningDocument();
  };

  private onCancelCheckIn = () => {
    const { history, resetAppProgress } = this.props;
    resetAppProgress(history);
  };

  private onContinueCheckIn = () => {
    const {
      history,
      scannedDocument,
      confirmedScannedDocument,
      documents,
      clearCheckInIDScanningErrors,
      clearCheckInIDScanningDocument,
      resetProfileUpdateProgress,
      saveScannedDocument,
    } = this.props;

    clearCheckInIDScanningErrors();

    const isThisDocumentAlreadySaved = documents.some(
      (document) =>
        document.typeCode === scannedDocument?.documentIdType?.code &&
        document.number === scannedDocument?.documentNumber
    );

    const isScannedDocumentSameAsPrevious =
      scannedDocument &&
      scannedDocument?.documentIdType?.id ===
        confirmedScannedDocument?.documentIdType?.id &&
      scannedDocument?.documentNumber ===
        confirmedScannedDocument?.documentNumber;

    if (isThisDocumentAlreadySaved && !isScannedDocumentSameAsPrevious) {
      Router.changeStepVisibility(Path.checkIn, 'ID', true);
    } else {
      Router.changeStepVisibility(Path.checkIn, 'ID', false);
    }

    if (scannedDocument) {
      if (!isScannedDocumentSameAsPrevious) {
        resetProfileUpdateProgress();
      }

      saveScannedDocument(scannedDocument);
      clearCheckInIDScanningDocument();
    }

    const { errors } = this.props;
    if (errors?.length) {
      return;
    }

    history.push(Router.nextStepURL);
  };

  private get hintMessage() {
    const { t } = this.props;
    const { KIOSK_CUSTOM_MESSAGE_PASSPORT_SCANNING } =
      Configurator.getTranslationCodes();

    return Configurator.getTranslation(KIOSK_CUSTOM_MESSAGE_PASSPORT_SCANNING);
  }

  private get isBackButtonVisible() {
    const authStepPath = Router.steps[Path.checkIn].AUTH?.url;

    return Router.prevStepURL !== authStepPath;
  }

  public render() {
    const {
      t,
      scanDocument,
      isScanningLoading,
      scannedDocument,
      confirmedScannedDocument,
      checkInIDScanningErrors,
      errors,
    } = this.props;

    const isCheckInIDScanningError = !!checkInIDScanningErrors.length;

    return (
      <View
        modal={{
          values: errors,
          customErrorCode: CHECK_IN_FAILED_MESSAGE,
        }}
        idle={!isScanningLoading ? { type: 'modal' } : undefined}
      >
        {!isScanningLoading &&
          !isCheckInIDScanningError &&
          !!scannedDocument && (
            <ScannedDataVerificationModal
              scannedDocument={scannedDocument}
              onBackToScan={this.onBackToScan}
              onContinueCheckIn={this.onContinueCheckIn}
            />
          )}

        {!isCheckInIDScanningError && isScanningLoading && (
          <LoadingModal
            className="with-default-kiosk-components-theme"
            description={t('CHECK_IN_ID_SCANNING.LOADING_MODAL.DESCRIPTION')}
          />
        )}

        {isCheckInIDScanningError && (
          <ScanningErrorModal
            error={checkInIDScanningErrors[0]}
            onBackToScan={this.onBackToScan}
            onCancelCheckIn={this.onCancelCheckIn}
            onContinueCheckIn={this.onContinueCheckIn}
          />
        )}

        <Header title={`${t('CHECK_IN')} - ${t('DOCUMENT_SCANNING')}`} />
        <ReservationHeader />

        <Body>
          <div className="check-in-id-scanning-wrapper">
            <Section
              className={classNames('check-in-id-scanning-section', {
                'check-in-id-scanning-wrapper-with-image':
                  Configurator.passportScanningImage,
              })}
            >
              <Text
                size={TextSize.xlg}
                weight={TextWeight.light}
                className="spacing-bottom-sm"
              >
                {t('CHECK_IN_ID_SCANNING.TITLE')}
              </Text>
              <Text hint className="spacing-bottom-sm">
                {this.hintMessage}
              </Text>
              <LargeButton
                iconPosition={LargeButtonIconPosition.top}
                onClick={scanDocument}
                icon={IconTypes.scanner}
                className="spacing-top-xxlg"
              >
                {t('SCAN_DOCUMENT')}
              </LargeButton>
            </Section>
            {Configurator.passportScanningImage && (
              <img
                src={Configurator.passportScanningImage}
                alt=""
                className="check-in-id-scanning-image"
              />
            )}
          </div>
        </Body>

        <Footer
          hasCancelButton
          hasBackButton={this.isBackButtonVisible}
          hasContinueButton
          isContinueDisabled={!confirmedScannedDocument}
          routeName={t('CHECK_IN')}
        />
      </View>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  isScanningLoading: getIsCheckInIDScanningLoading(state),
  scannedDocument: getScannedDocumentDetails(state),
  confirmedScannedDocument: getConfirmedScannedDocument(state),
  documents: getActiveDocuments(state),
  checkInIDScanningErrors: getCheckInIDScanningErrors(state),
  errors: getErrors(state),
});

const mapDispatchToProps = {
  scanDocument,
  clearCheckInIDScanningErrors,
  clearCheckInIDScanningDocument,
  resetAppProgress,
  saveScannedDocument,
  resetProfileUpdateProgress,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(CheckInIDScanning) as () => JSX.Element;

import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Body, Footer, FormHeader, ReservationHeader, View } from 'components';
import { compose } from 'redux';
import { resetAppProgress } from 'store/actions';
import {
  clearCashieringErrors,
  clearPreAuthorization,
  getAddPaymentMethodOperationStatus,
} from 'store/cashiering/actions';
import {
  getCashieringErrors,
  getFolios,
  getPreAuthorization,
  getPreAuthorizationId,
} from 'store/cashiering/selectors';
import { fullCheckIn } from 'store/reservation/actions';
import { getReservation } from 'store/reservation/selectors';
import { Folio } from 'types/Api/Cashiering';
import { ReservationView } from 'types/Api/Reservation';
import { ApiError, Money } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator, Router } from 'utils';
import { Path } from 'utils/Router';
import { Images } from 'views/CheckInCardPayment/Images/Images';
import PaymentDescription from 'views/CheckInCardPayment/PaymentDescription/PaymentDescription';

import { Icon, IconTypes, Loader } from '@ac/kiosk-components';

import { Header } from '@gss/components/layout';
import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';
import { AppBar, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './CheckInCardPayment.style';

const { CHECK_IN_FAILED_MESSAGE } = Configurator.getTranslationCodes();
const INITIAL_TIMEOUT = 3000;

interface CheckInPreAuthorizationState {
  wasAuthorizationRetried: boolean;
}

interface CheckInCardPaymentProps
  extends RequiredProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  clearCashieringErrors: typeof clearCashieringErrors;
  clearPreAuthorization: typeof clearPreAuthorization;
  resetAppProgress: typeof resetAppProgress;
  terminalError: boolean;
  errors: ApiError[];
  currencyCode: string;
  reservation: ReservationView;
  folios: Folio[];
  authorizationId: string;

  headerTitle: string;
  formMainText: string;
  paymentValue: Money;
  paymentDescription: string;
  successMessage: string;
  isPaymentAdded: boolean;
  isInitializing: boolean;
  addPayment: (folio: Folio, paymentValue: Required<Money>) => Promise<any>;
  checkOperationStatus: () => Promise<any>;
  afterSuccessfulOperation?: (newFolio: Folio) => Promise<any>;
  fullCheckIn: typeof fullCheckIn;
}

class CheckInCardPayment extends PureComponent<
  CheckInCardPaymentProps,
  CheckInPreAuthorizationState
> {
  public static defaultProps = {
    isPaymentAdded: true,
  };

  public state = {
    wasAuthorizationRetried: false,
  };

  private interruptRequests = false;
  private redirectQueued = false;

  public componentDidMount() {
    this.initializeTerminal();
  }

  public componentDidUpdate(prevProps: CheckInCardPaymentProps) {
    if (prevProps.isInitializing !== this.props.isInitializing) {
      this.initializeTerminal();
    }
  }

  public componentWillUnmount() {
    this.interruptRequests = true;
  }

  public render() {
    const { t, terminalError, errors, isPaymentAdded } = this.props;
    const { wasAuthorizationRetried } = this.state;

    return (
      <View
        idle={{ type: 'modal' }}
        modal={{
          values: errors,
          isOpen: terminalError,
          customErrorCode: CHECK_IN_FAILED_MESSAGE,
          onClick: this.handleModalClick,
          shouldRedirect: wasAuthorizationRetried,
          disableLoader: true,
        }}
      >
        {this.renderHeaderTitle()}
        <ReservationHeader />
        {isPaymentAdded ? this.onSuccess() : null}
        {this.renderBody()}
        <Footer
          hasCancelButton
          hasContinueButton
          hasBackButton
          routeName={t('CHECK_IN')}
          onContinue={this.changeRoute}
          isContinueDisabled
          isBackDisabled
          isCancelDisabled
          onCancel={this.cancelPayment}
        />
      </View>
    );
  }

  private renderHeaderTitle = () => {
    const {
      t,
      headerTitle: configuredHeaderSubTitle,
      isInitializing,
    } = this.props;
    const headerSubTile = isInitializing ? '' : `- ${configuredHeaderSubTitle}`;
    const headerTitle = `${t('CHECK_IN')} ${headerSubTile}`;

    return <Header title={headerTitle} />;
  };

  private renderBody = () => {
    const {
      t,
      isInitializing,
      formMainText,
      paymentDescription,
      paymentValue,
      classes,
    } = this.props;

    return (
      <Body>
        {isInitializing ? (
          <Loader title={t('LOADING')} description={t('PLEASE_WAIT')} />
        ) : (
          <>
            <FormHeader
              title={t('CARD_PAYMENT_SUBTITLE')}
              subtitle={formMainText}
            />
            <PaymentDescription
              paymentDescription={paymentDescription}
              paymentValue={paymentValue}
            />
            <Images
              image1={{
                className: classes.image1,
                imageSource: Configurator.cardPaymentImage1,
              }}
              image2={{
                className: classes.image2,
                imageSource: Configurator.cardPaymentImage2,
              }}
            />
          </>
        )}
      </Body>
    );
  };

  private onSuccess = () => {
    const { fullCheckIn } = this.props;
    const isCheckInPath = Router.currentPath === Path.checkIn;
    const isCheckInFinish = isCheckInPath && Router.isCurrentStepLast;
    if (!this.redirectQueued) {
      setTimeout(async () => {
        if (isCheckInFinish) await fullCheckIn(Configurator.postCheckInLetter);
        this.changeRoute();
      }, INITIAL_TIMEOUT);
      this.redirectQueued = true;
    }

    return this.renderSuccessAppBar();
  };

  private renderSuccessAppBar = () => {
    const { classes, successMessage } = this.props;

    return (
      <AppBar className={classes.appBar}>
        <Icon type={IconTypes.checkFilled} className={classes.icon} />
        <Typography className={classes.successful}>{successMessage}</Typography>
      </AppBar>
    );
  };

  private changeRoute = () => {
    const { history, clearPreAuthorization } = this.props;
    clearPreAuthorization();
    history.replace(Router.nextStepURL);
  };

  private cancelPayment = () => {
    const { history, resetAppProgress } = this.props;
    resetAppProgress(history);
  };

  private handleModalClick = () => {
    const { clearCashieringErrors, clearPreAuthorization } = this.props;
    const { wasAuthorizationRetried } = this.state;
    clearCashieringErrors();
    clearPreAuthorization();
    if (!wasAuthorizationRetried) {
      this.initializeTerminal();
      this.setState({ wasAuthorizationRetried: true });
    }
  };

  private initializeTerminal = async () => {
    if (this.props.isInitializing) {
      return;
    }

    const { clearPreAuthorization, paymentValue } = this.props;
    clearPreAuthorization();
    const folio = this.getFolioForTransaction();

    if (paymentValue.amount && folio && paymentValue.currency) {
      const unitPrice = {
        amount: paymentValue.amount,
        currency: paymentValue.currency || '',
      };
      await this.props.addPayment(folio, unitPrice);

      if (this.props.errors.length || this.interruptRequests) return;

      await this.props.checkOperationStatus();

      if (this.props.errors.length || this.interruptRequests) return;

      if (this.props.afterSuccessfulOperation)
        await this.props.afterSuccessfulOperation(folio);
    }
  };

  private getFolioForTransaction = () => {
    const {
      folioCodes: { FOLIO_COMPANY_TYPE },
    } = Configurator;
    const { folios } = this.props;
    const companyFolios = folios.filter(
      (folio) => folio.folioTypeCode.code === FOLIO_COMPANY_TYPE
    );
    if (companyFolios.length === 1) {
      return companyFolios[0];
    }

    return folios.find((folio) => folio.number === '1');
  };
}

const mapStateToProps = (state: Store) => ({
  errors: getCashieringErrors(state),
  reservation: getReservation(state),
  folios: getFolios(state),
  terminalError: getPreAuthorization(state).terminalError,
  authorizationId: getPreAuthorizationId(state),
});

const mapDispatchToProps = {
  getAddPaymentMethodOperationStatus,
  clearCashieringErrors,
  clearPreAuthorization,
  fullCheckIn,
  resetAppProgress,
};

type RequiredProps = Pick<
  CheckInCardPaymentProps,
  | 'headerTitle'
  | 'formMainText'
  | 'paymentValue'
  | 'addPayment'
  | 'isPaymentAdded'
  | 'paymentDescription'
  | 'isInitializing'
  | 'successMessage'
  | 'checkOperationStatus'
  | 'afterSuccessfulOperation'
>;

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckInCardPayment) as (props: RequiredProps) => JSX.Element;

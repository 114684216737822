import React, { useMemo } from 'react';
import {
  Location,
  NavigateFunction,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

export interface LegacyHistory {
  push: (address: string) => void;
  replace: (address: string) => void;
}

export interface RouteComponentProps {
  location: Location;
  history: LegacyHistory;
  params: Readonly<Params<string>>;
}

/**
 * Since react-router v6 withRouter was removed
 * Recreated withRouter HOC for LEGACY code
 */
export function withRouter<ComponentProps>(
  Component: React.FunctionComponent<ComponentProps>
) {
  function ComponentWithRouterProp(props: ComponentProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const history = useMemo(
      () => ({
        push: (address: string) => navigate(address),
        replace: (address: string) => navigate(address, { replace: true }),
      }),
      [navigate]
    );

    return (
      <Component
        {...props}
        location={location}
        history={history}
        params={params}
      />
    );
  }

  return ComponentWithRouterProp;
}

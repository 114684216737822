import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FormHeader, FormTextField } from 'components';
import { ids } from 'config';
import { compose } from 'redux';
import {
  Field,
  Form,
  initialize,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import Store from 'types/Store';
import { isFormValid, required } from 'utils/Validator';

import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';
import { Grid } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './CompanyDetailsForm.style';

const SPACING = 2;

interface PassedProps {
  onSubmit: (values: any) => void;
  setSubmitState: (isFormValid: boolean) => void;
}

interface CompanyDetailsFormProps
  extends PassedProps,
    RouteComponentProps,
    InjectedFormProps,
    WithTranslation,
    WithStyles<typeof styles> {
  onInitialize: (form: string, data: object) => void;
}

class CompanyDetailsForm extends PureComponent<CompanyDetailsFormProps> {
  public componentDidUpdate() {
    const { setSubmitState } = this.props;
    setSubmitState(isFormValid(this.props));
  }

  public render() {
    const { t, handleSubmit, classes, onSubmit, ...props } = this.props;

    return (
      <Form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        className={classes.formWrapper}
      >
        <FormHeader
          formName={t('COMPANY_DETAILS')}
          isFormValid={isFormValid(props)}
        />
        <Grid container spacing={SPACING}>
          <Grid item xs={12} sm={6} md={12} className={classes.companyField}>
            <Field
              name="fullName"
              component={FormTextField}
              validate={[required]}
              label={t('NAME')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12} className={classes.companyField}>
            <Field
              name="taxId"
              type="text"
              validate={[required]}
              component={FormTextField}
              label={t('TAX_ID')}
            />
          </Grid>
        </Grid>
      </Form>
    );
  }
}

const mapStateToProps = (state: Store) => ({});

const mapDispatchToProps = {
  onInitialize: initialize,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  reduxForm({ form: ids.DETAILS_FORM }),
  connect(mapStateToProps, mapDispatchToProps)
)(CompanyDetailsForm) as (props: PassedProps) => JSX.Element;

import { ApiError } from '@ac/library-api';

export const handleSagaError = (error: unknown): Error | ApiError | object => {
  if (!error) {
    return new Error('Undefined error');
  }

  if (typeof error !== 'object') {
    return new Error(`Unspecified error: ${error as string}`);
  }

  return error as Error | ApiError | object;
};
